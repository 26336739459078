import { pageNamedContexts } from "@blockwell/palette";
import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import Markdown from "@/components/Markdown.vue";
export default {
    name: "ContextSetting",
    components: { Markdown, NameFind, LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin],
    inject: {
        manager: {
            name: "manager",
        },
    },
    props: {
        setting: {
            type: Object,
        },
        value: String,
    },
    data() {
        return {};
    },
    computed: {
        name() {
            return this.value || this.setting.default || "";
        }
    },
    methods: {
        source() {
            return (q, populate) => {
                let query = q?.toLowerCase();
                let vars = pageNamedContexts(this.manager.root.block);
                if (query) {
                    vars = vars.filter((it) => it.context.name.startsWith(query));
                }
                else {
                    vars = vars.filter(it => !it.conditional);
                }
                populate(vars.map(({ context, block, name, conditional }) => {
                    return {
                        name,
                        ownerKey: context.ownerKey,
                        blockType: block.type.type,
                        conditional,
                    };
                }));
            };
        },
    },
};
