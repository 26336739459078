import { addBlockTypes, addLayoutTypes } from "@/components/palette/palette";
export default {
    name: "PaletteBlocks",
    inject: ["manager", "editorState"],
    data() {
        return {
            types: addBlockTypes,
            layouts: addLayoutTypes
        };
    },
    methods: {
        dragStart(ev, spec) {
            ev.dataTransfer.setData("text/spec", JSON.stringify(spec));
            this.editorState.dragging = true;
        },
        dragEnd() {
            this.editorState.dragging = false;
        },
    },
};
