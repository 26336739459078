export default {
    name: "PaletteModal",
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        fullscreen: Boolean,
        large: Boolean,
        small: Boolean,
        flexible: Boolean,
        modalClass: String,
        noDismiss: Boolean,
        noPadding: Boolean,
        portal: {
            type: String,
            default: "#palette-modal-portal",
        },
    },
    data() {
        return {
            shown: this.show,
            showContent: this.show,
            paletteId: null,
            modified: null,
            rendering: false,
        };
    },
    watch: {
        show(val, prev) {
            if (val || prev !== true) {
                this.showContent = this.show;
                this.shown = this.show;
            }
            else {
                this.close();
            }
        },
    },
    computed: {
        rendered() {
            return this.shown || this.rendering;
        },
        modalClasses() {
            return [
                {
                    fullscreen: this.fullscreen,
                    "palette-modal-flexible": this.flexible,
                    "palette-modal-small": this.small,
                    "palette-modal-large": this.large,
                    "palette-modal-no-padding": this.noPadding,
                },
                this.modalClass,
            ];
        },
    },
    methods: {
        close() {
            this.showContent = false;
            this.$nextTick(() => {
                this.shown = false;
                this.$emit("update:show", false);
                this.$emit("closed");
            });
        },
        escape(event) {
            if (event.key === "Escape" || event.key === "Esc") {
                let attr = document.body.getAttribute("data-modals");
                if (attr) {
                    let modals = JSON.parse(attr);
                    if (modals[modals.length - 1] === this.uid) {
                        this.close();
                    }
                }
            }
        },
        modifyBody() {
            const layout = this.$el.closest(".palette-root-style");
            const body = layout.querySelector(".palette-block-layout");
            this.modified = body;
            this.rendering = true;
            let attr = body.getAttribute("data-modals");
            let modals;
            if (attr) {
                modals = JSON.parse(attr);
            }
            else {
                modals = [];
            }
            modals.push(this.uid);
            body.setAttribute("data-modals", JSON.stringify(modals));
            document.addEventListener("keydown", this.escape);
            let observer = new ResizeObserver((entries) => {
                this.fixZIndex();
            });
            observer.observe(this.$refs.box);
            setTimeout(() => {
                observer.disconnect();
            }, 500);
        },
        fixZIndex() {
            this.position();
            const body = this.$el.closest(".main-palette");
            if (!body) {
                return;
            }
            let attr = body.getAttribute("data-modals");
            let modals;
            if (attr) {
                modals = JSON.parse(attr);
            }
            else {
                modals = [];
            }
            let zIndex = 20;
            for (let uid of modals) {
                document.getElementById(uid).style.zIndex = "" + zIndex++;
            }
        },
        position() {
            const backdrop = this.$refs.backdrop;
            const box = this.$refs.box;
            if (!backdrop || !box) {
                return;
            }
            let rect = backdrop.getBoundingClientRect();
            let top = Math.max(rect.top * -1, 0);
            let left = Math.max(rect.left * -1, 0);
            let height;
            let width;
            if (rect.bottom > window.innerHeight) {
                height = window.innerHeight - Math.max(0, rect.top);
            }
            else {
                height = rect.height + Math.min(0, rect.top);
            }
            if (rect.right > window.innerWidth) {
                width = window.innerWidth - Math.max(0, rect.left);
            }
            else {
                width = rect.width + Math.min(0, rect.left);
            }
            let boxWidth = box.clientWidth;
            let boxHeight = box.clientHeight;
            let x;
            let y;
            if (boxWidth > width) {
                x = 0;
            }
            else {
                x = (width - boxWidth) / 2;
            }
            if (boxHeight > height) {
                y = 0;
            }
            else {
                y = (height - boxHeight) / 2;
            }
            box.style.top = `${y + top}px`;
            box.style.left = `${x + left}px`;
        },
        unmodifyBody() {
            const body = this.modified;
            let attr = body?.getAttribute("data-modals");
            if (attr) {
                let modals = JSON.parse(attr);
                modals = modals.filter((it) => it !== this.uid);
                if (modals.length === 0) {
                    body.removeAttribute("data-modals");
                }
                else {
                    body.setAttribute("data-modals", JSON.stringify(modals));
                }
            }
            document.removeEventListener("keydown", this.escape);
            this.rendering = false;
        },
        getScrollbarWidth() {
            // From Bootstrap 4.0 Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
            const scrollDiv = document.createElement("div");
            scrollDiv.className = "scroll-bar-measurer";
            document.body.appendChild(scrollDiv);
            const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
        },
        backdropClick() {
            if (!this.noDismiss) {
                this.close();
            }
        },
    },
    beforeDestroy() {
        this.unmodifyBody();
    },
};
