export default {
    props: {
        product: String,
        loading: Boolean
    },
    data() {
        return {
            showPopover: false,
            productData: null,
            fuelLoading: false,
        };
    },
    computed: {
        ticket() {
            const status = this.status;
            return status?.tickets?.find((it) => it.productId === this.product);
        },
        sufficientBalance() {
            return !!this.ticket || this.balance.gte(this.productData?.fuel);
        },
    },
    watch: {
        product: {
            immediate: true,
            handler(val) {
                const api = this.$store.getters["user/api"].fuel;
                if (val) {
                    api.getProduct(val).then((data) => {
                        this.productData = data;
                    });
                }
            },
        },
    },
};
