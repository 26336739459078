import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
export default {
    name: "CollectionSetting",
    components: { NameFind, LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: String,
    },
    data() {
        return {
            collection: this.value,
        };
    },
    computed: {},
    watch: {
        collection(val) {
            this.emitSetting(val);
        },
    },
    methods: {
        source() {
            return (q, populate) => {
                let query = q?.toLowerCase();
                let ctx = this.wrap.block.context;
                let names = ctx.findCollections();
                if (query) {
                    names = names.filter((it) => it.startsWith(query));
                }
                populate(names);
            };
        },
    },
};
