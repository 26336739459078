import MediaFileView from "@/components/palette/settings/media/MediaFileView.vue";
import Modal from "@/components/Modal.vue";
import { sortByPath } from "rambdax";
import { reverse } from "remeda";
export default {
    name: "ImageManager",
    components: { Modal, MediaFileView },
    inject: ["editorState"],
    props: {
        show: Boolean,
        requestId: Number
    },
    data() {
        return {
            shown: this.show,
            media: [],
            uploadIndex: 1
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        }
    },
    watch: {
        show(val) {
            this.shown = val;
        },
        shown(val) {
            this.$emit("update:show", val);
            if (val) {
                this.loadImages();
            }
        },
    },
    methods: {
        uploadFile(file, progress, error) {
            const api = this.$store.getters["user/api"];
            const formData = new FormData();
            formData.append("file", file);
            return api.media
                .upload(formData)
                .then((file) => {
                progress(100);
                this.media.unshift(file);
                this.uploadIndex += 1;
                return [{ url: file.storage?.[0]?.location }];
            })
                .catch((err) => {
                error(err.message);
            });
        },
        loadImages() {
            const api = this.api;
            api.media.getUserMedia().then((it) => (this.media = reverse(sortByPath("id", it))));
        },
        selected(file) {
            this.$emit("select", file);
        },
        remove(file) {
            const api = this.api;
            let media = this.media;
            api.media.deleteMedia(file.id)
                .then(() => {
                let index = media.findIndex(it => it.id === file.id);
                if (index > -1) {
                    media.splice(index, 1);
                }
            });
        },
        drag() {
            this.editorState.dragging = true;
            this.shown = false;
        }
    },
};
