import ClampedText from "@/components/ClampedText.vue";
import DumbappValueView from "@/components/palette/settings/values/DumbappValueView.vue";
import { isVarsMetaValue } from "@blockwell/variables";
export default {
    name: "CurrentValue",
    components: { DumbappValueView, ClampedText },
    props: {
        value: {
            type: [Object, Array, String, Boolean, Number]
        },
        error: String
    },
    data() {
        return {};
    },
    computed: {
        showValue() {
            return this.value !== undefined && this.value !== null;
        },
        emptyArray() {
            if (Array.isArray(this.value)) {
                return this.value.length === 0;
            }
            return false;
        },
        realValue() {
            if (isVarsMetaValue(this.value)) {
                return this.value.valueOf();
            }
            return this.value;
        }
    }
};
