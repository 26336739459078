import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default {
    name: "CheckboxBlockView",
    mixins: [BlockViewMixin],
    data() {
        return {
            checked: false,
            label: null,
            radio: false,
            collection: "",
            val: "",
        };
    },
    computed: {},
    watch: {},
    methods: {
        input(ev) {
            let input = ev.target;
            let block = this.block.block.type;
            this.$nextTick(() => {
                if (this.radio) {
                    let radios = this.$el
                        .closest(".palette-root-style")
                        .querySelectorAll(`.palette-radio-${this.collection}`);
                    for (let el of radios) {
                        let parent = el.parentElement;
                        if (parent?.__vue__) {
                            parent.__vue__.uncheck();
                        }
                    }
                }
                block.setChecked(input.checked);
            });
        },
        onValue(value) {
            this.checked = value.checked;
            this.label = value.label || null;
            this.radio = value.radio;
            this.collection = value.collection;
        },
        uncheck() {
            let block = this.block.block.type;
            block.setChecked(false);
        },
        click(ev) {
            ev.stopPropagation();
        },
    },
};
