import EditorPrimarySetting from "@/components/palette/settings/block/EditorPrimarySetting.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import equals from "fast-deep-equal";
import { clone } from "remeda";
import MediaRequestMixin from "@/components/palette/settings/media/MediaRequestMixin";
export default {
    name: "BlockPrimarySetting",
    components: { EditorPrimarySetting },
    mixins: [SettingMixin, MediaRequestMixin],
    inject: ["manager"],
    props: {
        spec: {
            type: Object,
        },
        settings: {
            type: Array,
        },
    },
    data() {
        return {
            setting: null,
            show: false,
            reference: null,
            value: null
        };
    },
    watch: {
        wrap: {
            immediate: true,
            handler() {
                // All these values always change at the same time as wrap, but wrap is the
                // one guaranteed to be unique when changing blocks
                let spec = this.spec;
                let primary = this.paramKey;
                let settings = this.settings;
                let setting;
                this.reference = null;
                this.show = false;
                if (primary) {
                    for (let group of settings) {
                        if (group.settings[primary]) {
                            setting = group.settings[primary];
                            break;
                        }
                    }
                }
                this.setting = setting;
            },
        },
        spec: {
            deep: true,
            handler(spec) {
                this.value = spec?.param?.[this.paramKey];
            },
        },
    },
    methods: {
        open() {
            if (this.setting) {
                if (this.spec.type === "image" && this.paramKey === "uri") {
                    this.requestImage(this.wrap.block.key);
                }
                else {
                    this.reference = this.wrap.block.element;
                    this.show = true;
                }
            }
        },
        update(update) {
            let block = this.wrap?.block;
            if (block && this.spec && update) {
                for (let [key, value] of Object.entries(update)) {
                    if (!equals(this.spec.param[key], value)) {
                        this.spec.param[key] = value;
                        block.updateParameter(key, clone(value));
                    }
                }
            }
        },
        imageUpdate(value) {
            this.update({
                uri: {
                    type: "const",
                    param: {
                        value
                    }
                }
            });
        }
    },
    mounted() {
        this.editorState.events.controls.on("doubleclick", this.open);
    },
    beforeDestroy() {
        this.editorState.events.controls.off("doubleclick", this.open);
    },
};
