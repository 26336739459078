import Vue from "vue";
import SubmissionsDropdown from "../../components/SubmissionsDropdown.vue";
import ContractHistoryDropdown from "../../components/ContractHistoryDropdown.vue";
import MessagesDropdown from "../../components/message/MessagesDropdown.vue";
import AccountDropdown from "../../components/AccountDropdown.vue";
import Mailchimp from "../../components/Mailchimp.vue";
import MainMenu from "../../components/MainMenu.vue";
import MobileNav from "@/layouts/components/MobileNav.vue";
import { every } from "@/lib/interval";
import LoadingButton from "@/components/LoadingButton.vue";
import PaletteSaveAs from "@/components/palette/settings/PaletteSaveAs.vue";
import PaletteSubscriptionStatus from "@/components/palette/settings/PaletteSubscriptionStatus.vue";
export default Vue.extend({
    name: "PaletteMainHeader",
    components: {
        PaletteSubscriptionStatus,
        PaletteSaveAs,
        LoadingButton,
        MobileNav,
        MainMenu,
        Mailchimp,
        AccountDropdown,
        MessagesDropdown,
        ContractHistoryDropdown,
        SubmissionsDropdown,
    },
    inject: ["editorState", "saveState"],
    props: {
        icon: String,
        external: String,
        manager: {
            type: Object,
        },
    },
    data() {
        return {
            draftSaved: "",
            interval: null,
            showSaveAs: false,
        };
    },
    computed: {
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        email() {
            return this.$store.state.user.email;
        },
        publishedLink() {
            if (!this.saveState.version || this.saveState.version < 0) {
                return null;
            }
            let link = "/palettes";
            if (this.manager?.name) {
                link += "/" + this.manager.name;
            }
            let index = this.$route.fullPath.indexOf("?");
            if (index >= 0) {
                link += this.$route.fullPath.slice(index);
            }
            return link;
        },
        lockStatus() {
            return this.saveState.lockStatus;
        },
        canEdit() {
            return this.saveState.canEdit || this.saveState.availability === "new";
        },
    },
    watch: {
        "editorState.draftSaved": {
            immediate: true,
            handler() {
                this.refreshTime();
            },
        },
    },
    methods: {
        refreshTime() {
            if (this.saveState.draftSaved) {
                this.draftSaved = this.saveState.draftSaved.fromNow();
            }
            else {
                this.draftSaved = "";
            }
        },
        openEditor() {
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: "#editor",
            });
        },
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
        subscription() {
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: "#editor/subscription",
            });
        },
    },
    mounted() {
        this.interval = every(() => {
            this.refreshTime();
        }, 10000);
    },
    beforeDestroy() {
        this.interval?.clear();
    },
});
