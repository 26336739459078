import * as acemodule from "ace-builds/src-noconflict/ace";
let ace = acemodule;
ace.config.setModuleUrl('ace/mode/markdown', require('file-loader?esModule=false!ace-builds/src-noconflict/mode-markdown.js'));
ace.config.setModuleUrl('ace/ext/language_tools', require('file-loader?esModule=false!ace-builds/src-noconflict/ext-language_tools.js'));
import templatemode from "../ace-template";
import mykai from "@/views/happs/book/lib/theme-mykai";
import { blockVariableInfo } from "@blockwell/palette";
templatemode(acemodule);
mykai(acemodule);
export default {
    props: {
        wrap: {
            type: Object,
        },
        value: String,
    },
    data() {
        return {
            template: this.value,
        };
    },
    watch: {
        value(val) {
            if (this.editor && this.template !== val) {
                this.editor.session.setValue(val);
            }
        },
        template(val) {
            this.$emit("input", val);
        },
    },
    methods: {
        source() {
            let cached;
            let timeout;
            return (q) => {
                let query = q?.toLowerCase();
                if (!cached) {
                    cached = blockVariableInfo(this.wrap.block, null).filter((it) => it.valueType !== "Null");
                }
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => (cached = null), 3000);
                let vars = cached;
                if (query) {
                    vars = vars.filter((it) => it.valueType !== "Null" &&
                        (it.name.startsWith(query) ||
                            it.path.find((part) => part.startsWith(query))));
                }
                return vars;
            };
        },
        prepareAce(focus, single = false) {
            this.$nextTick(() => {
                let editor = acemodule.edit(this.$refs.ace);
                this.editor = editor;
                editor.setTheme("ace/theme/mykai");
                editor.session.setMode("ace/mode/template");
                editor.setAutoScrollEditorIntoView(true);
                editor.setBehavioursEnabled(true);
                editor.setOptions({
                    showGutter: false,
                    maxLines: single ? 1 : 100,
                    wrap: true,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                });
                editor.renderer.setScrollMargin(5, 5, 3, 3);
                let langTools = acemodule.require("ace/ext/language_tools");
                let query = this.source();
                let testCompleter = {
                    getCompletions(editor, session, pos, prefix, callback) {
                        let token = session.getTokenAt(pos.row, pos.column);
                        if (token.type.endsWith(".completion")) {
                            let data = query(prefix).map((it) => {
                                return {
                                    value: it.name,
                                    caption: it.name,
                                    meta: it.value,
                                };
                            });
                            callback(null, data);
                        }
                        else {
                            callback(null, []);
                        }
                    },
                };
                langTools.setCompleters([testCompleter]);
                editor.on("change", () => {
                    this.template = editor.session.getValue();
                });
                let Autocomplete = acemodule.require("ace/autocomplete").Autocomplete;
                editor.completer = new Autocomplete();
                editor.completer.editor = editor;
                editor.completer.$init();
                this.$nextTick(() => {
                    editor.session.setValue(this.template);
                    editor.session.selection.moveCursorFileEnd();
                    if (focus) {
                        editor.focus();
                    }
                });
            });
        }
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.completer.destroy();
            this.editor.destroy();
            this.editor = null;
        }
    },
};
