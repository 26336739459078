import BaseSettingTypesMixin from "@/components/palette/settings/types/BaseSettingTypesMixin";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import { clone } from "remeda";
export default {
    name: "ConversionConfig",
    components: { NameFind },
    mixins: [BaseSettingTypesMixin],
    inject: ["manager"],
    props: {
        value: {
            type: Object,
        },
        wrap: {
            type: Object,
        },
    },
    data() {
        let manager = this.manager;
        return {
            types: manager.registry.convertTypes.getAll().map((it) => {
                return {
                    ...it,
                    name: it.type,
                };
            })
        };
    },
    computed: {
        settings() {
            if (this.value?.type) {
                const manager = this.manager;
                let settings = manager.registry.convertTypes.get(this.value.type).settings;
                let value = this.value;
                return Object.entries(settings).map(([key, val]) => {
                    return {
                        key,
                        setting: val,
                        value: value.param?.[key],
                    };
                });
            }
            return [];
        }
    },
    methods: {
        source(q, populate) {
            populate(this.types);
        },
        typeInput(type) {
            if (this.value?.type !== type) {
                this.$emit("update", { type, param: {} });
            }
        },
        input(update) {
            let value = clone(this.value);
            if (!value.param) {
                value.param = update;
            }
            else {
                value.param = {
                    ...value.param,
                    ...update
                };
            }
            this.$emit("update", value);
        }
    },
};
