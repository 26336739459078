export default {
    name: "PaletteSubscriptionStatus",
    inject: ["editorState", "saveState"],
    data() {
        return {};
    },
    computed: {
        endingIn() {
            return this.saveState.subscriptionExpires.fromNow();
        }
    },
    methods: {
        subscription() {
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: "#editor/subscription",
            });
        },
    }
};
