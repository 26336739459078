import { createDefaultBlockTypes, InteractionVariable, paletteFromSpec } from "@blockwell/palette";
import { createDefaultConvertTypes, createDefaultVariableTypes } from "@blockwell/variables";
import { addDumbappVariableTypes } from "@blockwell/dumbapp/lib/variables";
const blockTypes = createDefaultBlockTypes();
const convertTypes = createDefaultConvertTypes();
export function loadPalette(spec, batches, nft, api, dumbappApi) {
    const varTypes = createDefaultVariableTypes(api);
    varTypes.register(new InteractionVariable());
    addDumbappVariableTypes(varTypes, dumbappApi);
    let palette = paletteFromSpec(spec, batches, nft, blockTypes, varTypes, convertTypes);
    palette.activate();
    return palette;
}
export function layoutChange(manager, block, prev, value) {
    if (block.type.type === "nft") {
        let child = block.children[0];
        if (child && child.type.type === "image") {
            child.updateParameter("layout", value);
        }
    }
}
export const addLayoutTypes = [
    {
        title: "List",
        icon: "table_rows",
        spec: {
            type: "layout",
        },
        description: "Normal vertical layout.",
    },
    {
        title: "Sidebar",
        icon: "view_array",
        description: "Layout with narrow sidebars on either side of a main layout.",
        spec: {
            type: "layout",
            param: {
                layout: "sidebar",
            },
            children: [
                {
                    block: {
                        type: "layout",
                        classes: ["sidebar-left-sidebar"],
                    },
                },
                {
                    block: {
                        type: "layout",
                        classes: ["sidebar-main-area"],
                    },
                },
                {
                    block: {
                        type: "layout",
                        classes: ["sidebar-right-sidebar"],
                    },
                },
            ],
        },
    },
    {
        title: "Grid",
        icon: "view_comfy",
        description: "Displays blocks in a grid.",
        spec: {
            type: "layout",
            param: {
                layout: "grid",
            },
        },
    },
];
export const addBlockTypes = [
    {
        title: "Template",
        icon: "text_fields",
        description: "Text content that can optionally use variables.",
        spec: {
            type: "template",
            param: {
                template: {
                    type: "const",
                    param: {
                        value: "",
                    },
                },
            },
        },
    },
    {
        title: "Wallet",
        icon: "account_balance_wallet",
        description: "Controls for connecting wallets to the page.",
        spec: {
            type: "wallet",
        },
    },
    {
        title: "Repeat",
        icon: "format_list_numbered",
        description: "Repeat content, for example a list of NFTs.",
        spec: {
            type: "repeat",
            param: {
                items: {
                    type: "var",
                    param: {
                        name: "items",
                    },
                },
            },
        },
    },
    {
        title: "Condition",
        icon: "flip",
        description: "Show or hide content based on a condition.",
        spec: {
            type: "condition",
            param: {
                conditions: [],
            },
        },
    },
    {
        title: "Balance",
        icon: "money",
        description: "Show a token balance.",
        spec: {
            type: "balance",
        },
    },
    {
        title: "NFT Item",
        icon: "smart_toy",
        description: "Load and display an NFT item.",
        spec: {
            type: "nft",
            param: {
                tokenId: {
                    type: "var",
                    param: {
                        name: "",
                    },
                },
            },
            children: [
                {
                    block: {
                        type: "image",
                        param: {
                            uri: {
                                type: "var",
                                param: {
                                    name: "metadata.image",
                                },
                            },
                            layout: "card",
                        },
                    },
                },
                {
                    block: {
                        type: "layout",
                        classes: ["card-content"],
                        children: [
                            {
                                block: {
                                    type: "template",
                                    param: {
                                        template: {
                                            type: "const",
                                            param: {
                                                value: "#### {{metadata.name}}",
                                            },
                                        },
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    {
        title: "Image",
        icon: "image",
        description: "Display an image.",
        spec: {
            type: "image",
            param: {
                uri: {
                    type: "var",
                    param: {
                        name: "",
                    },
                },
            },
        },
    },
    {
        title: "bApp",
        icon: "smart_button",
        description: "Add a bApp for submitting transactions.",
        spec: {
            type: "dumbapp",
            param: {
                shortcode: {
                    type: "const",
                    param: {
                        value: "",
                    },
                },
                label: {
                    type: "const",
                    param: {
                        value: "Submit",
                    },
                },
            },
        },
    },
    {
        title: "Checkbox",
        icon: "check_box",
        description: "Checkbox for allowing user selection.",
        spec: {
            type: "checkbox",
            param: {},
        },
    },
    {
        title: "Input",
        icon: "input",
        description: "Form field where users can input text.",
        spec: {
            type: "input",
            param: {},
        },
    },
    {
        title: "Spinner",
        icon: "refresh",
        description: "A spinner indicating activity.",
        spec: {
            type: "spinner",
            param: {
                conditions: [],
            },
        },
    },
    {
        title: "Contract",
        icon: "keyboard_alt",
        description: "Display information about a smart contract.",
        spec: {
            type: "contract",
            param: {
                contract: {
                    type: "var",
                    param: {
                        type: "contract",
                    },
                },
            },
        },
    },
];
