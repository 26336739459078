import ConvertSetting from "@/components/palette/settings/types/ConvertSetting.vue";
import BaseSettingTypesMixin from "@/components/palette/settings/types/BaseSettingTypesMixin";
import ConditionsSetting from "@/components/palette/settings/types/ConditionsSetting.vue";
import InteractionsSetting from "@/components/palette/settings/types/InteractionsSetting.vue";
export default {
    components: {
        ConvertSetting, ConditionsSetting, InteractionsSetting
    },
    mixins: [BaseSettingTypesMixin],
    methods: {
        settingComponent(setting) {
            switch (setting.type) {
                case "convert":
                    return ConvertSetting;
                case "conditions":
                    return ConditionsSetting;
                case "interactions":
                    return InteractionsSetting;
            }
            return this.settingComponentBase(setting);
        },
    },
};
