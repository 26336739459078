import EditorSettingPopper from "@/components/palette/settings/types/EditorSettingPopper.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
export default {
    name: "EditorPrimarySetting",
    components: { EditorSettingPopper },
    mixins: [SettingMixin],
    props: {
        wrap: {
            type: Object,
        },
        value: {
            type: Object,
        },
        reference: HTMLElement,
        show: Boolean,
    },
    data() {
        return {
            template: this.value?.param?.value || ""
        };
    },
    watch: {
        value(value) {
            this.template = value?.param?.value || "";
        },
        template(value) {
            this.emitSetting({
                type: "const",
                param: {
                    value,
                },
            });
        },
    }
};
