import PopperMixin from "@/components/PopperMixin";
import FuelProductPrice from "@/components/fuel/FuelProductPrice.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import BuyFuel from "@/components/fuel/BuyFuel.vue";
import { FuelData } from "@/lib/fuel";
import { mapGetters } from "vuex";
import LoginForm from "@/components/LoginForm.vue";
import BigNumber from "bignumber.js";
import LoadingButton from "@/components/LoadingButton.vue";
import dayjs from "dayjs";
export default {
    name: "FuelPopover",
    components: { LoadingButton, LoginForm, BuyFuel, ProgressCircle, FuelProductPrice },
    mixins: [PopperMixin],
    props: {
        show: Boolean,
        fuel: FuelData,
        fuelStatus: {
            type: Object,
        },
        product: {
            type: Object,
        },
        balance: {
            type: Object
        }
    },
    data() {
        return {
            placementOverride: "top",
            buy: false,
            token: "",
            requestingTrial: false
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "api"]),
        ...mapGetters("dumbapp", ["tracking"]),
        ticket() {
            const status = this.fuelStatus;
            const product = this.product;
            return status?.tickets?.find((it) => it.productId === product?.id);
        },
        sufficientBalance() {
            const product = this.product;
            return !!this.ticket || this.balance?.gte(product?.fuel);
        },
        needMore() {
            const product = this.product;
            return new BigNumber(product?.fuel).minus(this.balance).toString(10);
        },
        status() {
            const status = this.fuelStatus;
            if (this.ticket) {
                return "free";
            }
            if (this.sufficientBalance) {
                return "ok";
            }
            if (status.trial !== "used") {
                return "trial";
            }
            return "insufficient";
        },
        trial() {
            return this.fuelStatus.trial;
        },
        tracked() {
            let tracks = this.tracking["fuel"];
            if (tracks) {
                return (tracks.filter((it) => {
                    return it.status === "pending" ||
                        (dayjs().diff(dayjs(it.submission.created), "minutes") < 3 &&
                            this.fuel?.stake?.lte(it.stake));
                }).length > 0);
            }
            return false;
        },
    },
    methods: {
        requestTrial() {
            const api = this.api.fuel;
            this.requestingTrial = true;
            api.requestTrial()
                .then(res => {
                this.fuelStatus.trial = res.status;
            })
                .finally(() => this.requestingTrial = false);
        }
    },
};
