import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import Markdown from "@/components/Markdown.vue";
export default {
    name: "LiteralSetting",
    components: { Markdown, LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object
        },
        value: [String, Number, Boolean, Array, Object]
    },
    data() {
        return {
            literal: this.value || this.setting.default
        };
    },
    computed: {
        forceType() {
            if (this.setting.valueType) {
                return "value";
            }
            return null;
        }
    },
    watch: {
        literal(val) {
            this.emitSetting(val);
        },
        value(val) {
            if (val !== this.literal) {
                this.literal = val;
            }
        }
    }
};
