import { cssProperties } from "@blockwell/palette";
export const FlexChildGroup = {
    title: "Flex Child",
    hideRoot: true,
    items: [
        {
            prop: "order",
            description: "Position in the flex parent.",
            width: 50,
        },
        {
            prop: "flex-basis",
            label: "Basis",
            description: "The base size of the block.",
            width: 50,
        },
        {
            prop: "flex-grow",
            label: "Grow",
            description: "How much this block should grow automatically in comparison to its siblings.",
            width: 50,
        },
        {
            prop: "flex-shrink",
            label: "Shrink",
            description: "How much this block should shrink in comparison to its siblings.",
            width: 50,
        },
        {
            prop: "align-self",
            label: "Align",
            description: "How to align this block across the flex direction.",
            icons: [
                "hdr_auto",
                "align_vertical_top",
                "align_vertical_bottom",
                "align_vertical_center",
                "text_fields",
                "pause",
            ],
        },
    ].map((it) => {
        return {
            ...it,
            prop: cssProperties.get(it.prop),
        };
    }),
};
export const LayoutStyleGroup = {
    title: "Layout",
    hideRoot: true,
    items: [
        {
            prop: "display",
            icons: [
                { icon: "view_day", description: "<em>Block</em> elements take up a full line." },
                {
                    icon: "view_column",
                    description: "<em>Flex</em> allows displaying child blocks in different directions.",
                },
                {
                    icon: "text_format",
                    description: "<em>Inline</em> content displays in line with text.",
                },
                {
                    icon: "hdr_auto",
                    description: "<em>Inline Block</em> is like Inline, but allows sizing and padding.",
                },
                {
                    icon: "view_week",
                    description: "<em>Inline Flex</em> is like Inline, but the contents have flex layout.",
                },
            ],
        },
        {
            label: "Direction",
            prop: "flex-direction",
            icons: ["arrow_forward", "arrow_back", "arrow_downward", "arrow_upward"],
        },
        {
            label: "Align",
            prop: "align-items",
            description: "How to align items across the selected Direction.",
            icons: [
                "align_vertical_top",
                "align_vertical_bottom",
                "align_vertical_center",
                "text_fields",
                "pause",
            ],
        },
        {
            label: "Justify",
            prop: "justify-content",
            description: "How to align children in the selected Direction.",
            icons: [
                { icon: "format_align_left", description: "" },
                { icon: "format_align_right", description: "" },
                { icon: "format_align_center", description: "" },
                { icon: "horizontal_distribute", description: "" },
                { icon: "more_horiz", description: "" },
            ],
        },
        {
            label: "Wrap",
            prop: "flex-wrap",
            icons: ["arrow_right_alt", "subdirectory_arrow_left", "arrow_upward"],
        },
        {
            label: "H Gap",
            prop: "column-gap",
            description: "Horizontal gap between children.",
            width: 50,
        },
        {
            label: "V Gap",
            prop: "row-gap",
            description: "Vertical gap between children.",
            width: 50,
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const SizingStyleGroup = {
    title: "Size and Spacing",
    hideRoot: true,
    items: [
        {
            prop: "margin",
            width: 50,
        },
        {
            prop: "padding",
            width: 50,
        },
        {
            prop: "width",
            width: 50,
        },
        {
            prop: "height",
            width: 50,
        },
        {
            label: "Min W",
            prop: "min-width",
            description: "Minimum width.",
            width: 50,
        },
        {
            label: "Min H",
            prop: "min-height",
            description: "Minimum height.",
            width: 50,
        },
        {
            label: "Max W",
            prop: "max-width",
            description: "Maximum width.",
            width: 50,
        },
        {
            label: "Max H",
            prop: "max-height",
            description: "Maximum height.",
            width: 50,
        },
        {
            prop: "overflow-y",
            label: "Overflow",
            icons: [
                {
                    icon: "visibility",
                    description: "Show children that are bigger than this block.",
                },
                {
                    icon: "visibility_off",
                    description: "Hide children that are bigger than this block.",
                },
                {
                    icon: "format_line_spacing",
                    description: "Show a scrollbar for scrolling content.",
                },
                {
                    icon: "electric_bolt",
                    description: "Show a scrollbar if children are bigger than this block.",
                },
            ],
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const PositionStyleGroup = {
    title: "Position",
    hideRoot: true,
    items: [
        {
            prop: "position",
        },
        {
            prop: "positioning",
            width: 50,
        },
        {
            prop: "float",
            icons: ["close", "art_track", { icon: "art_track", horizontal: true }],
        },
        {
            prop: "z-index",
        }
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const TypeStyleGroup = {
    title: "Typography",
    items: [
        {
            prop: "font-family",
            label: "Font",
        },
        {
            prop: "font-weight",
            label: "Weight",
        },
        {
            prop: "font-size",
            width: 50,
        },
        {
            prop: "line-height",
            width: 50,
            label: "Height",
        },
        {
            prop: "color",
        },
        {
            prop: "text-align",
            label: "Align",
            icons: [
                "format_align_left",
                "format_align_center",
                "format_align_right",
                "format_align_justify",
            ],
        },
        {
            prop: "text-transform",
            label: "Capitalize",
        },
        {
            prop: "white-space",
            label: "Breaking",
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const BackgroundStyleGroup = {
    title: "Background",
    items: [
        {
            prop: "background-color",
            label: "Color",
        },
        {
            prop: "background",
            label: "Images & Gradients",
        },
        {
            prop: "background-clip",
            label: "Clipping",
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const BackgroundFuncItem = {
    prop: {
        id: "background-image-func",
        property: "background-image-func",
        type: "select",
        options: [
            { id: "url", label: "Image" },
            { id: "linear-gradient", label: "Linear Gradient" },
        ],
        default: "url",
    },
    icons: [
        { icon: "image", description: "Image" },
        { icon: "gradient", description: "Linear Gradient" },
    ],
    label: "Type",
};
export const BackgroundGradientItem = {
    prop: {
        id: "background-image-gradient",
        property: "background-image-gradient",
        type: "gradient",
    },
    label: "Colors",
};
export const BackgroundGradientDirection = {
    prop: {
        id: "background-image-direction",
        property: "background-image-direction",
        type: "slider",
        units: ["deg"],
        default: "180",
    },
    label: "Direction",
};
export const BackgroundImageGroup = {
    title: "Background Image",
    items: [
        {
            prop: "background-image",
            label: "Image",
        },
        {
            prop: "background-size",
            label: "Width",
        },
        {
            prop: "background-size-y",
            label: "Height",
        },
        {
            prop: "background-position",
            label: "Position",
            icons: [
                {
                    icon: "arrow_outward",
                    horizontal: true,
                },
                "arrow_upward",
                "arrow_outward",
                "arrow_back",
                "control_camera",
                "arrow_forward",
                {
                    icon: "arrow_outward",
                    vertical: true,
                    horizontal: true,
                },
                "arrow_downward",
                {
                    icon: "arrow_outward",
                    vertical: true,
                },
            ],
            iconColumns: 3,
            hideTooltips: true,
        },
        {
            prop: "background-repeat",
            label: "Repeat",
            icons: ["grain", "more_horiz", "more_vert", "noise_control_off"],
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const BordersGroup = {
    title: "Borders",
    hideRoot: true,
    items: [
        {
            prop: "border-radius",
            label: "Radius",
            width: 52,
        },
        {
            prop: "border",
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const BorderStyleGroup = {
    title: "Borders",
    items: [
        {
            prop: "border-width",
            label: "Width",
        },
        {
            prop: "border-style",
            label: "Style",
        },
        {
            prop: "border-color",
            label: "Color",
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const EffectsStyleGroup = {
    title: "Effects",
    items: [
        {
            prop: "box-shadow",
        },
        {
            prop: "mix-blend-mode",
            label: "Blending",
        },
        {
            prop: "opacity",
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
export const BoxShadowGroup = {
    title: "Box Shadow",
    items: [
        {
            prop: "box-shadow-type",
            label: "Type",
        },
        {
            prop: "box-shadow-h",
            label: "Horiz",
            description: "Horizontal distance for shadow.",
            width: 50,
        },
        {
            prop: "box-shadow-v",
            label: "Vert",
            description: "Vertical distance for shadow.",
            width: 50,
        },
        {
            prop: "box-shadow-blur",
            label: "Blur",
            width: 50,
        },
        {
            prop: "box-shadow-spread",
            label: "Spread",
            width: 50,
        },
        {
            prop: "box-shadow-color",
            label: "Color",
        },
    ].map((it) => {
        return Object.freeze({
            ...it,
            prop: cssProperties.get(it.prop),
        });
    }),
};
