import TemplateBlockView from "@/components/palette/blocks/TemplateBlockView.vue";
import NftBlockView from "@/components/palette/blocks/NftBlockView.vue";
import WalletBlockView from "@/components/palette/blocks/WalletBlockView.vue";
import RepeatBlockView from "@/components/palette/blocks/RepeatBlockView.vue";
import RepeatChildView from "@/components/palette/blocks/RepeatChildView.vue";
import ConditionBlockView from "@/components/palette/blocks/ConditionBlockView.vue";
import BalanceBlockView from "@/components/palette/blocks/BalanceBlockView.vue";
import ImageBlockView from "@/components/palette/blocks/ImageBlockView.vue";
import DumbappBlockView from "@/components/palette/blocks/DumbappBlockView.vue";
import SpinnerBlockView from "@/components/palette/blocks/SpinnerBlockView.vue";
import LayoutBlockView from "@/components/palette/blocks/LayoutBlockView.vue";
import ContractBlockView from "@/components/palette/blocks/ContractBlockView.vue";
import CheckboxBlockView from "@/components/palette/blocks/CheckboxBlockView.vue";
import InputBlockView from "@/components/palette/blocks/InputBlockView.vue";
import ContribTokenBlockView from "@/components/palette/blocks/ContribTokenBlockView.vue";
export default {
    components: {
        SpinnerBlockView,
        DumbappBlockView,
        ImageBlockView,
        WalletBlockView,
        NftBlockView,
        TemplateBlockView,
        LayoutBlockView,
        RepeatBlockView,
        RepeatChildView,
        BalanceBlockView,
        ConditionBlockView,
        ContractBlockView,
        CheckboxBlockView,
        InputBlockView,
        ContribTokenBlockView,
    },
    props: {
        block: {
            type: Object,
        },
    },
    methods: {
        componentForType: function (block) {
            switch (block.block.type.type) {
                case "template":
                    return TemplateBlockView;
                case "nft":
                    return NftBlockView;
                case "wallet":
                    return WalletBlockView;
                case "repeat":
                    return RepeatBlockView;
                case "repeat-child":
                    return RepeatChildView;
                case "condition":
                    return ConditionBlockView;
                case "balance":
                    return BalanceBlockView;
                case "image":
                    return ImageBlockView;
                case "dumbapp":
                    return DumbappBlockView;
                case "spinner":
                    return SpinnerBlockView;
                case "contract":
                    return ContractBlockView;
                case "checkbox":
                    return CheckboxBlockView;
                case "input":
                    return InputBlockView;
                case "contrib-token":
                    return ContribTokenBlockView;
                default:
                    return LayoutBlockView;
            }
        },
    },
    mounted() {
        let block = this.block.block;
        if (block.canHaveChildren()) {
            block.onLayout(this.layout);
        }
        block.element = this.$el;
    },
    beforeDestroy() {
        let block = this.block?.block;
        // There are cases where the block hierarchy is already destroyed
        if (block) {
            if (block.canHaveChildren()) {
                block.offLayout(this.layout);
            }
            if (block.element === this.$el) {
                block.element = null;
            }
        }
    },
};
