import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import { CompositeStyleSet, } from "@blockwell/palette";
import NumberStyleSetting from "@/components/palette/settings/style/types/NumberStyleSetting.vue";
import { omit } from "remeda";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "SpacingStyleSetting",
    components: { StyleLabel, NumberStyleSetting },
    mixins: [StyleSettingMixin],
    data() {
        return {
            locked: false,
        };
    },
    computed: {
        virtual() {
            return this.item.prop.virtual;
        },
        props() {
            let prop = this.item.prop;
            return prop.properties;
        },
        orientation() {
            switch (this.entry?.prop?.property) {
                case "border-radius":
                    return "corners";
                default:
                    return "edges";
            }
        },
        layer() {
            if (this.virtual) {
                return this.set;
            }
            return CompositeStyleSet.from(this.entry);
        },
    },
    watch: {
        entry: {
            immediate: true,
            handler(val) {
                if (this.item.prop.property === "border-radius") {
                    if (val.value.values.length === 4) {
                        let first = val.value.values[0];
                        let match = true;
                        for (let it of val.value.values) {
                            if (it.value !== first.value ||
                                it.fixedValue !== first.fixedValue ||
                                it.unit !== first.unit) {
                                match = false;
                                break;
                            }
                        }
                        this.locked = match;
                    }
                    else {
                        this.locked = true;
                    }
                }
                else {
                    this.locked = false;
                }
            },
        },
    },
    methods: {
        update(index, update) {
            if (this.virtual) {
                this.$emit("update", update);
            }
            else {
                let entry = this.entry;
                if (this.locked) {
                    entry.updateAll(omit(update, ["property"]));
                    this.updateFromEntry();
                }
                else {
                    entry.update([update]);
                    this.updateFromEntry();
                }
            }
        },
    },
};
