import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default {
    name: "NftBlockView",
    mixins: [BlockViewMixin],
    data() {
        return {
            layout: this.block.block.type.param.layout || "card"
        };
    },
    methods: {
        onValue(value) {
            if (this.layout !== value.layout) {
                this.layout = value.layout;
            }
        }
    }
};
