import BaseSettingTypesMixin from "@/components/palette/settings/types/BaseSettingTypesMixin";
import { blockVariableInfo, valueVariableInfo } from "@blockwell/palette";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import { clone } from "remeda";
import { paletteConditionOperators } from "@blockwell/variables";
export default {
    name: "ConditionConfig",
    components: { NameFind, RadioTabs },
    mixins: [BaseSettingTypesMixin],
    inject: ["manager"],
    props: {
        value: {
            type: Object,
        },
        wrap: {
            type: Object,
        },
        parentIndex: {
            type: [Number, Array],
        },
        filterSample: {
            type: Object,
        },
        first: Boolean
    },
    data() {
        let selectSetting = {
            type: "select",
            enum: [null, ...paletteConditionOperators],
        };
        return {
            logic: this.value?.logic || "and",
            left: this.value?.left?.param?.name || "item",
            op: this.value.op,
            right: this.value.right,
            selectSetting,
        };
    },
    computed: {},
    methods: {
        updateLogic(logic) {
            this.logic = logic;
            this.emitSettings();
        },
        updateLeft(name) {
            this.left = name;
            this.emitSettings();
        },
        updateOp(op) {
            this.op = op?.op;
            if (op?.op === "truthy" || op?.op === "not truthy") {
                this.right = undefined;
            }
            this.emitSettings();
        },
        updateRight(right) {
            this.right = right.right;
            this.emitSettings();
        },
        source() {
            let cached;
            let timeout;
            return (q, populate) => {
                let query = q?.toLowerCase();
                if (!cached) {
                    let sample = this.filterSample;
                    cached = [];
                    if (sample) {
                        valueVariableInfo(cached, null, this.wrap.block.key, "value", sample, ["item"]);
                    }
                    else {
                        cached = blockVariableInfo(this.wrap.block, null, this.parentIndex).filter((it) => it.valueType !== "Null");
                    }
                }
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => (cached = null), 3000);
                let vars = cached;
                if (query) {
                    vars = vars.filter((it) => it.name.startsWith(query) ||
                        it.path.find((part) => part.startsWith(query)));
                }
                populate(vars);
            };
        },
        inputValue(info) {
            return info?.name;
        },
        suggestion(info) {
            if (!info.name) {
                return "";
            }
            if (info) {
                return `<div class="var-name">${info.name}</div>
                    <div class="var-info">
                        <div class="var-type">${info.valueType}</div>
                        <div class="var-value">${info.value || ""}</div>
                    </div>`;
            }
            return "";
        },
        emitSettings() {
            let value = {
                left: {
                    type: "var",
                    param: {
                        name: this.left
                    }
                }
            };
            if (this.logic) {
                value.logic = this.logic;
            }
            if (this.right) {
                value.right = clone(this.right);
            }
            if (this.op) {
                value.op = this.op;
            }
            this.$emit("update", value);
        }
    },
};
