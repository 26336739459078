import Clipboard from "@/components/Clipboard.vue";
import { mapGetters } from "vuex";
export default {
    name: "WalletBlockBlockwell",
    components: { Clipboard },
    props: {
        account: String
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "api"]),
    },
    watch: {
        api: {
            immediate: true,
            handler(val, old) {
                old?.events?.off("login", this.login);
                val?.events?.on("login", this.login);
            },
        },
    },
    methods: {
        login() {
            this.$bus.emit("login_request", {
                message: "Sign In to load your Blockwell Wallet.",
            });
        },
    },
    beforeDestroy() {
        this.api.events.off("login", this.login);
    },
};
