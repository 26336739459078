import { PageManager } from "@blockwell/palette";
import PageTab from "@/components/palette/settings/page/PageTab.vue";
import PageEditor from "@/components/palette/settings/page/PageEditor.vue";
import MainPageEditor from "@/components/palette/settings/page/MainPageEditor.vue";
export default {
    name: "PageList",
    computed: {
        PageManager() {
            return PageManager;
        },
    },
    components: { MainPageEditor, PageEditor, PageTab },
    props: {
        manager: {
            type: Object,
        },
        index: Number,
    },
    data() {
        return {
            pages: [],
            activePage: this.index || 0,
            newPage: null,
        };
    },
    watch: {
        index: {
            immediate: true,
            handler(val) {
                this.activePage = val;
                if (val === -1 && !this.newPage) {
                    this.newPage = PageManager.create();
                }
            },
        },
        activePage(val) {
            this.$emit("update:index", val);
        },
    },
    methods: {
        onChange(change) {
            if (change.type === "page") {
                this.loadPages();
            }
        },
        loadPages() {
            this.pages = this.manager.page.getPages();
        },
        created(name) {
            let index = this.manager.page.getPages().findIndex((it) => it.name === name) + 1;
            this.activePage = index;
            this.newPage = null;
        },
    },
    mounted() {
        this.loadPages();
        this.manager.registry.events.on("change", this.onChange);
    },
    beforeDestroy() {
        this.manager.registry.events.off("change", this.onChange);
    },
};
