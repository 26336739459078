import PaletteEditorDialogMixin from "@/components/palette/settings/PaletteEditorDialogMixin";
import FuelUse from "@/components/fuel/FuelUse.vue";
import FuelSubscriptionView from "@/components/fuel/FuelSubscriptionView.vue";
export default {
    name: "PaletteSubscription",
    components: { FuelSubscriptionView, FuelUse },
    mixins: [PaletteEditorDialogMixin],
    data() {
        return {
            subscriptionId: null,
            subError: null,
            popover: false,
        };
    },
    computed: {
        foreverFree() {
            return this.saveState.subscriptionActive && !this.saveState.subscriptionExpires;
        },
    },
    methods: {
        submit(ticketId) {
            const api = this.$store.getters["user/api"].palette;
            api.subscribe(this.name, ticketId)
                .then((res) => {
                this.subscriptionId = res.id;
                this.subError = null;
            })
                .catch((err) => {
                this.subError = err.message;
            });
        },
        update() {
            this.$emit("update");
        }
    },
};
