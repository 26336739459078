import { Typing } from "@blockwell/eth-types";
import Info from "@/components/Info.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import DynamicContextMixin from "@/components/palette/settings/types/DynamicContextMixin";
import ArgumentVariableSetting from "@/components/palette/settings/types/ArgumentVariableSetting.vue";
export default {
    name: "ArgumentsSetting",
    components: { ArgumentVariableSetting, VariableSetting, Info },
    mixins: [SettingMixin, DynamicContextMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: Array,
        },
    },
    data() {
        let i = 0;
        let value = this.value?.map((value) => {
            if ("value" in value) {
                return value;
            }
            return {
                name: "arg" + i++,
                value,
            };
        });
        return {
            args: (value || []),
            previousKey: this.uniqueKey,
            argSetting: {
                type: "var",
                allowNull: !!this.setting?.form,
            },
        };
    },
    watch: {
        "dynamicContext.args": {
            immediate: true,
            deep: true,
            handler(val) {
                if (!val) {
                    this.args = [];
                    return;
                }
                let previous;
                if (this.uniqueKey === this.previousKey) {
                    previous = [...this.args];
                }
                let args = [];
                let i = 0;
                for (let it of val) {
                    let val;
                    if (previous?.length > 0) {
                        val = previous.shift();
                        val.name = it.name;
                        val.typing = it.typing;
                    }
                    else {
                        let conf;
                        if (this.value?.[i]) {
                            let value = this.value[i];
                            if ("value" in value) {
                                conf = value;
                            }
                            else {
                                conf = {
                                    name: "arg" + i,
                                    value,
                                };
                            }
                        }
                        val = {
                            name: it.name,
                            typing: it.typing,
                            prefill: conf?.prefill,
                            form: conf?.form,
                            value: conf?.value,
                        };
                        if (!conf?.form && !conf?.value) {
                            val.value = {
                                type: "var",
                                param: {
                                    name: "",
                                },
                            };
                        }
                    }
                    if (!val.typing && this.dynamicContext.abi) {
                        val.typing = Typing.parse(this.dynamicContext.abi.inputs[i]);
                    }
                    if (!val.typing && it.typing) {
                        val.typing = it.typing;
                    }
                    args.push(val);
                    ++i;
                }
                this.args = args;
                this.previousKey = this.uniqueKey;
            },
        },
    },
    methods: {
        input(index, update) {
            if (update) {
                let args = this.args;
                let old = args[index];
                if (old) {
                    let arg = {
                        name: old.name,
                        typing: old.typing,
                        value: update.value,
                    };
                    if (update.prefill) {
                        arg.prefill = true;
                    }
                    if (update.form) {
                        arg.form = true;
                    }
                    this.$set(this.args, index, arg);
                    if (this.setting.path) {
                        this.emitSetting(this.args.map((it) => it.value));
                    }
                    else {
                        this.emitSetting(this.args);
                    }
                }
            }
        },
        typing(info) {
            return Typing.toString(info);
        },
    },
};
