import Modal from "@/components/Modal.vue";
import ContractLoader from "@/views/happs/components/ContractLoader.vue";
import Recent from "@/lib/Recent";
const recent = new Recent();
export default {
    name: "FindContract",
    components: { ContractLoader, Modal },
    data() {
        return {
            show: false
        };
    },
    methods: {
        contractSelected(contract) {
            this.$emit("input", {
                address: {
                    type: "const",
                    param: {
                        value: contract.address
                    }
                },
                chain: {
                    type: "const",
                    param: {
                        value: contract.network
                    }
                }
            });
            this.show = false;
            recent.addUserRecent("findcontract", contract);
        }
    }
};
