import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import Checkboard from "@/components/color/Checkboard.vue";
import { tinycolor } from "@ctrl/tinycolor";
import { parseGradientSteps } from "@blockwell/palette";
import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
import ColorInput from "@/components/color/ColorInput.vue";
import ColorPicker from "@/components/color/ColorPicker.vue";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "GradientStyleSetting",
    components: { StyleLabel, ColorPicker, ColorInput, SettingsPopper, Checkboard },
    mixins: [StyleSettingMixin],
    inject: ["manager"],
    data() {
        return {
            steps: [],
            moveIndex: -1,
            moveX: 0,
            moveY: 0,
            movePos: 0,
            moveTime: 0,
            fullWidth: 0,
            showColor: -1,
            currentColor: "",
            recent: []
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.steps = parseGradientSteps(value);
            },
        },
    },
    computed: {
        styledSteps() {
            let steps = this.steps;
            let styled = [];
            for (let step of steps) {
                let adjust = Math.floor((step.pos * 15) / 100);
                let color = this.resolveValue(step.color);
                let dark = tinycolor(color).isDark();
                styled.push({
                    step,
                    style: {
                        left: `calc(${step.pos}% - ${adjust}px)`,
                        "background-color": color,
                        "border-color": dark ? "#aaa" : "#222",
                    },
                });
            }
            return styled;
        },
        resolvedGradient() {
            if (this.steps) {
                let steps = this.steps;
                return steps.map((it) => `${this.resolveValue(it.color)} ${it.pos}%`).join(",");
            }
            return "";
        },
        gradient() {
            if (this.steps) {
                let steps = this.steps;
                return steps.map((it) => `${it.color} ${it.pos}%`).join(",");
            }
            return "";
        },
        theme() {
            return this.manager?.color?.colors;
        }
    },
    methods: {
        colorInput(ev) {
            let color;
            if (typeof ev === "string") {
                color = ev;
            }
            else {
                if (ev.rgba.a === 1) {
                    color = ev.hex;
                }
                else {
                    color = `rgba(${ev.rgba.r}, ${ev.rgba.g}, ${ev.rgba.b}, ${ev.rgba.a})`;
                }
            }
            this.steps[this.showColor].color = color;
            this.entry.update({ value: this.gradient });
            this.updateFromEntry();
        },
        resolveValue(value) {
            if (this.manager) {
                const colors = this.manager.color;
                return colors.resolveColor(value);
            }
            return value;
        },
        stepClick(index, ev) {
            if (Date.now() - this.moveTime < 500 && Math.abs(ev.clientX - this.moveX) < 5) {
                this.recent = this.manager?.getRecentColors();
                this.currentColor = this.steps[index].color;
                this.showColor = index;
            }
        },
        updateShow(show) {
            if (!show) {
                this.showColor = -1;
            }
        },
        addStep(ev) {
            let el = ev.target;
            let bound = el.getBoundingClientRect();
            let offsetX = ev.clientX - bound.x;
            let width = bound.width - 15;
            let pos = Math.round((offsetX * 100) / width);
            let steps = this.steps;
            let i = steps.findIndex(it => it.pos > pos);
            if (i === -1) {
                steps.push({
                    color: steps[steps.length - 1].color,
                    pos
                });
            }
            else {
                let color;
                if (i === 0) {
                    color = steps[0].color;
                }
                else {
                    color = tinycolor(steps[i - 1].color).mix(steps[i].color, 50).toString();
                }
                steps.splice(i, 0, {
                    color,
                    pos
                });
            }
        },
        mousedown(index, ev) {
            this.moveIndex = index;
            this.moveX = ev.clientX;
            this.moveY = ev.clientY;
            this.movePos = this.steps[index].pos;
            this.moveTime = Date.now();
            this.fullWidth = this.$refs.wrap.offsetWidth - 15;
            window.addEventListener("mouseup", this.mouseup, { once: true });
            window.addEventListener("mousemove", this.mousemove);
            this.$emit("active", true);
            ev.preventDefault();
        },
        mouseup(ev) {
            if (this.steps.length > 2) {
                let y = Math.abs(ev.clientY - this.moveY);
                if (y > 40) {
                    this.$refs.step[this.moveIndex].classList.remove("delete");
                    this.steps.splice(this.moveIndex, 1);
                    this.entry.update({ value: this.gradient });
                    this.updateFromEntry();
                }
            }
            this.moveIndex = -1;
            window.removeEventListener("mousemove", this.mousemove);
            setTimeout(() => {
                this.$emit("active", false);
            }, 50);
        },
        mousemove(ev) {
            if (this.moveIndex === -1) {
                return;
            }
            let pos = Math.max(0, Math.min(100, Math.round(this.movePos + ((ev.clientX - this.moveX) * 100) / this.fullWidth)));
            if (this.steps.length > 2) {
                let y = Math.abs(ev.clientY - this.moveY);
                if (y > 40) {
                    this.$refs.step[this.moveIndex].classList.add("delete");
                }
                else {
                    this.$refs.step[this.moveIndex].classList.remove("delete");
                }
            }
            if (pos !== this.steps[this.moveIndex].pos) {
                this.steps[this.moveIndex].pos = pos;
                let j = 1;
                for (let i = this.moveIndex + 1; i < this.steps.length; i++) {
                    if (this.steps[i].pos <= pos) {
                        this.steps[i].pos = pos + j++;
                    }
                }
                j = 1;
                for (let i = this.moveIndex - 1; i >= 0; i--) {
                    if (this.steps[i].pos >= pos) {
                        this.steps[i].pos = pos - j++;
                    }
                }
                this.entry.update({ value: this.gradient });
                this.updateFromEntry();
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener("mousemove", this.mousemove);
    },
};
