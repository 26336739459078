import { StackStyleSet } from "@blockwell/palette";
import { BackgroundFuncItem, BackgroundGradientDirection, BackgroundGradientItem, BackgroundImageGroup } from "@/components/palette/settings/style/style-groups";
import StyleComponentMixin from "@/components/palette/settings/style/StyleComponentMixin";
import { debounce } from "@blockwell/util";
export default {
    name: "BackgroundStackSettings",
    mixins: [StyleComponentMixin],
    props: {
        layer: {
            type: Object,
        },
        index: Number,
    },
    data() {
        return {
            funcItem: BackgroundFuncItem,
            gradItem: BackgroundGradientItem,
            dirItem: BackgroundGradientDirection,
        };
    },
    computed: {
        func() {
            let image = this.layer.get("background-image")?.value || "url()";
            let index = image.indexOf("(");
            let name = image.slice(0, index);
            let content = image.slice(index + 1, -1).trim();
            let deg = "180";
            if (name.includes("gradient")) {
                let match = /^\s*(\d+)deg\s*,(.*)/.exec(content);
                if (match) {
                    deg = match[1];
                    content = match[2];
                }
            }
            return {
                name,
                content,
                deg,
            };
        },
        funcLayer() {
            let funcLayer = new StackStyleSet(this.layer.prop);
            funcLayer.values["background-image-func"] = {
                type: "select",
                value: this.func.name,
            };
            return funcLayer;
        },
        gradientLayer() {
            let layer = new StackStyleSet(this.layer.prop);
            layer.values["background-image-gradient"] = {
                type: "gradient",
                value: this.func.content,
            };
            layer.values["background-image-direction"] = {
                type: "slider",
                value: this.func.deg,
                unit: "deg",
            };
            return layer;
        },
        group() {
            let items;
            if (this.func.name === "url") {
                items = BackgroundImageGroup.items;
            }
            else {
                items = [...BackgroundImageGroup.items.slice(1)];
            }
            let size = this.layer.get("background-size");
            if (size?.fixedValue === "contain" || size?.fixedValue === "cover") {
                items = items.filter(it => it.prop.property !== "background-size-y");
            }
            return items;
        },
    },
    methods: {
        updateFunc(entry) {
            if (this.func.name !== entry.value) {
                let up;
                switch (entry.value) {
                    case "linear-gradient":
                        up = {
                            property: "background-image",
                            type: "file",
                            value: "linear-gradient(black, white)",
                        };
                        break;
                    default:
                        up = {
                            property: "background-image",
                            type: "file",
                            value: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=)",
                        };
                }
                this.$emit("update", up);
            }
        },
        update(entry) {
            if (entry.property === "background-image-gradient") {
                this.updateGradient(entry);
            }
            else if (entry.property === "background-image-direction") {
                this.updateGradientDirection(entry);
            }
            else {
                this.$emit("update", entry);
            }
        },
        updateGradient: debounce(function (entry) {
            let up = {
                property: "background-image",
                value: `${this.func.name}(${this.func.deg || "180"}deg, ${entry.value})`,
            };
            this.$emit("update", up);
        }, 100, { maxWait: 200 }),
        updateGradientDirection: debounce(function (entry) {
            let up = {
                property: "background-image",
                value: `${this.func.name}(${entry.value}deg, ${this.func.content})`,
            };
            this.$emit("update", up);
        }, 100, { maxWait: 200 }),
        active(active) {
            this.$emit("update:prevent-close", active);
        },
    },
};
