import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import DynamicContextMixin from "@/components/palette/settings/types/DynamicContextMixin";
import CurrentValue from "@/components/palette/settings/CurrentValue.vue";
export default {
    name: "DumbappSetting",
    components: { CurrentValue, VariableSetting, SelectField, Info },
    mixins: [SettingMixin, DynamicContextMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: Object,
        },
    },
    data() {
        return {
            currentValue: null,
            errorValue: null,
            dumbapp: null,
        };
    },
    computed: {
        variableSetting() {
            return {
                ...this.setting,
                type: "var",
                variableType: "value",
            };
        },
        dumbappTitle() {
            return this.dumbapp?.getTitle();
        }
    },
    methods: {
        resolved(val) {
            this.currentValue = val?.value;
            if (val?.type === "value") {
                if (typeof val.value === "string" && val.value.length === 6) {
                    let api = this.$store.getters["user/api"];
                    return api.getDumbapp(val.value).then((dumbapp) => {
                        if (this.currentValue === val.value) {
                            let args = dumbapp.dynamic.map((it) => {
                                return {
                                    name: it.name,
                                    typing: it.typing,
                                };
                            });
                            this.dynamicContext.args = args;
                            this.dumbapp = dumbapp;
                            this.errorValue = null;
                        }
                    }).catch(err => {
                        if (this.currentValue === val.value) {
                            this.dumbapp = null;
                            this.errorValue = err.message;
                        }
                    });
                }
            }
            this.dumbapp = null;
            this.errorValue = "Invalid shortcode.";
        },
    },
};
