import Modal from "@/components/Modal.vue";
import PaletteMainMenu from "@/components/palette/settings/menu/PaletteMainMenu.vue";
import CodeBlock from "@/components/CodeBlock.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import PageEditor from "@/components/palette/settings/page/PageEditor.vue";
import PageList from "@/components/palette/settings/page/PageList.vue";
import PaletteSaveAs from "@/components/palette/settings/PaletteSaveAs.vue";
import dayjs from "dayjs";
import PaletteVersionsManager from "@/components/palette/settings/PaletteVersionsManager.vue";
import PaletteSharing from "@/components/palette/settings/PaletteSharing.vue";
import PaletteExport from "@/components/palette/settings/PaletteExport.vue";
import PaletteImport from "@/components/palette/settings/PaletteImport.vue";
import PalettePageEditor from "@/components/palette/settings/PalettePageEditor.vue";
import PaletteSubscription from "@/components/palette/settings/PaletteSubscription.vue";
import PaletteSubscriptionStatus from "@/components/palette/settings/PaletteSubscriptionStatus.vue";
export default {
    name: "PaletteEditorHeader",
    components: {
        PaletteSubscriptionStatus,
        PalettePageEditor,
        PaletteExport,
        PaletteSharing,
        PaletteVersionsManager,
        PaletteSaveAs,
        PageList,
        PageEditor,
        NameFind,
        LoadingButton,
        CodeBlock,
        PaletteMainMenu,
        Modal,
        PaletteImport,
        PaletteSubscription,
    },
    inject: ["editorState", "saveState"],
    props: {
        name: String,
        manager: {
            type: Object,
        },
    },
    data() {
        const editorState = this.editorState;
        return {
            tab: editorState.tab,
            page: "main",
            editPage: null,
            editorDialog: null,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        localDraft() {
            return !!this.saveState.localDraft;
        },
        saveInfo() {
            let saveState = this.saveState;
            if (saveState.isNew) {
                return "Unsaved New Palette";
            }
            else if (saveState.localDraft) {
                return "Unpublished draft";
            }
            else if (saveState.isDraft) {
                return "Unpublished draft saved";
            }
            else if (saveState.version > 0) {
                return "Published " + dayjs(saveState.version).format("lll");
            }
            else if (saveState.version > -1) {
                return "Published";
            }
        },
        publishIcon() {
            if (this.saveState.subscriptionStatus === "cancelled" ||
                this.saveState.subscriptionStatus === "suspended") {
                return "info";
            }
            else {
                return "public";
            }
        },
        publishColor() {
            if (this.saveState.subscriptionStatus === "cancelled" ||
                this.saveState.subscriptionStatus === "suspended") {
                return "danger";
            }
            else {
                return "primary";
            }
        },
        dialogComponent() {
            switch (this.editorDialog) {
                case "page":
                    return PalettePageEditor;
                case "import":
                    return PaletteImport;
                case "export":
                    return PaletteExport;
                case "sharing":
                    return PaletteSharing;
                case "versions":
                    return PaletteVersionsManager;
                case "saveAs":
                    return PaletteSaveAs;
                case "subscription":
                    return PaletteSubscription;
                default:
                    return null;
            }
        },
    },
    watch: {
        tab(val) {
            const editorState = this.editorState;
            editorState.tab = val;
        },
        "editorState.tab"(val) {
            if (val !== this.tab) {
                this.tab = val;
            }
        },
        "editorState.editorPath": {
            immediate: true,
            handler(val) {
                this.editorDialog = val[0];
            },
        },
        manager: {
            immediate: true,
            handler(manager, old) {
                if (old) {
                    old.page.events.off("activate", this.onActivate);
                }
                if (manager) {
                    manager.page.events.on("activate", this.onActivate);
                }
            },
        },
        page(name) {
            if (name === "main") {
                name = undefined;
            }
            if (this.manager.page.activePageName !== name) {
                this.$router.push({
                    query: this.$route.query,
                    hash: this.$route.hash,
                    path: `/palette2/${this.manager.name}/${name}`,
                });
            }
        },
    },
    methods: {
        action(action) {
            switch (action) {
                case "save":
                    this.save();
                    break;
                case "save-as":
                    this.openSaveAs();
                    break;
                case "export":
                    this.export();
                    break;
                case "import":
                    this.importPalette();
                    break;
                case "discard":
                    this.discard();
                    break;
                case "publish":
                    this.publish();
                    break;
                case "history":
                    this.dialogOpen("versions");
                    break;
                case "sharing":
                    this.dialogOpen("sharing");
                    break;
                case "subscription":
                    this.dialogOpen("subscription");
                    break;
            }
        },
        save() {
            let saveState = this.saveState;
            saveState.events.emit("save", null);
        },
        discard() {
            let saveState = this.saveState;
            saveState.events.emit("discard", null);
        },
        publish() {
            let saveState = this.saveState;
            if (saveState.subscriptionActive) {
                saveState.events.emit("publish", null);
            }
            else {
                this.dialogOpen("subscription");
            }
        },
        openSaveAs() {
            this.dialogOpen("saveAs");
        },
        openImages() {
            this.$emit("images");
        },
        close() {
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: "",
            });
        },
        preview(ev) {
            const editorState = this.editorState;
            let target = ev.target;
            editorState.mode = target.checked ? "preview" : "default";
        },
        export() {
            this.dialogOpen("export");
        },
        importPalette() {
            this.dialogOpen("import");
        },
        source(q, populate) {
            populate([{ name: "main", layout: "Main Palette" }, ...this.manager.page.getPages()]);
        },
        onActivate({ page }) {
            const editorState = this.editorState;
            if (!page) {
                this.page = "main";
                editorState.pageEditorIndex = 0;
            }
            else {
                this.page = page.name;
                editorState.pageEditorIndex =
                    this.manager.page.getPages().findIndex((it) => it === page) + 1;
            }
        },
        addPage() {
            const editorState = this.editorState;
            editorState.pageEditorIndex = -1;
            this.dialogOpen("page");
        },
        openPage() {
            this.dialogOpen("page");
        },
        pageCreated(name) {
            this.editPage = null;
            this.$router.push({
                query: this.$route.query,
                hash: "#editor",
                path: `/palette2/${this.manager.name}/${name}`,
            });
        },
        pageChange(name) {
            this.page = name;
        },
        dialogOpen(name) {
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: `#editor/${name}`,
            });
        },
        dialogClose() {
            this.$router.push({
                path: this.$route.path,
                query: this.$route.query,
                hash: "#editor",
            });
        },
        updateSubscription() {
            this.subscriptionInterval.runNow();
        }
    },
    beforeDestroy() {
        this.manager?.page?.events?.off("activate", this.onActivate);
    },
};
