import Emittery from "emittery";
import { addBlock, addBlocksFromSpec, } from "@blockwell/palette";
import { debounce } from "@blockwell/util";
import { clone } from "remeda";
function calculatePosition(parent, child, width, height) {
    let parentRect = parent.getBoundingClientRect();
    let childRect = child.getBoundingClientRect();
    let x = childRect.x - parentRect.x;
    let y = childRect.y - parentRect.y;
    if (x < 0) {
        if (x + width < 0) {
            x = 0;
            width = 0;
        }
        else {
            width = width + x;
            x = 0;
        }
    }
    return new DOMRect(x, y, width, height);
}
export default {
    data() {
        return {
            editorState: {
                name: this.name,
                editorLayout: false,
                editing: false,
                dragging: false,
                mode: "default",
                events: Object.freeze({
                    drag: new Emittery(),
                    controls: new Emittery(),
                }),
                tab: "block",
                style: null,
                showImages: false,
                imageRequestId: 0,
                options: {
                    extraSpacing: true,
                    outlines: true,
                    conditionBlocks: true,
                    conditionStripes: true,
                    selection: true,
                },
                treeOptions: {
                    showRepeats: false,
                    contexts: false,
                },
                hoverKey: 0,
                selectKey: 0,
                selectVariable: null,
                subscriptionStatus: null,
                editorPath: [],
                pageEditorIndex: 0,
                clipboard: "",
            },
            dragTarget: null,
            hover: {
                pos: null,
                key: null,
                type: null,
            },
            select: {
                pos: null,
                key: null,
                type: null,
            },
            selectFlip: false,
            selectBarWidth: 0,
            showDropdown: false,
        };
    },
    provide() {
        return {
            editorState: this.editorState,
        };
    },
    computed: {
        hoverCss() {
            return {
                left: this.hover.pos?.x - 2 + "px",
                top: this.hover.pos?.y - 24 + "px",
            };
        },
        selectCss() {
            return {
                left: this.select.pos?.x + "px",
                top: this.select.pos?.y + "px",
                width: this.select.pos?.width + "px",
                height: this.select.pos?.height + "px",
            };
        },
        selectedBlock() {
            return this.select.key;
        },
        controls() {
            if (this.select) {
                if (this.select.type === "repeat-child") {
                    return null;
                }
            }
            if (!this.selectedParent) {
                return null;
            }
            return {
                delete: true,
                anchor: true,
            };
        },
        selectedParent() {
            if (this.select.key) {
                let manager = this.manager;
                let block = manager.registry.blocks.get(this.select.key)?.getParentBlock();
                if (block) {
                    return block.key;
                }
            }
            return null;
        },
        options() {
            return this.$store.state.palette.options.view[this.editorState.mode];
        },
        dropdownMenu() {
            let manager = this.manager;
            let list = [
                {
                    name: "copy",
                    title: "Copy",
                    icon: "content_copy",
                },
                {
                    name: "cut",
                    title: "Cut",
                    icon: "content_cut",
                },
            ];
            list = list.concat([
                {
                    name: "divider",
                },
                {
                    name: "paste_after",
                    title: "Paste After",
                    icon: "content_paste",
                },
            ]);
            let block = manager.registry.blocks.get(this.select.key);
            if (block && block.canHaveChildren()) {
                list = list.concat([
                    {
                        name: "paste_inside",
                        title: "Paste Inside",
                        icon: "content_paste_go",
                    },
                ]);
            }
            list = list.concat([
                {
                    name: "divider",
                },
                {
                    name: "duplicate",
                    title: "Duplicate",
                    icon: "filter_none",
                },
                {
                    name: "delete",
                    title: "Delete",
                    icon: "delete",
                },
            ]);
            return list;
        },
    },
    watch: {
        "editorState.dragging"(val) {
            this.$refs.target.style.display = val ? "block" : "none";
        },
        "editorState.showImages"(val) {
            if (!val) {
                this.editorState.events.controls.emit("imageClose");
            }
        },
        "editorState.name": {
            immediate: true,
            handler(val) {
                if (val === "new") {
                    this.saveState.availability = "new";
                    this.saveState.isNew = true;
                }
                else {
                    this.saveState.isNew = false;
                    let api = this.api;
                    api.palette.availability(val).then((it) => {
                        if (this.editorState.name === val) {
                            this.saveState.availability = it;
                        }
                    });
                }
            },
        },
        "editorState.selectKey": {
            handler(val) {
                this.onSelect(val);
            },
        },
        "editorState.hoverKey": {
            handler(val) {
                this.onHover(val);
            },
        },
        options: {
            deep: true,
            immediate: true,
            handler(val) {
                this.editorState.options = val;
            },
        },
    },
    methods: {
        setupEditor() {
            this.selectBlock(null);
            this.editorState.editorLayout = true;
            setTimeout(() => {
                this.editorState.editing = true;
                this.$nextTick(() => {
                    this.$refs.wrapper.addEventListener("mouseover", this.mouseover);
                    this.$refs.wrapper.addEventListener("mouseout", this.mouseout);
                    this.$refs.wrapper.addEventListener("click", this.click, true);
                    this.$refs.wrapper.addEventListener("mousedown", this.mousedown, false);
                    let manager = this.manager;
                    if (manager) {
                        manager.page.triggerActivate(this.$route.params.path || "");
                    }
                });
            }, 1);
            this.editorState.events.drag.on("drag", this.blockDrag);
            this.editorState.events.drag.on("drop", this.blockDrop);
            document.addEventListener("paste", this.pasteEvent);
            document.addEventListener("copy", this.copyEvent);
            document.addEventListener("cut", this.cutEvent);
            document.addEventListener("keydown", this.keyEvent);
        },
        clearEditor() {
            this.editorState.editing = false;
            setTimeout(() => {
                this.editorState.editorLayout = false;
                let manager = this.manager;
                if (manager) {
                    manager.page.triggerActivate(this.$route.params.path || "");
                }
            }, 1);
            this.$refs.wrapper.removeEventListener("mouseover", this.mouseover);
            this.$refs.wrapper.removeEventListener("mouseout", this.mouseout);
            this.$refs.wrapper.removeEventListener("click", this.click, true);
            this.$refs.wrapper.removeEventListener("mousedown", this.mousedown, false);
            this.editorState.events.drag.off("drag", this.blockDrag);
            this.editorState.events.drag.off("drop", this.blockDrop);
            document.removeEventListener("paste", this.pasteEvent);
            document.removeEventListener("copy", this.copyEvent);
            document.removeEventListener("cut", this.cutEvent);
            document.removeEventListener("keydown", this.keyEvent);
        },
        blockDrag(ev) {
            let target = this.$refs.target;
            if (!target) {
                // In some weird cases target can be null
                return;
            }
            if (ev?.rect) {
                let pos = calculatePosition(this.$refs.edit, ev.element, ev.rect.width, ev.rect.height);
                target.style.display = "block";
                target.style.left = pos.x + ev.rect.x + "px";
                target.style.top = pos.y + ev.rect.y + "px";
                target.style.width = pos.width + "px";
                target.style.height = pos.height + "px";
            }
            else {
                target.style.display = "none";
            }
        },
        blockDrop(ev) {
            let manager = this.manager;
            let target = manager.registry.blocks.get(ev.target);
            let parent;
            let slot;
            let index = undefined;
            if (ev.position) {
                parent = target.parent;
                index = parent.children.indexOf(target);
                if (ev.position === "bottom" || ev.position === "right") {
                    index += 1;
                }
            }
            else {
                parent = target;
                slot = ev.slot;
                index = parent.children.length;
            }
            if (parent.type.type === "repeat") {
                // Special case when a repeat block has no child blocks
                parent = parent.children[0];
            }
            let block;
            if (ev?.block) {
                block = manager.registry.blocks.get(ev.block);
                if (block.key !== target.key && !target.isDescendantOf(block)) {
                    this.dropExistingBlock(block, parent, index);
                }
            }
            else if (ev.type) {
                block = addBlock(ev.type, manager.registry, parent, { spec: [] }, index);
                if (ev.type.children) {
                    addBlocksFromSpec(block, ev.type.children);
                }
                if (block.repeated) {
                    block.updateRepeatSpec();
                }
                else {
                    block.renderTree();
                }
                block.emitChange("add");
            }
            this.dragEnd();
            if (block) {
                // Short delay to allow for layout changes
                setTimeout(() => {
                    this.selectBlock(block);
                }, 50);
            }
        },
        dropExistingBlock(block, target, index) {
            let oldParent = block.parent;
            block.remove();
            target.addChild(block, index);
            block.addContext();
            if (block.repeated) {
                if (oldParent.repeated && block.repeated !== oldParent.repeated) {
                    oldParent.updateRepeatSpec();
                }
                block.updateRepeatSpec();
                block.renderTree();
            }
            else {
                block.addContext();
                block.renderTree();
                if (oldParent.repeated) {
                    oldParent.updateRepeatSpec();
                }
                this.selectBlock(null);
            }
            block.emitChange("move");
        },
        mouseover(ev) {
            let el = ev.target.closest(".palette-block-view");
            if (el) {
                this.editorState.hoverKey = parseInt(el.dataset?.key);
            }
        },
        onHover(key) {
            this.$el
                .querySelector(".palette-block-hover")
                ?.classList?.remove("palette-block-hover");
            if (key) {
                let block = this.manager.registry.blocks.get(key);
                let el = block.element;
                if (el) {
                    el.classList.add("palette-block-hover");
                    let pos = calculatePosition(this.$refs.edit, el, el.offsetWidth, el.offsetHeight);
                    this.hover = {
                        pos,
                        type: block.type.type,
                    };
                }
                else {
                    this.hover = { pos: null, type: null };
                }
            }
            else {
                this.hover = { pos: null, type: null };
            }
        },
        mouseout(ev) {
            if (this.editorState.hoverKey) {
                this.editorState.hoverKey = 0;
            }
        },
        click: (() => {
            let time = 0;
            let x = 0, y = 0;
            return function (ev) {
                let el = ev.target;
                if (!el.classList.contains("palette-block-view")) {
                    el = el.closest(".palette-block-view");
                }
                if (!el) {
                    return;
                }
                let key = parseInt(el.dataset?.key);
                let now = ev.timeStamp;
                let dist = (ev.clientX - x) ^ (2 + (ev.clientY - y)) ^ 2;
                if (this.editorState.selectKey !== key) {
                    this.editorState.selectKey = key;
                    ev.stopPropagation();
                    ev.preventDefault();
                    time = now;
                    x = ev.clientX;
                    y = ev.clientY;
                }
                else if (now !== time && now - time < 300 && dist < 100) {
                    time = 0;
                    ev.stopPropagation();
                    ev.preventDefault();
                    this.editorState.events.controls.emit("doubleclick", { x, y });
                }
                else {
                    time = now;
                    x = ev.clientX;
                    y = ev.clientY;
                }
            };
        })(),
        mousedown(ev) {
            if (ev.detail > 1) {
                ev.preventDefault();
                ev.stopPropagation();
            }
        },
        onSelect(key) {
            this.showDropdown = false;
            if (this.manager?.registry) {
                this.selectBlock(this.manager.registry.blocks.get(key));
            }
            else {
                this.selectBlock(null);
            }
        },
        selectBlock(block) {
            if (this._observer) {
                this._observer.disconnect();
            }
            if (!block) {
                this.select = { key: null, pos: null, type: null };
                this.editorState.selectKey = 0;
                return;
            }
            const el = block.element;
            const key = block.key;
            if (!el) {
                console.log("No element for", block);
                return;
            }
            this.editorState.selectKey = key;
            this.select = {
                key,
                pos: null,
                type: block.type.type,
            };
            this.selectPosition(el);
            let observer = new ResizeObserver((entries) => {
                if (this.select.key === key) {
                    this.selectPosition(el);
                }
            });
            observer.observe(el);
            observer.observe(this.$refs.wrapper);
            this._observer = observer;
            let rect = el.getBoundingClientRect();
            let scrollTop;
            let scrollLeft;
            if (rect.top < 40 && rect.bottom < 150) {
                scrollTop = window.scrollY - 70 + rect.top;
            }
            else if (rect.top > window.innerHeight) {
                scrollTop = window.scrollY + rect.top - window.innerHeight + el.clientHeight;
            }
            if (scrollTop !== undefined) {
                window.scrollTo(0, scrollTop);
            }
        },
        selectPosition(el) {
            if (!this.$refs.edit || !el) {
                this.select.pos = null;
            }
            else {
                let pos = calculatePosition(this.$refs.edit, el, el.offsetWidth, el.offsetHeight);
                this.select.pos = pos;
                this.selectBarWidth = pos.width + 2;
                this.selectFlip = el.offsetTop < 20;
            }
        },
        selectParent() {
            if (this.selectedParent) {
                this.selectBlock(this.manager.registry.blocks.get(this.selectedParent));
            }
        },
        dragStart(ev) {
            ev.dataTransfer.setData("text/key", this.select.key.toString());
            this.editorState.dragging = true;
        },
        dragEnd() {
            this.editorState.dragging = false;
        },
        deleteBlock() {
            let manager = this.manager;
            let block = manager.registry.blocks.get(this.select.key);
            let parent = block.parent;
            if (!parent) {
                // Don't delete root blocks
                return;
            }
            block.remove();
            this.select.key = null;
            this.select.pos = null;
            if (block.repeated) {
                parent.updateRepeatSpec();
            }
            block.emitChange("remove");
        },
        duplicateBlock() {
            let manager = this.manager;
            let block = manager.registry.blocks.get(this.select.key);
            let parent = block.parent;
            let index = parent.children.indexOf(block);
            let spec = clone(block.toSpec());
            let newBlock = addBlock(spec.block, manager.registry, parent, { spec: [] }, index + 1);
            if (spec.block.children) {
                addBlocksFromSpec(newBlock, spec.block.children);
            }
            if (block.repeated) {
                newBlock.updateRepeatSpec();
            }
            else {
                newBlock.renderTree();
            }
            newBlock.emitChange("add");
            // Short delay to allow for layout changes
            setTimeout(() => {
                this.selectBlock(newBlock);
            }, 50);
        },
        styleUpdated: debounce(function () {
            if (this.select.key) {
                let manager = this.manager;
                let block = manager.registry.blocks.get(this.select.key);
                let el = block.element;
                this.selectPosition(el);
            }
        }, 50),
        openImages(requestId) {
            this.editorState.imageRequestId = requestId;
            this.editorState.showImages = true;
        },
        imageSelected(file) {
            this.editorState.showImages = false;
            this.editorState.events.controls.emit("image", {
                requestId: this.editorState.imageRequestId,
                file,
            });
        },
        copyEvent(event) {
            if (!this.validEventTarget(event)) {
                return;
            }
            this.copyBlock();
        },
        copyBlock() {
            let manager = this.manager;
            let block = manager.registry.blocks.get(this.select.key);
            if (!block) {
                return;
            }
            this.editorState.clipboard = JSON.stringify(block.toJSON().block);
            navigator.clipboard.writeText(this.editorState.clipboard);
        },
        cutEvent(event) {
            if (!this.validEventTarget(event)) {
                return;
            }
            this.cutBlock();
        },
        cutBlock() {
            let manager = this.manager;
            let block = manager.registry.blocks.get(this.select.key);
            if (!block) {
                return;
            }
            let parent = block.parent;
            this.editorState.clipboard = JSON.stringify(block.toJSON().block);
            navigator.clipboard.writeText(this.editorState.clipboard);
            block.remove();
            this.select.key = null;
            this.select.pos = null;
            if (block.repeated) {
                parent.updateRepeatSpec();
            }
            block.emitChange("remove");
        },
        pasteEvent(event) {
            if (!this.validEventTarget(event)) {
                return;
            }
            let spec;
            if (event) {
                try {
                    const clip = event.clipboardData.getData("text/plain");
                    spec = JSON.parse(clip);
                }
                catch (err) { }
            }
            console.log("paste", spec, this.editorState.clipboard);
            if (spec && typeof spec?.type === "string") {
                this.pasteBlock(spec);
            }
            else if (this.editorState.clipboard) {
                try {
                    spec = JSON.parse(this.editorState.clipboard);
                }
                catch (err) { }
                if (typeof spec?.type === "string") {
                    this.pasteBlock(spec);
                }
            }
        },
        pasteBlock(block, inside = false) {
            let manager = this.manager;
            let selected = manager.registry.blocks.get(this.select.key);
            if (!selected) {
                return;
            }
            let parent = selected.parent;
            let index = 0;
            if (inside || !parent) {
                parent = selected;
                index = selected.children.length - 1;
            }
            else {
                index = parent.children.indexOf(selected);
            }
            let newBlock = addBlock(block, manager.registry, parent, { spec: [] }, index + 1);
            if (block.children) {
                addBlocksFromSpec(newBlock, block.children);
            }
            if (selected.repeated) {
                newBlock.updateRepeatSpec();
            }
            else {
                newBlock.renderTree();
            }
            newBlock.emitChange("add");
            // Short delay to allow for layout changes
            setTimeout(() => {
                this.selectBlock(newBlock);
            }, 50);
        },
        dropdownClick(name) {
            switch (name) {
                case "duplicate":
                    this.duplicateBlock();
                    break;
                case "delete":
                    this.deleteBlock();
                    break;
                case "copy":
                    this.copyBlock();
                    break;
                case "cut":
                    this.cutBlock();
                    break;
                case "paste_after":
                    this.pasteEvent(null);
                    break;
            }
        },
        keyEvent(event) {
            if (!this.validEventTarget(event)) {
                return;
            }
            switch (event.key) {
                case "Del":
                case "Delete":
                    this.deleteBlock();
                    break;
            }
        },
        validEventTarget(event) {
            if (event?.target instanceof HTMLElement) {
                if (["INPUT", "TEXTAREA", "SELECT"].includes(event.target.tagName)) {
                    return false;
                }
                if (event.target.closest(".palette-settings-wrap")) {
                    return false;
                }
                if (event.target.closest(".palette-editor-header")) {
                    return false;
                }
                if (event.target.closest(".palette-editor-modal")) {
                    return false;
                }
            }
            return true;
        }
    },
    beforeDestroy() {
        if (this._observer) {
            this._observer.disconnect();
        }
        this.closeEditor();
    },
};
