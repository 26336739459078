<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="" />
            <span>QR Palette</span>
        </portal>
        <button class="btn btn-primary" id="back-to-top">
            <i class="material-icons">keyboard_arrow_up</i>
        </button>
        <div class="palette-contract">
            <div class="form-group select-contract">
                <p>Start by entering the contract address.</p>
                <label>Contract Address</label>
                <div class="row no-gutters">
                    <div class="col-md-7">
                        <input type="text" class="form-control contract-input" value="" />
                    </div>
                </div>
                <div class="invalid-feedback"></div>
                <small class="form-text text-muted"> Enter the address of the contract.</small>
            </div>
            <div class="select-network" style="display: none">
                <div class="data row no-gutters">
                    <span class="label col-sm-3">Contract Address</span>
                    <span class="data-value col-sm-9 network-address"></span>
                </div>
                <p>
                    A contract for this address was found on multiple networks, please select the
                    correct network.
                </p>
                <div class="network-selection"></div>
            </div>
        </div>
        <div class="change-contract" style="display: none">
            <a class="change-contract-link" href="#">Change Contract</a>
        </div>
        <div class="loading-palette" style="display: none">
            <div
                role="progressbar"
                class="progress mdc-linear-progress mdc-linear-progress--indeterminate status-progress"
            >
                <div class="mdc-linear-progress__buffering-dots"></div>
                <div class="mdc-linear-progress__buffer"></div>
                <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                </div>
                <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                </div>
            </div>
        </div>
        <div class="error palette-error"></div>
        <div class="palette">
            <h2 class="palette-title"></h2>
            <p class="palette-description"></p>
            <div class="palette-explorer-link" style="display: none">
                <div
                    style="
                        display: flex;
                        flex-wrap: wrap;
                        column-gap: 5px;
                        justify-content: space-between;
                    "
                >
                    <a class="btn btn-primary strippers-link" target="_blank"
                        >View Your Strippers/Clubs</a
                    >
                </div>
                <div style="margin-top: 40px">
                    <h4>Strippers You've Staked</h4>
                    <div class="staked-strippers"></div>
                </div>
            </div>

            <div class="palette-address-box" style="display: none">
                <h4>Set Wallet</h4>
                <p>Set your wallet address to load data for.</p>
                <form id="palette-address">
                    <div class="address-input formulate-input" data-classification="text">
                        <div class="address-input-wrapper">
                            <input id="palette-address-input" type="text" placeholder="0x123..." />
                            <button class="btn btn-primary" type="submit">Load Wallet</button>
                        </div>
                    </div>
                    <div class="error palette-address-error"></div>
                </form>
            </div>

            <div v-if="!loggedIn" class="palette-login">
                <a class="sign-in" href="/app">Sign In</a> to use your Blockwell Wallet.
            </div>

            <h3 id="palette-navigation">Palette Navigation</h3>
            <div class="palette-quicklinks"></div>

            <div class="palette-sections"></div>
        </div>
    </layout-heading>
</template>

<script>
import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import * as util from "@/assets/lib/util";
import { addressRegex } from "@blockwell/chain-client";
import { addressEqual, getChain } from "@blockwell/chains";
import * as R from "rambdax";
import Api from "@/lib/api/WalletApi";
import * as moment from "moment";
import qrBlock from "@/assets/templates/qr-block.hbs";
import callBlock from "@/assets/templates/call-block.hbs";
import linkBlock from "@/assets/templates/link-block.hbs";
import callBoolean from "@/assets/templates/call/boolean.hbs";
import callString from "@/assets/templates/call/string.hbs";
import callUint from "@/assets/templates/call/uint.hbs";
import callTimestamp from "@/assets/templates/call/timestamp.hbs";
import callStruct from "@/assets/templates/call/struct.hbs";
import callToken from "@/assets/templates/call/token.hbs";
import callList from "@/assets/templates/call/list.hbs";
import paletteSection from "@/assets/templates/palette-section.hbs";
import paletteQuicklink from "@/assets/templates/palette-quicklink.hbs";
import BigNumber from "bignumber.js";
import { capitalCase, paramCase } from "change-case";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";

const api = new Api("legacy");
export default {
    data() {
        return {
            gameNft: null,
            palette: null,
            net: null,
            contract: null,
        };
    },
    computed: {
        ...mapState("user", ["account"]),
        ...mapGetters("user", ["loggedIn"]),
        nftLinksDeps() {
            return {
                palette: this.palette,
                gameNft: this.gameNft
            }
        }
    },
    watch: {
        nftLinksDeps: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val.palette === "game" && val.gameNft) {
                    $(".strippers-link").attr(
                        "href",
                        `https://app.blockwell.ai/nft-swap?net=${this.net}&contract=${val.gameNft}`
                    );
                    $(".palette-explorer-link").show();
                } else {
                    $(".palette-explorer-link").hide();
                }
            },
        },
    },
    mounted() {
        let urlParams = new URLSearchParams(window.location.search);
        let address = urlParams.get("contract");

        if (address) {
            $(".palette-contract").hide();
            $(".change-contract").show();
            this.loadContract(address).catch((err) => {
                console.error(err);
                $(".palette-error").append(
                    `<p>Could not load a palette for the given contract.</p>`
                );
            });
        } else {
            this.init().catch((err) => {
                console.error(err);
                $(".palette-error").append(
                    `<p>Could not load a palette for the given contract.</p>`
                );
            });
        }

        $(".change-contract").click(function (ev) {
            ev.preventDefault();

            $(".contract-input").attr("disabled", false).val("");
            $(".palette").hide();
            $(".palette-sections").empty();
            $(".palette-contract").show();
            $(".change-contract").hide();
            this.init().catch((err) => {
                console.error(err);
                $(".palette-error").append(
                    `<p>Could not load a palette for the given contract.</p>`
                );
            });
        });

        $(".sign-in").click(function (ev) {
            ev.preventDefault();

            window.location =
                "/app/login?url=" +
                encodeURIComponent(window.location.pathname + window.location.search);
        });

        const backToTop = $("#back-to-top");

        $(window).scroll(function () {
            let top = $("#palette-navigation").offset().top;
            if ($(window).scrollTop() > top) {
                backToTop.addClass("show");
            } else {
                backToTop.removeClass("show");
            }
        });

        backToTop.on("click", function () {
            let top = $("#palette-navigation").offset().top;
            $("html, body").animate({ scrollTop: top }, "300");
            return false;
        });
    },
    methods: {
        async init() {
            const contractList = R.uniqWith(
                (a, b) => a.network === b.network,
                await util.getContractFromField($(".contract-input"))
            );

            $(".contract-input").attr("disabled", true);
            $(".change-contract").show();

            let contract;
            if (contractList.length > 1) {
                $(".network-address").text(contractList[0].address);
                let network = await this.selectNetwork(contractList.map((it) => it.network));
                contract = contractList.find((it) => it.network === network);
            } else {
                contract = contractList[0];
            }

            await this.loadContract(contract.address, contract);
        },

        async loadContract(input, contr = null) {
            let contractId;

            if (contr) {
                contractId = contr.id;
            } else if (input.length === 36) {
                contractId = input;
            } else if (/^0x[a-fA-F0-9]{40}$/.test(input)) {
                let list = await util.loadContract(input);

                if (list.length > 1) {
                    $(".contract-input").attr("disabled", false).val(input);
                    $(".palette-contract").show();
                    this.init().catch((err) => {
                        console.error(err);
                        $(".palette-error").append(
                            `<p>Could not load a palette for the given contract.</p>`
                        );
                    });
                    return;
                } else {
                    contractId = list[0].id;
                }
            }
            const token = await api.getTokenData(contractId);
            const address = token.address;

            if (!contractId) {
                throw new Error("Could not find the contract");
            }

            if (history.pushState) {
                let newurl =
                    window.location.protocol +
                    "//" +
                    window.location.host +
                    window.location.pathname +
                    "?contract=" +
                    address +
                    window.location.hash;
                window.history.pushState(
                    { path: newurl },
                    `${token.name} Custodial Contract Functions | Blockwell-QR`,
                    newurl
                );
            }

            document.title = `${token.name} Custodial Contract Functions | Blockwell-QR`;

            $(".palette-error").empty();
            $(".loading-palette").show();

            const palette = await api.loadPalette(this.$route.params.type, contractId);

            if (!palette) {
                throw new Error("Empty palette");
            }

            this.net = palette.contractNetwork;
            this.contract = palette.contractAddress;
            this.palette = palette.name;

            let title = palette.title.replace(":name", palette.contractName);
            let description = palette.description
                .replace(":name", palette.contractName)
                .replace(":address", palette.contractAddress)
                .replace(":network", palette.contractNetwork);

            $(".palette-title").text(title);
            $(".palette-description").text(description);

            async function loadStakedStrippersAndClubs(account) {
                let contract = await api.getContractId(
                    palette.contractAddress,
                    palette.contractNetwork
                );
                let events = await api.contractEvents(contract.id, "StripperStaked");

                let stakes = events
                    .map((it) => {
                        return {
                            staker: it.returnValues.staker,
                            stripperId: it.returnValues.stripperId,
                            transactionHash: it.transactionHash,
                        };
                    })
                    .filter((it) => addressEqual(account, it.staker));

                if (stakes.length > 0) {
                    let web3 = Dapp.getNetwork(palette.contractNetwork).web3;
                    let batch = new web3.BatchRequest();
                    for (let stake of stakes) {
                        batch.add(web3.eth.getTransaction.request(stake.transactionHash));
                    }

                    let res;
                    try {
                        let result = await batch.execute();
                        res = result?.response;
                    } catch (err) {
                        console.log(err);
                        res = err.response.map((it) => (it.jsonrpc ? it.result : it));
                    }

                    let i = 0;
                    for (let stake of stakes) {
                        let data = "0x" + res[i].input.slice(74, 138);
                        stake.clubId = new BigNumber(data).toString(10);
                        i++;
                    }

                    for (let stake of stakes) {
                        $(".staked-strippers").append(`
<div class="stripper-stake">
    Stripper <strong>${stake.stripperId}</strong> to Club <strong>${stake.clubId}</strong>
</div>
`);
                    }
                } else {
                    $(".staked-strippers").append("<p>No strippers staked yet.</p>");
                }
            }

            function addLinks(account) {
                if (palette?.name === "game") {
                    loadStakedStrippersAndClubs(account);
                }
            }

            this.palette = palette.name;

            if (palette.name === "game") {
                if (this.account) {
                    addLinks(this.account);
                } else {
                    $(".palette-address-box").show();
                    $("#palette-address").on("submit", (ev) => {
                        ev.preventDefault();
                        let val = $("#palette-address-input").val();
                        if (addressRegex.test(val)) {
                            $(".palette-address-box").hide();
                            addLinks(val);
                        } else {
                            $(".palette-address-error").innerText = "Invalid wallet address.";
                        }
                    });
                }
            }

            for (let section of palette.sections) {
                let sectionId = "section-" + paramCase(section.title);
                let $section = $(
                    paletteSection({
                        title: section.title,
                        description: section.description,
                        id: sectionId,
                    })
                );
                let $wrap = $section.find(".palette-blocks-wrap");
                let $blocks = $('<div class="palette-blocks"></div>').appendTo($wrap);

                let batcher = new Batcher(Dapp.getNetwork(palette.contractNetwork).web3);
                batcher.setContract(token.abi, token.address);

                for (let block of section.blocks) {
                    if (block.code) {
                        let url =
                            window.location.protocol +
                            "//" +
                            window.location.host +
                            `/${block.code.shortcode}`;
                        let image = process.env.VUE_APP_API_URL + `${block.code.shortcode}/image`;
                        $blocks.append(
                            qrBlock({
                                title: block.code.title,
                                url,
                                image,
                            })
                        );
                    } else if (block.call) {
                        $blocks.append(await this.renderCallBlock(palette, block, token, batcher));
                    } else if (block.link) {
                        $blocks.append(
                            $(
                                linkBlock({
                                    link: block.link
                                        .replace(":address", token.address)
                                        .replace(":network", token.network),
                                    text: block.text,
                                    description: block.description,
                                })
                            )
                        );
                    } else if (block.break) {
                        $blocks = $('<div class="palette-blocks"></div>').appendTo($wrap);
                    }
                }

                batcher.execute();

                $(".palette-sections").append($section);

                $(".palette-quicklinks").append(
                    $(
                        paletteQuicklink({
                            link: "#" + sectionId,
                            title: section.title,
                        })
                    )
                );
            }

            $(".qr-help a").popover({
                html: true,
                trigger: "focus",
                placement: "auto",
                content: function () {
                    let content = $(this).siblings("p").html();
                    return content;
                },
            });

            $(".palette").show();
            $("#palette-navigation").css({ visibility: "visible" });
            $(".loading-palette").hide();

            if (window.location.hash) {
                let off = $(window.location.hash).offset();
                $("html, body").animate({ scrollTop: $(window.location.hash).offset().top }, 500);
            }

            $('a[href^="#"]').click(function () {
                const href = $.attr(this, "href");

                $("html, body").animate(
                    {
                        scrollTop: $(href).offset().top,
                    },
                    500,
                    function () {
                        window.location.hash = href;
                    }
                );

                return false;
            });
        },

        async renderCallBlock(palette, block, token, batcher) {
            let $block = $(
                callBlock({
                    title: block.title,
                    description: block.description,
                    call: block.call,
                })
            );
            let args = [];

            if (block.args) {
                for (let arg of block.args) {
                    let val;
                    if (arg.value) {
                        val = arg.value;
                    } else if (arg.variable) {
                        switch (arg.variable) {
                            case "contract.address":
                                val = palette.contractAddress;
                                break;
                        }
                    }

                    args.push(val);
                }
            }
            let abi = token.abi;
            let contractAddress = token.address;
            if (block.address) {
                if (util.addressRegex.test(block.address)) {
                    contractAddress = block.address;
                } else {
                    contractAddress = await api.contractCall(palette.contractId, block.address);
                }
                if (!util.addressRegex.test(contractAddress)) {
                    throw new Error("Invalid contract address in block");
                }
                let contract = await api.getContractId(contractAddress, palette.contractNetwork);
                abi = contract.abi;
            }
            if (block.contractId) {
                let contract = await api.getContract(block.contractId);
                abi = contract.abi;
                contractAddress = contract.address;
            }

            if (batcher.contract.address !== contractAddress) {
                batcher.setContract(abi, contractAddress);
            }

            batcher.try(block.call, null, args, async (result) => {
                if (block.template === "struct") {
                    let abi = token.abi.find((it) => it.name === block.call);

                    if (abi.outputs.length === 1 && abi.outputs[0].components) {
                        let mapped = {};
                        let comps = abi.outputs[0].components;
                        for (let i = 0; i < comps.length; i++) {
                            let val = result[i];
                            let name = capitalCase(comps[i].name);
                            if (abi.name === "getConfig" && comps[i].name.endsWith("Fp")) {
                                val = new BigNumber(val).div(100).toString(10);
                            } else if (
                                abi.name === "getConfig" &&
                                comps[i].name === "apCapPerStripperTenths"
                            ) {
                                val = new BigNumber(val).div(10).toString(10);
                            } else if (
                                abi.name === "getConfig" &&
                                ["stakeCost", "popularityStakeCost"].includes(comps[i].name)
                            ) {
                                val = new BigNumber(val).div(1e18).toString(10);
                            }
                            name = name.replace("influence", "charm").replace("Influence", "Charm");
                            mapped[name] = val;
                        }
                        if (abi.name === "getConfig" && mapped.Nft) {
                            this.gameNft = mapped.Nft;
                        }
                        result = mapped;
                    }
                }

                let value;
                switch (block.template) {
                    case "struct":
                        let map = [];

                        for (let [key, val] of Object.entries(result)) {
                            if (!/^[0-9]+$/.test(key)) {
                                map.push({
                                    name: capitalCase(key),
                                    value: val.toString(),
                                });
                            }
                        }
                        value = callStruct({ map });

                        break;
                    case "list":
                        value = callList({ values: result });
                        break;
                    case "boolean":
                        let bool = true;
                        if (!result || result === "false") {
                            bool = false;
                        }
                        value = callBoolean({
                            value: bool ? "Yes" : "No",
                        });
                        break;
                    case "uint":
                        let val = result;
                        if (block.decimals) {
                            if (block.decimals === "auto") {
                                val = new BigNumber(result).div(`1e${token.decimals}`).toFormat();
                            } else {
                                val = new BigNumber(result).div(`1e${block.decimals}`).toFormat();
                            }
                        }

                        let symbol;
                        if (block.symbol) {
                            if (block.symbol === "auto") {
                                symbol = token.symbol;
                            } else {
                                symbol = block.symbol;
                            }
                        }

                        value = callUint({
                            value: val,
                            symbol,
                        });
                        break;
                    case "timestamp":
                        let time = parseInt(result) * 1000;
                        if (time > 0) {
                            let date = new Date(time);
                            value = callTimestamp({
                                timestamp: date.toLocaleString(),
                            });
                        } else {
                            value = callTimestamp();
                        }
                        break;
                    case "interval":
                        let seconds = parseInt(result);
                        let interval = moment().add(seconds, "seconds");
                        value = interval.fromNow(true);

                        break;
                    case "token":
                        let data = await api.getContractId(result, palette.contractNetwork);
                        const tokenData = await api.getTokenData(data.id);

                        value = callToken({
                            name: tokenData.name,
                            symbol: tokenData.symbol,
                            value: result,
                            link: getChain(palette.contractNetwork).explorerToken(result),
                        });

                        break;
                    default:
                        value = callString({
                            value: result,
                        });
                }

                $block.find(".call-block-value").html(value);
            });

            return $block;
        },

        selectNetwork(networks) {
            return new Promise((resolve, reject) => {
                let $sel = $(".network-selection");

                networks.map((it) => {
                    $sel.append(`
                <button class="btn btn-sm btn-primary" type="button" data-network="${it}">${it}</button>
            `);
                });

                util.fadeIn($(".select-network"));

                $sel.find("button").click(async function () {
                    await util.fadeOut($(".select-network"));
                    resolve($(this).data("network"));
                });
            });
        },
    },
};
</script>

<style lang="scss">
.staked-strippers {
    display: flex;
    column-gap: 5px;
    row-gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .stripper-stake {
        padding: 5px;
        border: 1px solid rgba(#555, 0.5);
    }
}

.call-list-textarea {
}
</style>
