import StyleComponentMixin from "@/components/palette/settings/style/StyleComponentMixin";
import { BoxShadowGroup } from "@/components/palette/settings/style/style-groups";
export default {
    name: "ShadowStackSettings",
    mixins: [StyleComponentMixin],
    props: {
        layer: {
            type: Object,
        },
        index: Number,
    },
    data() {
        return {
            group: BoxShadowGroup.items,
        };
    },
    methods: {
        update(entry) {
            this.$emit("update", entry);
        },
        active(active) {
            this.$emit("update:prevent-close", active);
        },
    }
};
