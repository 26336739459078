import ContractSettingMixin from "@/components/palette/settings/types/DynamicContextMixin";
import Info from "@/components/Info.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import StackListSetting from "@/components/palette/settings/types/components/StackListSetting.vue";
import ConversionConfig from "@/components/palette/settings/types/components/ConversionConfig.vue";
import ConditionConfig from "@/components/palette/settings/types/components/ConditionConfig.vue";
import { clone } from "remeda";
import InteractionConfig from "@/components/palette/settings/types/components/InteractionConfig.vue";
export default {
    name: "InteractionsSetting",
    components: { InteractionConfig, ConditionConfig, ConversionConfig, StackListSetting, VariableSetting, Info },
    mixins: [ContractSettingMixin, SettingMixin],
    props: {
        setting: {
            type: Object
        },
        value: {
            type: [Array]
        },
        parentIndex: {
            type: [Number, Array]
        }
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    methods: {
        layerLeft(value) {
            return value.type;
        },
        layerOp(value) {
            switch (value.actions?.[0]?.type) {
                case "page":
                    return "open page";
                default:
                    return "";
            }
        },
        layerRight(value) {
            switch (value.actions?.[0]?.type) {
                case "page":
                    return value.actions[0].param.page;
                default:
                    return "";
            }
        },
        input(update, index) {
            let value = this.value ? clone(this.value) : [];
            value[index] = update;
            this.emitSetting(value);
        },
        addLayer() {
            let value = this.value ? clone(this.value) : [];
            value.push({ type: "click", actions: [{ type: "", param: {} }] });
            this.emitSetting(value);
        },
        deleteLayer(index) {
            let value = this.value ? clone(this.value) : [];
            value.splice(index, 1);
            this.emitSetting(value);
        }
    }
};
