import BackgroundStackPreview from "@/components/palette/settings/style/types/stack/BackgroundStackPreview.vue";
import BackgroundStackSettings from "@/components/palette/settings/style/types/stack/BackgroundStackSettings.vue";
import ShadowStackPreview from "@/components/palette/settings/style/types/stack/ShadowStackPreview.vue";
import ShadowStackSettings from "@/components/palette/settings/style/types/stack/ShadowStackSettings.vue";
export const stackTypes = {
    background: {
        preview: BackgroundStackPreview,
        settings: BackgroundStackSettings,
    },
    "box-shadow": {
        preview: ShadowStackPreview,
        settings: ShadowStackSettings,
    },
};
export const stackComponents = {
    BackgroundStackPreview,
    BackgroundStackSettings,
    ShadowStackPreview,
    ShadowStackSettings,
};
