import ColorThemeColor from "@/components/palette/settings/color/ColorThemeColor.vue";
export default {
    name: "ColorTheme",
    components: { ColorThemeColor },
    inject: ["manager"],
    data() {
        const manager = this.manager;
        return {
            theme: Object.values(manager.color.colors),
        };
    },
    methods: {
        update(index, entry) {
            this.theme.splice(index, 1, entry);
            let manager = this.manager;
            manager.color.updateColor(entry);
        },
    },
};
