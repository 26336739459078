var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"qr-block\">\n    <h4>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":17}}}) : helper)))
    + "</h4>\n    <div class=\"qrcode-image-wrap\">\n        <img src=\""
    + alias4(((helper = (helper = helpers.image || (depth0 != null ? depth0.image : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"image","hash":{},"data":data,"loc":{"start":{"line":4,"column":18},"end":{"line":4,"column":27}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":41}}}) : helper)))
    + "\">\n    </div>\n    <div class=\"qrcode-link\">\n        <span>Deep Link</span>\n        <a href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":8,"column":17},"end":{"line":8,"column":24}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":8,"column":42},"end":{"line":8,"column":49}}}) : helper)))
    + "</a>\n    </div>\n    <div class=\"qr-help\">\n        <a href=\"javascript:void(0)\"><i class=\"material-icons tooltip-target\">help</i></a>\n        <p style=\"display: none;\">\n            QR Codes are used with the <a href=\"https://docs.blockwell.ai/qr\">Blockwell-QR</a> system. <a href=\"https://docs.blockwell.ai/qr/getting-started.html\">Click\n            here</a> to learn how to use them.\n        </p>\n    </div>\n</div>\n";
},"useData":true});