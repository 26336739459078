export default {
    inject: ["editorState"],
    data() {
        return {
            imageRequestId: 0,
            imageRequestBlock: null
        };
    },
    methods: {
        requestImage(block) {
            this.imageRequestId = Date.now();
            this.imageRequestBlock = block;
            this.editorState.imageRequestId = this.imageRequestId;
            this.editorState.showImages = true;
        },
        image(res) {
            if (this.imageRequestId === res.requestId) {
                if (!this.wrap?.block?.key || this.wrap.block.key === this.imageRequestBlock) {
                    this.imageUpdate(res.file.storage[0].location);
                }
            }
            this.imageRequestId = 0;
            this.imageRequestBlock = null;
        },
    },
    mounted() {
        this.editorState.events.controls.on("image", this.image);
    },
    beforeDestroy() {
        this.editorState.events.controls.off("image", this.image);
    },
};
