import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import ColorField from "@/components/color/ColorField.vue";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "ColorStyleSetting",
    components: { StyleLabel, ColorField },
    mixins: [StyleSettingMixin],
    data() {
        return {
            showUnits: false,
        };
    },
    computed: {
        showInherit() {
            return this.item.prop.default === "inherit" && this.value !== "inherit";
        },
    },
    methods: {
        inherit() {
            this.input("inherit");
        },
        openUnits() {
            this.showUnits = !this.showUnits;
            if (this.showUnits) {
                window.addEventListener("click", this.globalClick, true);
            }
        },
        globalClick(event) {
            requestAnimationFrame(() => {
                this.showUnits = false;
            });
            window.removeEventListener("click", this.globalClick, true);
        },
    },
};
