<template>
        <button type="button" class="loading-icon btn btn-sm"
                :class="classes"
                v-on="$listeners"
                :disabled="loading">
            <ProgressCircle v-if="loading" tiny class="side" color="light" />
            <span v-else class="material-icons"><slot></slot></span>
        </button>
</template>

<script>
import ProgressCircle from "@/components/ProgressCircle";
export default {
    name: "LoadingIcon",
    components: {ProgressCircle},
    props: {
        loading: Boolean,
        color: String,
    },
    computed: {
        classes() {
        }
    }
}
</script>

<style scoped lang="scss">
.loading-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    line-height: 1.2;
    padding: 0;
    color: var(--primary);

    .material-icons {
        margin: 0;
    }
}
</style>
