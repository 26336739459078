import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import { CompositeStyleSet } from "@blockwell/palette";
import StyleComponentMixin from "@/components/palette/settings/style/StyleComponentMixin";
import { capitalCase } from "change-case";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "CompositeStyleSetting",
    components: { StyleLabel },
    mixins: [StyleSettingMixin, StyleComponentMixin],
    data() {
        return {};
    },
    computed: {
        props() {
            let prop = this.item.prop;
            return prop.properties;
        },
        group() {
            let props = this.props;
            return props.map(prop => {
                return {
                    prop,
                    label: capitalCase(prop.property).split(" ").pop()
                };
            });
        },
        layer() {
            return CompositeStyleSet.from(this.entry);
        },
    },
    methods: {
        update(index, update) {
            let entry = this.entry;
            entry.update([update]);
            this.updateFromEntry();
        },
    },
};
