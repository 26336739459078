import SelectField from "@/components/palette/settings/form/SelectField.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import SettingTypesMixin from "@/components/palette/settings/types/SettingTypesMixin";
import equals from "fast-deep-equal";
import CurrentValue from "@/components/palette/settings/CurrentValue.vue";
import { debounce } from "@blockwell/util";
export default {
    name: "DynamicVariable",
    components: { CurrentValue, NameFind, SelectField },
    mixins: [SettingTypesMixin],
    inject: ["manager"],
    props: {
        wrap: {
            type: Object,
        },
        value: {
            type: Object,
        },
        index: Number,
    },
    provide() {
        return {
            dynamicContext: this.dynamicContext,
        };
    },
    data() {
        let manager = this.manager;
        return {
            name: this.value.name,
            type: this.value.type,
            types: manager.registry.variableTypes
                .getAll()
                .filter((it) => !!it.description)
                .map((it) => {
                return {
                    type: it.type,
                    name: it.type,
                    label: it.type + ": " + it.label,
                    description: it.description,
                };
            }),
            ref: null,
            currentValue: null,
            errorValue: null,
            dynamicContext: {
                contract: null,
                abi: null,
                args: [],
            },
        };
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.name = val.name;
                if (this.type !== val.type) {
                    this.type = val.type;
                }
            },
        },
        index: {
            immediate: true,
            handler(val) {
                let block = this.wrap.block;
                let ref = block.variable.variableInstance(val);
                // Avoid making the instance reactive
                this.ref = Object.freeze({
                    instance: ref,
                });
                ref.onValue(this.variableValue);
                ref.value();
            },
        },
        name: {
            handler: debounce(function (val) {
                if (val !== this.value.name) {
                    this.value.name = val;
                    this.$emit("update", this.value);
                }
            }, 200),
        },
        type: {
            handler(val) {
                if (val !== this.value.type) {
                    this.value.type = val;
                    this.$emit("update", this.value);
                }
            },
        },
    },
    computed: {
        settings() {
            let manager = this.manager;
            if (this.type) {
                let settings = manager.registry.variableTypes.get(this.type).settings;
                let value = this.value;
                return Object.entries(settings).map(([key, val]) => {
                    return {
                        key,
                        setting: val,
                        value: value.param[key],
                    };
                });
            }
            else {
                return [];
            }
        },
        parentIndex() {
            return this.ref?.instance?.index;
        },
    },
    methods: {
        variableValue(val) {
            if (val.value) {
                if (val.type === "error") {
                    this.errorValue = val.value.message;
                    this.currentValue = null;
                }
                else {
                    this.errorValue = null;
                    switch (val.type) {
                        case "value":
                            this.currentValue = val.value;
                            break;
                        case "nft":
                            this.currentValue = val.value.collection + " #" + val.value.tokenId;
                            break;
                        case "wallet":
                            this.currentValue = val.value;
                            break;
                        case "contract":
                            this.currentValue = val.value.name;
                            break;
                        case "null":
                            this.currentValue = null;
                            break;
                    }
                }
            }
            else {
                this.currentValue = null;
                this.errorValue = null;
            }
        },
        source(q, populate) {
            populate(this.types);
        },
        input(update) {
            let changed = false;
            for (let [key, value] of Object.entries(update)) {
                if (!equals(this.value.param[key], value)) {
                    if (value === null) {
                        if (this.value.param[key]) {
                            changed = true;
                            this.$delete(this.value.param, key);
                        }
                    }
                    else {
                        changed = true;
                        this.$set(this.value.param, key, value);
                    }
                }
            }
            if (changed) {
                this.$emit("update", this.value);
            }
        },
    },
};
