import { render, staticRenderFns } from "./StyleLabel.vue?vue&type=template&id=ed80af26&functional=true&"
import script from "./StyleLabel.vue?vue&type=script&lang=ts&"
export * from "./StyleLabel.vue?vue&type=script&lang=ts&"
import style0 from "./StyleLabel.vue?vue&type=style&index=0&id=ed80af26&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports