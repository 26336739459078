import { PaletteColorEntry } from "@blockwell/palette";
import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
import ColorPicker from "@/components/color/ColorPicker.vue";
import { debounce } from "@blockwell/util";
export default {
    name: "ColorThemeColor",
    components: { ColorPicker, SettingsPopper },
    inject: ["manager"],
    props: {
        color: {
            type: Object,
        },
    },
    data() {
        return {
            show: false,
            recent: []
        };
    },
    computed: {
        style() {
            return {
                "background-color": this.color.base,
                color: this.color.text,
            };
        },
    },
    watch: {
        show(val, old) {
            if (val) {
                this.recent = this.manager?.getRecentColors(false);
            }
            if (old && !val && this.color?.base) {
                this.manager?.color?.addRecent(this.color.base);
            }
        }
    },
    methods: {
        input: debounce(function (value) {
            let color;
            if (value.rgba.a === 1) {
                color = value.hex;
            }
            else {
                color = `rgba(${value.rgba.r}, ${value.rgba.g}, ${value.rgba.b}, ${value.rgba.a})`;
            }
            let colorEntry = new PaletteColorEntry(this.color.name, color);
            this.$emit("update", colorEntry);
        }, 16, { maxWait: 32 }),
    },
};
