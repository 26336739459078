import { render, staticRenderFns } from "./BlockVariables.vue?vue&type=template&id=6378e1d6&scoped=true&"
import script from "./BlockVariables.vue?vue&type=script&lang=ts&"
export * from "./BlockVariables.vue?vue&type=script&lang=ts&"
import style0 from "./BlockVariables.vue?vue&type=style&index=0&id=6378e1d6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6378e1d6",
  null
  
)

export default component.exports