// @ts-ignore
import Modal from "@/components/Modal.vue";
export default {
    components: { Modal },
    inject: ["saveState"],
    props: {
        name: String,
        manager: {
            type: Object,
        },
    },
    data() {
        return {
            showDialog: true
        };
    },
    watch: {
        show(val) {
            this.showDialog = val;
        },
        showDialog(val) {
            if (this.show !== val) {
                this.$emit("update:show", val);
                if (!val) {
                    this.$emit("close");
                }
            }
        },
    }
};
