import { Typing } from "@blockwell/eth-types";
import Info from "@/components/Info.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import { sort } from "remeda";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import ContractSettingMixin from "@/components/palette/settings/types/DynamicContextMixin";
import { EventFragment, Interface } from "@ethersproject/abi";
export default {
    name: "AbiSetting",
    components: { NameFind, Info },
    mixins: [SettingMixin, ContractSettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: String,
        minimal: Boolean
    },
    data() {
        return {
            abis: [],
            name: null,
        };
    },
    watch: {
        "dynamicContext.contract": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.abis = sort(val.abi, (a, b) => {
                        let aUpper = a.name?.[0]?.toUpperCase() === a.name?.[0];
                        let bUpper = b.name?.[0]?.toUpperCase() === b.name?.[0];
                        if (aUpper === bUpper) {
                            return a.name?.localeCompare(b.name);
                        }
                        else if (aUpper) {
                            return 1;
                        }
                        return -1;
                    });
                    if (this.value && !this.name) {
                        if (this.setting.abiType === "event") {
                            if (this.value === "*") {
                                this.name = "*";
                            }
                            else {
                                let event = val.abi.find(it => it.type === "event" && Interface.getEventTopic(EventFragment.from(it)) === this.value);
                                if (event) {
                                    this.name = event.name;
                                }
                                else {
                                    this.name = this.value;
                                }
                            }
                        }
                        else {
                            let manager = this.manager;
                            let abi = manager.registry.abis.get(this.value);
                            this.name = abi.name;
                            if (this.dynamicContext) {
                                this.dynamicContext.abi = abi;
                                this.dynamicContext.args = abi.inputs.map((it) => {
                                    return {
                                        name: it.name,
                                        typing: Typing.parse(it),
                                    };
                                });
                            }
                        }
                    }
                }
                else {
                    this.abis = [];
                }
            },
        },
        name(val) {
            if (val === "*") {
                this.emitSetting("*");
                return;
            }
            let manager = this.manager;
            let abis = this.abis;
            let abi = abis.find((it) => it.name === val);
            if (abi) {
                if (abi.type === "event") {
                    let topic0 = Interface.getEventTopic(EventFragment.from(abi));
                    this.emitSetting(topic0);
                }
                else {
                    if (this.dynamicContext) {
                        this.dynamicContext.abi = abi;
                        this.dynamicContext.args = abi.inputs.map((it) => {
                            return {
                                name: it.name,
                                typing: Typing.parse(it),
                            };
                        });
                    }
                    let key = manager.registry.abis.add(abi);
                    this.emitSetting(key);
                }
            }
        },
    },
    methods: {
        source(q, populate) {
            let abis = this.abis;
            let setting = this.setting;
            let type = setting.abiType || "read";
            if (type === "read") {
                abis = abis.filter((it) => it.constant || it.stateMutability === "view");
            }
            else if (type === "event") {
                abis = abis.filter((it) => it.type === "event");
                abis.unshift({ name: "*", type: "any" });
            }
            if (q) {
                abis = abis.filter((it) => it.name?.toLowerCase()?.includes(q));
            }
            populate(abis);
        },
        inputValue(input) {
            return input?.name || "";
        },
        itemType(abi) {
            if (abi.type === "event") {
                return "event";
            }
            if (abi.type === "any") {
                return "any event";
            }
            return abi.stateMutability;
        },
        returnType(abi) {
            if (abi.type === "event") {
                return abi.inputs.map((it) => it.name).join(", ");
            }
            if (abi.type === "any") {
                return "";
            }
            let out = abi.outputs[0];
            return out?.internalType?.replace("struct ", "") || out?.type;
        },
    },
};
