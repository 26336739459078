import { every, onceOnHide } from "@/lib/interval";
import { debounce } from "@blockwell/util";
import bootbox from "bootbox";
import { Eventer } from "@blockwell/eventer";
import dayjs from "dayjs";
export default {
    data() {
        return {
            saveState: {
                isDraft: false,
                isNew: false,
                localDraft: 0,
                draftTimestamp: 0,
                updatedAt: 0,
                availability: null,
                requestingLock: false,
                lockStatus: null,
                version: -1,
                canEdit: false,
                publishing: false,
                subscription: null,
                subscriptionStatus: null,
                subscriptionActive: false,
                subscriptionExpires: null,
                events: Object.freeze(new Eventer()),
            },
            interval: null,
            lockInterval: null,
            subscriptionInterval: null,
            onhide: null,
        };
    },
    computed: {
        localDraftDeps() {
            const name = this.editorState.name;
            let localDraft = this.$store.state.drafts.drafts[`palette-${name}`];
            return localDraft?.timestamp;
        },
    },
    watch: {
        localDraftDeps: {
            immediate: true,
            handler(val) {
                let saveState = this.saveState;
                saveState.localDraft = val > 0 ? val : 0;
            },
        },
    },
    provide() {
        return {
            saveState: this.saveState,
        };
    },
    methods: {
        draftLoader() {
            if (this.interval) {
                this.interval.clear();
            }
            this.interval = every(() => {
                if (!this.saveState.requestingLock && !this.editorState.editing) {
                    this.refreshDraft();
                }
            }, 4000, true);
        },
        openEditor() {
            let api = this.api.palette;
            let saveState = this.saveState;
            let name = this.name;
            if (saveState.availability === "new") {
                this.applyDraftResponse(name, null);
                this.setupEditor();
            }
            else if (this.$store.getters["user/loggedIn"]) {
                saveState.requestingLock = true;
                api.lockDraft(name)
                    .then((res) => {
                    this.applyDraftResponse(name, res);
                    if (res.status === "locked") {
                        this.setupEditor();
                        if (this.lockInterval) {
                            this.lockInterval.clear();
                        }
                        this.lockInterval = every(() => {
                            this.refreshLock();
                        }, 5000, false);
                    }
                })
                    .finally(() => {
                    saveState.requestingLock = false;
                });
            }
            else {
                saveState.lockStatus = "unauthorized";
                this.$bus.emit("login_request", {
                    message: "Must be logged in to edit a Palette.",
                });
            }
        },
        closeEditor() {
            let saveState = this.saveState;
            this.lockInterval?.clear();
            this.lockInterval = null;
            if (this.$store.getters["user/loggedIn"] && saveState.lockStatus === "locked") {
                this.onHide();
            }
            this.clearEditor();
        },
        applyDraftResponse(name, res) {
            let saveState = this.saveState;
            saveState.lockStatus = res ? res.status : "available";
            saveState.updatedAt = res?.updatedAt || 0;
            if (res) {
                if (res.roles) {
                    saveState.canEdit = res.roles.includes("admin") || res.roles.includes("editor");
                    saveState.canShare = res.roles.includes("admin");
                }
            }
            else {
                // res is only null when it's a new Palette
                saveState.canEdit = true;
            }
            let localDraft = this.$store.state.drafts.drafts[`palette-${name}`];
            let local;
            if (localDraft?.timestamp) {
                try {
                    local = JSON.parse(localDraft.value);
                }
                catch (err) {
                    this.$store.dispatch("drafts/clearDraft", `palette-${name}`);
                }
            }
            if (local) {
                saveState.isDraft = true;
                if (saveState.draftTimestamp < localDraft.timestamp) {
                    saveState.draftTimestamp = localDraft.timestamp;
                    this.setupPalette(name, local);
                }
            }
            else if (!res) {
                // No local draft, no response, assume new Palette
                this.setupPalette("new");
            }
            else if (res.draft) {
                saveState.isDraft = true;
                if (saveState.draftTimestamp < res.draftTimestamp) {
                    saveState.draftTimestamp = res.draftTimestamp;
                    this.setupPalette(name, res.draft);
                }
            }
            else {
                saveState.isDraft = false;
                saveState.draftTimestamp = 0;
                if (saveState.version < res.version) {
                    saveState.version = res.version;
                    this.setupPalette(name, res.data);
                }
            }
            // Save the current version as well after setting up
            if (res) {
                saveState.version = res.version;
            }
        },
        change(change) {
            if (this.manager.registry.id === change.paletteId) {
                this.afterChanges(change.paletteId);
            }
        },
        afterChanges: debounce(function (id) {
            let manager = this.manager;
            if (id === manager.registry.id) {
                let json = manager.toJSON();
                let timestamp = Date.now();
                if (this.name === "new") {
                    this.saveState.draftTimestamp = timestamp;
                }
                this.$store.dispatch("drafts/saveDraft", {
                    key: `palette-` + this.name,
                    value: {
                        timestamp,
                        value: JSON.stringify(json),
                    },
                });
                this.onhide?.clear();
                this.onhide = onceOnHide(this.onHide);
            }
        }, 1000),
        handleSave() {
            if (!this.lockInterval || this.lockInterval.delay()) {
                let res = this.refreshLock();
                if (res) {
                    return res;
                }
            }
            return Promise.resolve(-1);
        },
        handleSaveAs(name) {
            let oldName = this.name;
            let api = this.api.palette;
            let saveState = this.saveState;
            this.lockInterval?.clear();
            this.$store.dispatch("drafts/clearDraft", `palette-${oldName}`);
            let prom = api.create(name, this.manager.toJSON());
            prom.then(() => {
                saveState.availability = "owned";
                this.$router.push(`${this.basePath}/${name}`);
                if (oldName !== "new" && saveState.canEdit) {
                    return api.unlockDraft(oldName);
                }
            });
            return prom;
        },
        async handleDiscard() {
            let api = this.api.palette;
            let saveState = this.saveState;
            let name = this.name;
            bootbox.confirm({
                title: "Discard Changes?",
                message: `All unpublished changes will be lost.`,
                backdrop: true,
                callback: (result) => {
                    if (result) {
                        this.$store.dispatch("drafts/clearDraft", `palette-${name}`);
                        this.selectBlock(null);
                        if (name === "new") {
                            this.setupPalette("new");
                        }
                        else {
                            api.discardDraft(name).then((res) => {
                                saveState.version = -1;
                                saveState.draftTimestamp = 0;
                                this.loadError = null;
                                this.applyDraftResponse(name, res);
                            });
                        }
                    }
                },
            });
        },
        async handlePublish() {
            let api = this.api.palette;
            let saveState = this.saveState;
            let manager = this.manager;
            let name = this.name;
            saveState.publishing = true;
            this.lockInterval?.clear();
            try {
                if (saveState.localDraft) {
                    this.onhide?.clear();
                    await api.saveDraft(name, manager.toJSON());
                    this.$store.dispatch("drafts/clearDraft", `palette-${name}`);
                }
                let res = await api.publish(name);
                saveState.version = -1;
                saveState.draftTimestamp = 0;
                this.loadError = null;
                this.applyDraftResponse(name, res);
            }
            finally {
                saveState.publishing = false;
                this.lockInterval = every(() => {
                    this.refreshLock();
                }, 5000);
            }
        },
        handleImport(spec) {
            let saveState = this.saveState;
            let manager = this.manager;
            let name = this.name;
            if (manager) {
                this.selectBlock(null);
                manager.destroy();
                this.manager = null;
            }
            let timestamp = Date.now();
            this.$store.dispatch("drafts/saveDraft", {
                key: `palette-${name}`,
                value: {
                    timestamp,
                    value: JSON.stringify(spec),
                },
            });
            saveState.isDraft = true;
            saveState.draftTimestamp = timestamp;
            this.setupPaletteSpec(spec).then((manager) => {
                manager.registry.events.on("change", this.change);
                manager.style.events.on("update", this.styleUpdated);
            });
        },
        refreshDraft() {
            let name = this.name;
            if (name) {
                let api = this.api.palette;
                let saveState = this.saveState;
                let localDraft = this.$store.state.drafts.drafts[`palette-${name}`];
                if (saveState.availability !== "new") {
                    api.getDraft(name, saveState.updatedAt).then((res) => {
                        if (this.name !== name || !res) {
                            return;
                        }
                        if (res.status === "unauthorized") {
                            this.subscription = "none";
                        }
                        else {
                            this.subscription = null;
                        }
                        this.applyDraftResponse(name, res);
                    });
                }
                else if (localDraft?.timestamp) {
                    if (saveState.draftTimestamp >= localDraft.timestamp) {
                        return;
                    }
                    try {
                        let useSpec = JSON.parse(localDraft.value);
                        this.setupPalette(name, useSpec);
                        saveState.draftTimestamp = localDraft.timestamp;
                        return;
                    }
                    catch (err) {
                        this.$store.dispatch("drafts/clearDraft", `palette-${name}`);
                    }
                }
                else if (!this.manager) {
                    this.setupPalette("new");
                    saveState.canEdit = true;
                }
            }
        },
        refreshLock() {
            let name = this.name;
            if (name && this.saveState.availability !== "new" && this.editorState.editing) {
                let api = this.api.palette;
                let saveState = this.saveState;
                let manager = this.manager;
                if (saveState.localDraft) {
                    saveState.isDraft = true;
                    this.onhide?.clear();
                    return api.saveDraft(name, manager.toJSON()).then((res) => {
                        this.$store.dispatch("drafts/clearDraft", `palette-${name}`);
                        saveState.draftTimestamp = res;
                        saveState.updatedAt = res;
                        return res;
                    });
                }
                else {
                    return api.refreshLock(name).then(() => saveState.draftTimestamp);
                }
            }
        },
        onHide() {
            let name = this.name;
            let api = this.api.palette;
            let saveState = this.saveState;
            let manager = this.manager;
            if (name && saveState.availability !== "new" && saveState.lockStatus === "locked") {
                if (saveState.localDraft) {
                    api.unlockDraft(name, manager.toJSON()).then((res) => {
                        this.$store.dispatch("drafts/clearDraft", `palette-${name}`);
                        saveState.draftTimestamp = res;
                        saveState.updatedAt = res;
                        saveState.lockStatus = "available";
                    });
                }
                else {
                    api.unlockDraft(name).then(() => {
                        saveState.lockStatus = "available";
                    });
                }
            }
        },
        updateSubscription() {
            let name = this.name;
            let api = this.api.palette;
            let saveState = this.saveState;
            if (name && saveState.availability !== "new") {
                api.getSubscription(name).then((res) => {
                    if (this.name !== name) {
                        return;
                    }
                    saveState.subscription = res;
                    saveState.subscriptionStatus = res.status || "none";
                    saveState.subscriptionExpires = res.expires ? dayjs(res.expires) : null;
                    saveState.subscriptionActive = res.active;
                });
            }
        },
    },
    created() {
        const events = this.saveState.events;
        events.on("importSpec", this.handleImport);
        events.on("discard", this.handleDiscard);
        events.on("publish", this.handlePublish);
        events.on("save", this.handleSave);
        events.on("saveAs", this.handleSaveAs);
        this.subscriptionInterval = every(this.updateSubscription, 20000);
    },
    beforeDestroy() {
        this.interval?.clear();
        this.lockInterval?.clear();
        this.subscriptionInterval?.clear();
        this.onhide?.run();
        this.saveState.events.clearListeners();
    },
};
