import { ClassStyle } from "@blockwell/palette";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import BlockStyleSettings from "@/components/palette/settings/style/BlockStyleSettings.vue";
export default {
    name: "BlockStyle",
    components: { BlockStyleSettings, NameFind },
    inject: ["manager", "editorState"],
    props: {
        spec: {
            type: Object,
        },
        wrap: {
            type: Object,
        },
        settings: {
            type: Array,
        },
    },
    data() {
        return {
            selected: -1,
            key: 0,
            showSelectors: false,
            showAddClass: false,
            addClassName: "",
            set: null,
            layers: [],
            styleReq: {},
            parentReq: {},
        };
    },
    computed: {
        classes() {
            this.spec?.classes;
            if (this.wrap) {
                return [
                    {
                        type: "root",
                    },
                    ...this.wrap.block.style.getStyleLayers(),
                ];
            }
            return [];
        },
        selectedClass() {
            if (this.selected > -1 && this.selected < this.classes.length) {
                return this.classes[this.selected];
            }
            else {
                return this.classes[this.classes.length - 1];
            }
        },
        setDeps() {
            return {
                classes: this.classes,
                selected: this.selected,
            };
        },
    },
    watch: {
        wrap: {
            immediate: true,
            handler(val) {
                if (val) {
                    let parent = val.block.parent;
                    while (parent && parent.type.type === "repeat-child") {
                        parent = parent.parent;
                    }
                    let classes = parent?.style?.getStyleLayers();
                    if (classes) {
                        let manager = this.manager;
                        let layers = manager.style.getLayers(classes);
                        let display;
                        for (let it of layers) {
                            if (it) {
                                let val = it.get("display")?.value;
                                if (val) {
                                    display = val;
                                }
                            }
                        }
                        this.parentReq = {
                            display,
                        };
                    }
                    else {
                        this.parentReq = {};
                    }
                }
            },
        },
        setDeps: {
            immediate: true,
            deep: true,
            handler({ classes, selected }) {
                let manager = this.manager;
                let style;
                if (selected > -1 && selected < classes.length) {
                    style = classes[selected];
                }
                else {
                    style = classes[classes.length - 1];
                }
                if (style) {
                    let set = manager.style.getSet(style);
                    let layers = manager.style.getLayers(classes);
                    this.set = set;
                    this.layers = layers;
                    let display;
                    let position;
                    for (let it of layers) {
                        if (it) {
                            let val = it.get("display")?.value;
                            if (val) {
                                display = val;
                            }
                            val = it.get("position")?.value;
                            if (val) {
                                position = val;
                            }
                            if (it === set) {
                                break;
                            }
                        }
                    }
                    this.styleReq = {
                        display,
                        position
                    };
                }
                else {
                    this.set = null;
                }
            },
        },
        spec: {
            immediate: true,
            deep: true,
            handler(spec) {
                if (!spec) {
                    this.selected = -1;
                    return;
                }
                if (this.key !== this.wrap.block.key) {
                    this.key = this.wrap.block.key;
                    this.selected = -1;
                }
            },
        },
    },
    methods: {
        classLabel(type) {
            if (type.type === "root") {
                return "Palette";
            }
            if (type.type === "block") {
                if (type.layout) {
                    return `${type.name} block (${type.layout})`;
                }
                else {
                    return type.name + " block";
                }
            }
            return type.name;
        },
        select(index) {
            this.selected = index;
            this.showSelectors = false;
        },
        selectLayer(set) {
            let index = this.layers.indexOf(set);
            if (index > -1) {
                this.select(index);
            }
        },
        openAdd() {
            this.showAddClass = !this.showAddClass;
            if (this.showAddClass) {
                this.$nextTick(() => {
                    let el = this.$refs.addClass.querySelector("input");
                    el.focus();
                });
                window.addEventListener("click", this.globalClick, true);
            }
        },
        openSelectors() {
            this.showSelectors = !this.showSelectors;
            if (this.showSelectors) {
                window.addEventListener("click", this.globalClick, true);
            }
        },
        add(name) {
            if (name) {
                let manager = this.manager;
                manager.style.addClass(name);
                let wrap = this.wrap;
                this.spec.classes.push(name);
                wrap.block.style.addClass(name);
                this.showAddClass = false;
                this.addClassName = "";
                this.selected = this.classes.length;
            }
        },
        remove() {
            let name = this.selectedClass.name;
            let wrap = this.wrap;
            wrap.block.style.removeClass(name);
            let index = this.spec.classes.indexOf(name);
            if (index > -1) {
                this.spec.classes.splice(index, 1);
            }
        },
        source(q, populate) {
            let manager = this.manager;
            let styles = manager.style.getStyles();
            let result = [];
            for (let style of styles) {
                if (style instanceof ClassStyle) {
                    if (!q || style.name.includes(q)) {
                        result.push(style.name);
                    }
                }
            }
            populate(result);
        },
        globalClick(event) {
            let ref = this.$refs.addClass || this.$refs.selectorChoices;
            if (ref) {
                if (!ref.contains(event.target)) {
                    requestAnimationFrame(() => {
                        this.showAddClass = false;
                        this.showSelectors = false;
                    });
                    window.removeEventListener("click", this.globalClick, true);
                }
            }
        },
        styleUpdated(style) {
            if (this.set === style.set) {
                if ("display" in style) {
                    this.styleReq.display = style.display;
                }
                if ("position" in style) {
                    this.styleReq.position = style.position;
                }
            }
        },
    },
    mounted() {
        this.manager.style.events.on("update", this.styleUpdated);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.globalClick, true);
        this.manager.style.events.off("update", this.styleUpdated);
    },
};
