<template>
    <div class="settings-popover">
        <a v-if="icon" href="javascript: void 0;" class="tooltip-target material-icons" :class="colorClass" @click.stop="open">{{
                icon
            }}</a>
        <div ref="popover" class="settings-popover-wrapper" :class="{borderless}" :data-show="showing">
                <div v-if="showing" class="settings-popover-inner">
                    <div class="settings-content" ref="content">
                        <slot />
                    </div>
                </div>
            <div ref="arrow" class="settings-popover-arrow-wrapper">
                    <div v-if="showing" class="settings-popover-arrow"></div>
            </div>
        </div>
    </div>
</template>

<script>
import PopperMixin from "@/components/PopperMixin";

export default {
    name: "SettingsPopper",
    mixins: [PopperMixin],
    props: {
        icon: {
            type: String,
            default: "help",
        },
        color: String,
        show: Boolean,
        borderless: Boolean
    },
    data() {
        return {
            useFlip: false,
            placementOverride: "left"
        }
    },
    computed: {
        colorClass() {
            if (this.color) {
                return "color-" + this.color;
            }
            return "";
        }
    }
};
</script>

<style scoped lang="scss">
@use "../settings.scss" as *;

.settings-popover {
    display: inline;

    .tooltip-target {
        text-decoration: none;
        color: $text;
    }
}

.settings-popover-wrapper {
    display: none;
    z-index: 10;
    max-width: calc(100vw - 20px);
    margin: 0;

    &[data-show] {
        display: block;
    }

    $placements: ("bottom": "top", "top": "bottom", "left": "right", "right": "left");

    @each $pos, $side in $placements {
        &[data-popper-placement^=#{$pos}] .settings-popover-arrow-wrapper {
            #{$side}: -4px;
        }
    }
}

.settings-popover-arrow-wrapper, .settings-popover-arrow, .settings-popover-arrow::before {
    position: absolute;
    width: 8px;
    height: 9px;
}

.settings-popover-arrow-wrapper {
    z-index: -1;
}

.settings-popover-inner {
    background: $settingBoxDarker;
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2)) drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.3));
    color: $text;

    max-width: 400px;
    padding: 10px;
}

.settings-popover-wrapper.borderless .settings-popover-inner {
    padding: 0;
}

.settings-popover-arrow::before {
    z-index: -1;
    content: '';
    transform: rotate(45deg);
    background: $settingBoxDarker;
}

.settings-content {
    font-size: 14px;
    font-weight: normal;
}
</style>
