import Info from "@/components/Info.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
export default {
    name: "StackListSetting",
    components: { SettingsPopper, VariableSetting, Info },
    props: {
        value: {
            type: [Array],
        },
        addLabel: {
            type: String,
            default: "+ Add Conversion"
        }
    },
    data() {
        return {
            preventClose: false,
            autoOpen: null,
        };
    },
    computed: {},
    watch: {},
    methods: {
        addLayer() {
            this.autoOpen = this.value?.length || 0;
            this.$emit("push");
        },
        deleteLayer(index) {
            this.$emit("delete", index);
        },
    },
};
