import FuelMixin from "@/components/fuel/FuelMixin";
import FuelIcon from "@/components/fuel/FuelIcon.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import FuelPopover from "@/components/fuel/FuelPopover.vue";
import FuelUseMixin from "@/components/fuel/FuelUseMixin";
import Info from "@/components/Info.vue";
import dayjs from "dayjs";
import SimpleFuelBalance from "@/components/fuel/SimpleFuelBalance.vue";
export default {
    name: "FuelSubscriptionView",
    components: { SimpleFuelBalance, Info, FuelPopover, LoadingButton, FuelIcon },
    mixins: [FuelMixin, FuelUseMixin],
    props: {
        reference: String,
        subId: String,
        popover: Boolean
    },
    data() {
        return {
            sub: null,
            subError: null,
        };
    },
    computed: {
        subDeps() {
            return {
                product: this.product,
                reference: this.reference,
            };
        },
        subStatus() {
            return this.sub?.status || "none";
        },
        expiration() {
            if (this.sub?.expires) {
                return dayjs(this.sub.expires).format("lll");
            }
            return null;
        }
    },
    watch: {
        subDeps: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val.product && val.reference) {
                    this.refresh(val.product, val.reference);
                }
            },
        },
        subId(val) {
            const api = this.$store.getters["user/api"].fuel;
            if (val) {
                api.getSubscription(val).then((res) => {
                    this.sub = res;
                });
            }
        },
        showPopover(val) {
            this.$emit("update:popover", val);
        }
    },
    methods: {
        refresh(product, reference) {
            const api = this.$store.getters["user/api"].fuel;
            api.findSubscription(product, reference).then((data) => {
                this.sub = data;
            });
        },
        click() {
            if (this.ticket) {
                this.$emit("submit", this.ticket.id);
            }
            else if (!this.sufficientBalance) {
                this.showPopover = true;
            }
            else {
                const api = this.$store.getters["user/api"].fuel;
                this.fuelLoading = true;
                api.buyTicket(this.productData.id)
                    .then((res) => {
                    this.$emit("submit", res.id);
                    this.subError = null;
                })
                    .catch((err) => {
                    this.subError = err.message;
                })
                    .finally(() => {
                    this.fuelLoading = false;
                    this.$emit("update");
                });
            }
        },
        cancel() {
            const api = this.$store.getters["user/api"].fuel;
            api.cancelSubscription(this.sub.id)
                .then(() => this.refresh(this.product, this.reference));
        },
        restart() {
            const api = this.$store.getters["user/api"].fuel;
            api.restartSubscription(this.sub.id)
                .then(() => this.refresh(this.product, this.reference));
        }
    },
};
