import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingTypesMixin from "@/components/palette/settings/types/SettingTypesMixin";
import { VariableReference } from "@blockwell/variables";
export default {
    name: "BlockSettingsGroup",
    components: { VariableSetting },
    mixins: [SettingTypesMixin],
    props: {
        spec: {
            type: Object,
        },
        group: {
            type: Object,
        },
        wrap: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    computed: {
        settings() {
            let spec = this.spec;
            let group = this.group;
            return Object.entries(group.settings).map(([key, val]) => {
                return {
                    key,
                    setting: val,
                    value: spec.param[key],
                };
            });
        },
        parentIndex() {
            if (this.wrap?.block) {
                let variables = this.wrap.block.type.variables();
                let indexes = [];
                for (let it of variables) {
                    // This is a variable defined by this block, and is assumed to depend on
                    // the block settings
                    if (it.nameKey && it.ref instanceof VariableReference) {
                        indexes.push(it.ref.index);
                    }
                }
                return indexes;
            }
            return [];
        },
        uniqueKey() {
            if (this.wrap?.block) {
                return 'block-' + this.wrap.block.key;
            }
            return 'group';
        }
    },
    methods: {
        input(update) {
            this.$emit("update", update);
        }
    }
};
