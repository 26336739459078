import { render, staticRenderFns } from "./PaletteFooter.vue?vue&type=template&id=875c4408&scoped=true&"
import script from "./PaletteFooter.vue?vue&type=script&lang=ts&"
export * from "./PaletteFooter.vue?vue&type=script&lang=ts&"
import style0 from "./PaletteFooter.vue?vue&type=style&index=0&id=875c4408&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "875c4408",
  null
  
)

export default component.exports