import { render, staticRenderFns } from "./EventsSetting.vue?vue&type=template&id=09aa7d90&scoped=true&"
import script from "./EventsSetting.vue?vue&type=script&lang=ts&"
export * from "./EventsSetting.vue?vue&type=script&lang=ts&"
import style0 from "./EventsSetting.vue?vue&type=style&index=0&id=09aa7d90&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09aa7d90",
  null
  
)

export default component.exports