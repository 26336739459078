import { render, staticRenderFns } from "./DefinitionSetting.vue?vue&type=template&id=666a21c6&scoped=true&"
import script from "./DefinitionSetting.vue?vue&type=script&lang=ts&"
export * from "./DefinitionSetting.vue?vue&type=script&lang=ts&"
import style0 from "./DefinitionSetting.vue?vue&type=style&index=0&id=666a21c6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "666a21c6",
  null
  
)

export default component.exports