import PaletteBlockView from "@/components/palette/PaletteBlockView.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import PaletteLayout from "@/views/palette/PaletteLayout.vue";
import PaletteViewMixin from "@/views/palette/PaletteViewMixin";
import PaletteViewerLayout from "@/views/palette/PaletteViewerLayout.vue";
import PaletteViewerBlockView from "@/components/palette/PaletteViewerBlockView.vue";
import PalettePageView from "@/components/palette/PalettePageView.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        PalettePageView,
        PaletteViewerBlockView,
        PaletteViewerLayout,
        PaletteLayout,
        ProgressCircle,
        PaletteBlockView,
    },
    mixins: [PaletteViewMixin],
    provide() {
        return {
            editorState: {},
        };
    },
    data() {
        return {
            basePath: "/palettes",
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn"]),
    },
    watch: {
        name: {
            immediate: true,
            handler(name) {
                this.loadError = null;
                if (name) {
                    this.loadPaletteName(name);
                }
                else {
                    this.loadError = `Palette with the name '${name}' could not be found.`;
                }
            },
        },
    },
    methods: {
        setupPalette(name, spec) {
            this.setupPaletteSpec(spec);
        },
    },
};
