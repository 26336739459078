import { capitalCase } from "change-case";
import { clone } from "remeda";
export default {
    props: {
        paramKey: String,
        wrap: {
            type: Object,
        },
        uniqueKey: String,
    },
    computed: {
        title() {
            return this.setting?.title || capitalCase(this.paramKey);
        },
    },
    methods: {
        emitSetting(value, key) {
            let useKey = key || this.paramKey;
            let input = {};
            input[useKey] = clone(value);
            this.$emit("input", input);
        },
    },
};
