import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import equals from "fast-deep-equal";
import { debounce } from "@blockwell/util";
export default {
    name: "LiteralSettingInput",
    components: { RadioTabs },
    props: {
        type: {
            type: String,
        },
        forceType: {
            type: String,
        },
        valueType: {
            type: String,
        },
        value: {
            type: [String, Number, Boolean, Array],
        },
    },
    data() {
        return {
            useType: this.forceType || "value",
            val: "",
            array: [{ index: 0, value: "" }],
        };
    },
    watch: {
        val: {
            handler: debounce(function (val) {
                if (this.useType === "value") {
                    this.$emit("input", val);
                }
            }, 200),
        },
        value: {
            immediate: true,
            deep: true,
            handler(value) {
                let val = "";
                let array = [{ index: 0, value: "" }];
                let useType = "value";
                if (Array.isArray(value)) {
                    array = value.map((it, index) => {
                        return {
                            index,
                            value: it,
                        };
                    });
                    useType = "array";
                }
                else if (this.forceType === "array") {
                    array = [{ index: 0, value: "" }];
                    useType = "array";
                }
                else {
                    val = value || "";
                }
                if (!equals(this.val, val)) {
                    this.val = val;
                }
                if (!equals(this.array, array)) {
                    this.array = array;
                }
                if (this.useType !== useType) {
                    this.useType = useType;
                }
            },
        },
        array: {
            deep: true,
            handler: debounce(function (val) {
                if (this.useType === "array") {
                    this.$emit("input", val.map((it) => it.value));
                }
            }, 200),
        },
    },
    methods: {
        addLiteral() {
            let index = this.array?.length > 0 ? this.array[this.array.length - 1].index + 1 : 0;
            this.array.push({
                index,
                value: "",
            });
        },
        removeLiteral(index) {
            let literalArray = this.array;
            literalArray.splice(literalArray.findIndex((it) => it.index === index), 1);
        },
        enterPress(index, event) {
            let form = this.$refs.literalForm;
            let inputs = form.querySelectorAll("input");
            let last = index === inputs.length - 1;
            event.preventDefault();
            if (last) {
                this.addLiteral();
            }
            this.$nextTick(() => {
                let form = this.$refs.literalForm;
                let inputs = form.querySelectorAll("input");
                let input = inputs[index + 1];
                input.focus();
            });
        },
        onPaste(index, event) {
            // @ts-ignore
            let clip = event.clipboardData || window.clipboardData;
            let paste = clip.getData("text");
            if (/\n/.test(paste)) {
                event.preventDefault();
                this.$nextTick(() => {
                    let literalArray = this.array;
                    let values = paste.split(/\n/).map((it) => {
                        return {
                            index: 0,
                            value: it,
                        };
                    });
                    let replaced = literalArray.slice(0, index).concat(values);
                    if (replaced.length < literalArray.length) {
                        replaced = replaced.concat(literalArray.slice(replaced.length));
                    }
                    // fix indexes
                    let i = 0;
                    for (let it of replaced) {
                        it.index = i++;
                    }
                    this.array = replaced;
                });
            }
        },
    },
};
