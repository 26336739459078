import PaletteModal from "@/components/palette/blocks/components/PaletteModal.vue";
import PaletteBlockView from "@/components/palette/PaletteBlockView.vue";
export default {
    name: "PalettePageView",
    components: { PaletteBlockView, PaletteModal },
    props: {
        manager: {
            type: Object,
        },
        basePath: String,
    },
    data() {
        return {
            page: null,
            layout: null,
            block: null,
            shown: false,
            paletteId: null,
            modified: null,
        };
    },
    computed: {
        fullscreen() {
            return this.layout === "full";
        },
        small() {
            return this.layout === "modal-small";
        },
        large() {
            return this.layout === "modal-large";
        },
        modalClasses() {
            return {
                fullscreen: this.fullscreen,
                "palette-modal-small": this.small,
                "palette-modal-large": this.large,
            };
        },
    },
    watch: {
        "$route.params.path": {
            immediate: true,
            handler(val) {
                this.manager.page.route(val || "");
            },
        },
    },
    methods: {
        onActivate({ page, path }) {
            const manager = this.manager;
            this.layout = page?.layout;
            if (this.page !== page) {
                this.page = page;
            }
            if (page && (!page.root.block || !this.block)) {
                this.shown = true;
                this.block = page.activate(path);
                this.block.block.layoutTree();
                this.block.block.renderTree();
                this.manager.page.emitTitle();
            }
            else if (!page) {
                this.block = null;
                this.shown = false;
            }
        },
        onLayout(layout) {
            this.layout = layout;
        },
        close() {
            let newPath = `${this.basePath}/${this.manager.name}`;
            if (this.$route.path !== newPath) {
                this.$router.push({
                    query: this.$route.query,
                    hash: this.$route.hash,
                    path: `${this.basePath}/${this.manager.name}`,
                });
            }
        },
        modifyBody() {
            let observer = new ResizeObserver((entries) => {
                this.position();
            });
            observer.observe(this.$refs.box);
            setTimeout(() => {
                observer.disconnect();
            }, 500);
        },
        position() {
            const backdrop = this.$refs.backdrop;
            const box = this.$refs.box;
            if (this.fullscreen || !backdrop || !box) {
                return;
            }
            let rect = backdrop.getBoundingClientRect();
            let top = Math.max(rect.top * -1, 0);
            let left = Math.max(rect.left * -1, 0);
            let height;
            let width;
            if (rect.bottom > window.innerHeight) {
                height = window.innerHeight - Math.max(0, rect.top);
            }
            else {
                height = rect.height + Math.min(0, rect.top);
            }
            if (rect.right > window.innerWidth) {
                width = window.innerWidth - Math.max(0, rect.left);
            }
            else {
                width = rect.width + Math.min(0, rect.left);
            }
            let boxWidth = box.clientWidth;
            let boxHeight = box.clientHeight;
            let x;
            let y;
            if (boxWidth > width) {
                x = 0;
            }
            else {
                x = (width - boxWidth) / 2;
            }
            if (boxHeight > height) {
                y = 0;
            }
            else {
                y = (height - boxHeight) / 2;
            }
            box.style.top = `${y + top}px`;
            box.style.left = `${x + left}px`;
        },
        backdropClick() {
            if (!this.noDismiss) {
                this.close();
            }
        },
    },
    created() {
        this.manager.page.events.on("activate", this.onActivate);
        this.manager.page.events.on("layout", this.onLayout);
    },
    beforeDestroy() {
        this.manager.page.events.off("activate", this.onActivate);
        this.manager.page.events.off("layout", this.onLayout);
    },
};
