import { render, staticRenderFns } from "./ArgumentsSetting.vue?vue&type=template&id=3573e615&scoped=true&"
import script from "./ArgumentsSetting.vue?vue&type=script&lang=ts&"
export * from "./ArgumentsSetting.vue?vue&type=script&lang=ts&"
import style0 from "./ArgumentsSetting.vue?vue&type=style&index=0&id=3573e615&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3573e615",
  null
  
)

export default component.exports