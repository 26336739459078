import FuelTokenSelect from "@/components/fuel/FuelTokenSelect.vue";
import BuyFuelWith from "@/components/fuel/BuyFuelWith.vue";
import { FuelData } from "@/lib/fuel";
export default {
    name: "BuyFuel",
    components: { BuyFuelWith, FuelTokenSelect },
    props: {
        token: String,
        fuel: FuelData,
    },
    data() {
        return {};
    }
};
