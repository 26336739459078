import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import MediaFileView from "@/components/palette/settings/media/MediaFileView.vue";
import MediaRequestMixin from "@/components/palette/settings/media/MediaRequestMixin";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "FileStyleSetting",
    components: { StyleLabel, MediaFileView },
    mixins: [StyleSettingMixin, MediaRequestMixin],
    inject: ["editorState"],
    data() {
        return {
            file: null,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val?.length > 10) {
                    let file = val.slice(4, -1);
                    const api = this.$store.getters["user/api"];
                    if (!file.startsWith("data:")) {
                        api.media
                            .findMedia(file)
                            .then((file) => (this.file = file))
                            .catch((err) => {
                            console.error(err);
                            this.file = null;
                        });
                    }
                    else {
                        this.file = null;
                    }
                }
                else {
                    this.file = null;
                }
            },
        },
    },
    methods: {
        click() {
            this.requestImage();
            this.$emit("active", true);
        },
        imageUpdate(uri) {
            this.entry.update({ value: `url(${uri})` });
            this.updateFromEntry();
        },
        imageClose() {
            this.$emit("active", false);
        }
    },
    mounted() {
        this.editorState.events.controls.on("imageClose", this.imageClose);
    },
    beforeDestroy() {
        this.editorState.events.controls.off("imageClose", this.imageClose);
    },
};
