import FuelIcon from "@/components/fuel/FuelIcon.vue";
export default {
    name: "FuelProductPrice",
    components: { FuelIcon },
    props: {
        product: {
            type: Object
        }
    },
    data() {
        return {};
    },
};
