import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import { ETH_ADDRESS_ZERO } from "@blockwell/eth-types";
import { addressRegex } from "@/assets/lib/util";
import { getAddress } from "@ethersproject/address";
import { chainEqual } from "@blockwell/chains";
import { VarsContract } from "@blockwell/variables";
export default {
    name: "ContribTokenBlockView",
    mixins: [BlockViewMixin],
    data() {
        return {
            options: [],
            chain: "mainnet",
            addresses: [],
            logos: [],
            value: "",
        };
    },
    computed: {},
    watch: {
        addresses: {
            immediate: true,
            deep: true,
            handler(val) {
                const api = this.$store.getters["user/api"];
                api.contracts
                    .getAll(val.map((address) => {
                    return {
                        address,
                        chainId: this.chain,
                    };
                }))
                    .then((res) => {
                    this.options = res
                        .map((it, index) => {
                        if (!it && val[index] === ETH_ADDRESS_ZERO) {
                            return {
                                id: null,
                                name: "Ether",
                                address: ETH_ADDRESS_ZERO,
                                network: this.chain,
                                features: [],
                                parameters: {
                                    symbol: "ETH",
                                    decimals: "18",
                                },
                            };
                        }
                        return it;
                    })
                        .filter((it) => !!it);
                    this.logos = this.options.map((it) => {
                        if (!it.id) {
                            return require("@/assets/images/ethereum.png");
                        }
                        if (chainEqual(1, it.network)) {
                            return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddress(it.address)}/logo.png`;
                        }
                        return null;
                    });
                });
            },
        },
    },
    methods: {
        onValue(value) {
            this.addresses = value.addresses;
            this.chain = value.chain;
        },
        input(ev) {
            let block = this.block.block.type;
            let target = ev.target;
            let address = target.value;
            if (address === ETH_ADDRESS_ZERO) {
                let contract = new VarsContract({
                    id: null,
                    name: "Ether",
                    address: ETH_ADDRESS_ZERO,
                    network: this.chain,
                    features: [],
                    parameters: {
                        symbol: "ETH",
                        decimals: "18",
                    },
                });
                block.setValue(contract);
            }
            else {
                const api = this.$store.getters["user/api"];
                api.contracts.getContract({ address, chainId: this.chain }).then((contract) => {
                    block.setValue(new VarsContract(contract));
                });
            }
        },
        logo(address) {
            if (address === "0x0000000000000000000000000000000000000000") {
                return require("@/assets/images/ethereum.png");
            }
            if (addressRegex.test(address)) {
                return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddress(address)}/logo.png`;
            }
            return "";
        },
        logoError(index) { },
    },
    destroyed() { },
};
