import ContractSettingMixin from "@/components/palette/settings/types/DynamicContextMixin";
import { Typing } from "@blockwell/eth-types";
import Info from "@/components/Info.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import { clone } from "remeda";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import StackListSetting from "@/components/palette/settings/types/components/StackListSetting.vue";
import ConversionConfig from "@/components/palette/settings/types/components/ConversionConfig.vue";
export default {
    name: "ConvertSetting",
    components: { ConversionConfig, StackListSetting, VariableSetting, Info },
    mixins: [ContractSettingMixin, SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: [Object, Array, Boolean],
        },
    },
    data() {
        return {
            outputs: null,
            checked: {},
            autoDecimals: "",
            argSetting: {
                type: "var",
                variableType: ["value", "contract"],
            },
            vars: [],
            isArray: false,
        };
    },
    computed: {
        varsDeps() {
            return {
                outputs: this.outputs,
                value: this.value,
                checked: this.checked,
                rawValue: this.dynamicContext?.rawValue,
            };
        },
    },
    watch: {
        "dynamicContext.contract": {
            immediate: true,
            handler(val) {
                if (val?.parameters?.decimals) {
                    this.autoDecimals = val.parameters.decimals;
                }
                else {
                    this.autoDecimals = null;
                }
            },
        },
        "dynamicContext.abi": {
            immediate: true,
            deep: true,
            handler(val) {
                let out = null;
                if (val?.outputs?.length > 0) {
                    let typing = Typing.parse(val.outputs[0]);
                    this.isArray = !!typing.array;
                    if (typing.name === "tuple") {
                        out = typing.components.map((it) => {
                            return {
                                key: it.parameterName,
                                type: it.name,
                            };
                        });
                    }
                    else {
                        out = [
                            {
                                key: "0",
                                type: typing.name,
                            },
                        ];
                    }
                }
                else {
                    this.isArray = false;
                }
                this.outputs = out;
            },
        },
        varsDeps: {
            deep: true,
            immediate: true,
            handler({ outputs, checked, value, rawValue, }) {
                if (!value) {
                    value = [];
                }
                let vars;
                if (!outputs) {
                    if (rawValue?.type === "value" && rawValue.value) {
                        outputs = this.dumbappValueToOutputs(rawValue.value);
                        this.isArray = Array.isArray(rawValue.value);
                    }
                    else {
                        this.isArray = false;
                    }
                }
                if (outputs?.length === 1) {
                    let val = Array.isArray(value) ? value : Object.values(value)[0];
                    vars = [
                        {
                            ...outputs[0],
                            value: val,
                        },
                    ];
                }
                else {
                    let obj = Array.isArray(value) ? null : value;
                    vars = outputs?.map((it, index) => {
                        let found = obj?.[it.key];
                        return {
                            ...it,
                            checked: checked?.[index] === undefined ? !!found : checked[index],
                            value: found,
                        };
                    });
                }
                this.vars = vars || [];
            },
        },
    },
    methods: {
        dumbappValueToOutputs(raw) {
            if (!raw) {
                return [];
            }
            if (typeof raw === "number") {
                return [
                    {
                        type: "uint",
                        key: "0",
                    },
                ];
            }
            else if (typeof raw === "boolean") {
                return [
                    {
                        type: "bool",
                        key: "0",
                    },
                ];
            }
            else if (typeof raw === "string") {
                return [
                    {
                        type: "string",
                        key: "0",
                    },
                ];
            }
            else if (Array.isArray(raw)) {
                return this.dumbappValueToOutputs(raw[0]);
            }
            else {
                return Object.entries(raw)
                    .map(([key, val]) => {
                    let outs = this.dumbappValueToOutputs(val);
                    if (outs.length !== 1) {
                        return null;
                    }
                    return {
                        key,
                        type: outs[0].type,
                    };
                })
                    .filter((it) => !!it);
            }
        },
        check(index, ev) {
            let el = ev.target;
            this.$set(this.checked, index, el.checked);
            if (!el.checked) {
                let outputs = this.outputs;
                if (outputs.length === 1) {
                    this.emitSetting(null);
                }
                else {
                    let key = outputs[index].key;
                    let value = clone(this.value);
                    if (value?.[key]) {
                        delete value[key];
                        if (Object.keys(value).length === 0) {
                            this.emitSetting(null);
                        }
                        else {
                            this.emitSetting(value);
                        }
                    }
                }
            }
        },
        layerLabel(value) {
            let manager = this.manager;
            let type = manager.registry.convertTypes.get(value.type);
            return type?.editorLabel(value.param);
        },
        keyList(key) {
            if (key) {
                let value;
                if (!this.value) {
                    value = {};
                }
                else if (Array.isArray(this.value)) {
                    value = {};
                }
                else {
                    value = clone(this.value);
                }
                let list = value[key];
                if (!list) {
                    list = [];
                    value[key] = list;
                }
                return { value, list };
            }
            else {
                if (!this.value) {
                    let list = [];
                    return { list, value: list };
                }
                let value = clone(this.value);
                return { list: value, value: value };
            }
        },
        input(update, index, key) {
            let { value, list } = this.keyList(key);
            list[index] = update;
            this.emitSetting(value);
        },
        addLayer(key) {
            let { value, list } = this.keyList(key);
            list.push({ type: "decimals" });
            this.emitSetting(value);
        },
        deleteLayer(index, key) {
            let { value, list } = this.keyList(key);
            list.splice(index, 1);
            this.emitSetting(value);
        },
    },
};
