import { defineComponent } from "vue";
import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default defineComponent({
    name: "RepeatBlockView",
    mixins: [BlockViewMixin],
    inject: ["editorState"],
    data() {
        return {
            layout: "list",
            nullList: false,
            showEmpty: false,
            emptyMessage: null
        };
    },
    methods: {
        onValue(value) {
            this.layout = value?.layout || "list";
            this.nullList = value.list.length === 1 && value.list[0] === null;
            this.showEmpty = !value.list || value.list.length === 0 || this.nullList;
            this.emptyMessage = value.empty;
        },
    }
});
