import { render, staticRenderFns } from "./TemplateBlockView.vue?vue&type=template&id=6186714e&scoped=true&"
import script from "./TemplateBlockView.vue?vue&type=script&lang=ts&"
export * from "./TemplateBlockView.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateBlockView.vue?vue&type=style&index=0&id=6186714e&prod&scoped=true&lang=scss&"
import style1 from "./TemplateBlockView.vue?vue&type=style&index=1&id=6186714e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6186714e",
  null
  
)

export default component.exports