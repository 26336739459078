import ContractSettingMixin from "@/components/palette/settings/types/DynamicContextMixin";
import { isConstantReference, isVariableReference } from "@blockwell/variables";
import Info from "@/components/Info.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import StackListSetting from "@/components/palette/settings/types/components/StackListSetting.vue";
import ConversionConfig from "@/components/palette/settings/types/components/ConversionConfig.vue";
import ConditionConfig from "@/components/palette/settings/types/components/ConditionConfig.vue";
import { clone } from "remeda";
export default {
    name: "ConditionsSetting",
    components: { ConditionConfig, ConversionConfig, StackListSetting, VariableSetting, Info },
    mixins: [ContractSettingMixin, SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: [Array],
        },
        parentIndex: {
            type: [Number, Array],
        },
    },
    data() {
        return {};
    },
    computed: {
        filterSample() {
            if (this.setting.filter &&
                Array.isArray(this.dynamicContext?.rawValue?.value) &&
                this.dynamicContext.rawValue.type === "value") {
                return this.dynamicContext.rawValue.value[0];
            }
            return null;
        },
    },
    watch: {},
    methods: {
        layerLogic(value, index) {
            if (index === 0) {
                return "";
            }
            return value?.logic?.toUpperCase() || "AND";
        },
        layerLeft(value) {
            return value?.left?.param && "name" in value.left.param
                ? value.left.param.name
                : "item";
        },
        layerOp(value) {
            if (value?.op === "truthy" || value?.op === "not truthy") {
                return "is " + value.op;
            }
            if (value?.right) {
                return value.op || "==";
            }
            return value?.op === "!=" ? "is not truthy" : "is truthy";
        },
        layerRight(value) {
            let right = value?.right;
            if (right) {
                if (isVariableReference(right) && right.param && "name" in right.param) {
                    return right.param.name;
                }
                else if (isConstantReference(right)) {
                    return right.param.value?.toString() || "";
                }
            }
            return "";
        },
        input(update, index) {
            let value = this.value ? clone(this.value) : [];
            value[index] = update;
            this.emitSetting(value);
        },
        addLayer() {
            let value = this.value ? clone(this.value) : [];
            value.push({ left: { type: "var", param: { name: "item" } } });
            this.emitSetting(value);
        },
        deleteLayer(index) {
            let value = this.value ? clone(this.value) : [];
            value.splice(index, 1);
            this.emitSetting(value);
        },
    },
};
