import MarkdownIt from 'markdown-it';
const md = MarkdownIt({});
md.use((md) => {
    const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };
    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        let link = tokens[idx];
        let href;
        let hrefIndex = link.attrIndex('href');
        if (hrefIndex >= 0) {
            href = link.attrs[hrefIndex][1];
            if (href) {
                let url = new URL(href);
                if (url.host && !/(^|\.)blockwell\.ai$/i.test(url.host)) {
                    link.attrSet("target", "_blank");
                }
            }
        }
        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self);
    };
});
export function markdownTemplate(source) {
    if (!source) {
        return "";
    }
    return md.render(source);
}
