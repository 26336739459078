import PaletteEditorDialogMixin from "@/components/palette/settings/PaletteEditorDialogMixin";
import CodeBlock from "@/components/CodeBlock.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import axios from "axios";
export default {
    name: "PaletteImport",
    components: { LoadingButton, CodeBlock },
    mixins: [PaletteEditorDialogMixin],
    data() {
        return {
            importCode: "",
            importError: null,
            importLoading: false,
        };
    },
    methods: {
        async doImport() {
            let saveState = this.saveState;
            this.importLoading = true;
            async function loadSchema(uri) {
                const res = await axios.get(uri);
                if (res.status >= 400)
                    throw new Error("Loading error: " + res.status);
                return res.data;
            }
            let spec;
            try {
                spec = JSON.parse(this.importCode);
                // let ajv = new Ajv2020({
                //     allErrors: true,
                //     loadSchema,
                //     allowUnionTypes: true,
                //     keywords: ["tsType"],
                //     formats: {
                //         uuid: {
                //             type: "string",
                //             validate: /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i,
                //         },
                //         contract: {
                //             type: "string",
                //             validate: /^[a-zA-Z0-9]+[./]0x[a-fA-F0-9]{40}$/,
                //         },
                //         "ethereum-address": {
                //             type: "string",
                //             validate: /^0x[a-fA-F0-9]{40}$/,
                //         },
                //         hex: {
                //             type: "string",
                //             validate: /^0x[a-fA-F\d]+$/,
                //         },
                //     },
                // });
                // let validate = await ajv.compileAsync<Palette>(
                //     await loadSchema("https://schema.blockwell.ai/palette/palette.schema.json")
                // );
                // let res = validate(spec);
                let res = spec;
                if (!res) {
                    // this.importError = ajv.errorsText(validate.errors);
                }
                else {
                    await Promise.all(saveState.events.emit("importSpec", spec));
                }
            }
            catch (err) {
                this.importError = err.message;
            }
            finally {
                this.$emit("close");
            }
        },
    }
};
