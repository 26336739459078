import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
export default {
    name: "SelectSetting",
    components: { SelectField, Info },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object
        },
        value: String
    },
    data() {
        return {
            selected: this.value || this.setting.default || ""
        };
    },
    computed: {
        options() {
            return this.setting.enum.map((it) => {
                return {
                    value: it
                };
            });
        }
    },
    watch: {
        value(val) {
            if (this.selected !== val) {
                this.selected = val;
            }
        },
        selected(val) {
            this.emitSetting(val || undefined);
        }
    }
};
