import Clipboard from "@/components/Clipboard.vue";
import GasEstimate from "@/components/dumbapp/GasEstimate.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import { mapGetters } from "vuex";
import "vue-unique-id";
import { getChain } from "@blockwell/chains";
export default {
    name: "WalletBlockMetamask",
    components: { GasEstimate, Clipboard, LoadingButton },
    props: {
        account: String,
        chainId: Number,
        wallet: Object,
        state: String
    },
    data() {
        return {
            trustWalletError: false,
            networkChangeInterval: null,
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "api"]),
        noProvider() {
            return this.state === "not-installed";
        },
        network() {
            if (this.chainId) {
                return getChain(this.chainId);
            }
            return null;
        },
        networkName() {
            return this.network?.networkName;
        },
        unconnected() {
            return this.state === "ready";
        },
        connecting() {
            return this.state === "loading-accounts";
        },
        rejected() {
            return this.state === "accounts-rejected";
        }
    },
    watch: {},
    methods: {
        connect() {
            this.wallet.connect();
        },
    },
};
