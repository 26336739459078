import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default {
    name: "ConditionChildView",
    mixins: [BlockViewMixin],
    inject: {
        editorState: {
            default: null
        }
    },
    data() {
        return {
            show: null,
        };
    },
    computed: {
        showing() {
            return ((this.editorState?.editing && this.editorState.options.conditionBlocks) || this.show);
        },
        classes() {
            return [
                ...this.typeClass(),
                {
                    hidden: this.show === false,
                },
            ];
        },
    },
    watch: {
        showing(val, old) {
            if (!old && val) {
                this.$nextTick(() => {
                    this.block.block.element = this.$el;
                });
            }
        },
    },
    methods: {
        onValue(value) {
            this.show = value.value;
        },
    },
};
