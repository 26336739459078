import { clone, reverse } from "remeda";
import SettingData from "@/components/palette/settings/SettingData.vue";
import DynamicVariable from "@/components/palette/settings/DynamicVariable.vue";
export default {
    name: "BlockVariables",
    components: { DynamicVariable, SettingData },
    inject: ["manager", "editorState"],
    props: {
        spec: {
            type: Object,
        },
        wrap: {
            type: Object,
        },
    },
    data() {
        return {
            contextName: typeof this.spec?.createCtx === "string" ? this.spec.createCtx : "",
        };
    },
    computed: {
        vars() {
            let spec = this.spec;
            let vars = spec.vars.map((spec, index) => {
                return {
                    spec,
                    index,
                };
            });
            return reverse(vars);
        },
    },
    watch: {
        "spec.createCtx": {
            handler(val) {
                if (this.contextName !== val) {
                    if (val === true) {
                        this.contextName = "";
                    }
                    else {
                        this.contextName = val;
                    }
                }
            },
        },
        "editorState.selectVariable": {
            immediate: true,
            handler(select) {
                if (select) {
                    let index = this.vars.findIndex((it) => it.spec.name === this.editorState.selectVariable);
                    if (index > -1) {
                        const findVar = () => {
                            this.$nextTick(() => {
                                if (!this.$refs.vars[index]?.$el) {
                                    findVar();
                                    return;
                                }
                                this.$refs.vars[index].$el.scrollIntoView({
                                    block: "start",
                                });
                            });
                        };
                        findVar();
                    }
                    this.editorState.selectVariable = null;
                }
            },
        },
    },
    methods: {
        add() {
            let block = this.wrap.block;
            let spec = {
                type: "literal",
                name: "",
                param: {
                    value: "",
                },
            };
            this.spec.vars.push(clone(spec));
            block.variable.addVariable(spec);
        },
        update(index, val) {
            let block = this.wrap.block;
            let ref = block.variable.updateVariable(index, clone(val));
            ref?.value();
        },
        remove(index) {
            let block = this.wrap.block;
            block.variable.removeVariable(index);
            this.spec.vars.splice(index, 1);
        },
        nameInput(ev) {
            let input = ev.target;
            let block = this.wrap.block;
            let val = input.value;
            if (val) {
                this.$set(this.spec, "createCtx", val);
                block.updateContextName(val);
            }
            else {
                this.$delete(this.spec, "createCtx");
                block.updateContextName(undefined);
            }
        },
    },
};
