import { PaletteManager } from "@blockwell/palette";
import BlockSettingsGroup from "@/components/palette/settings/BlockSettingsGroup.vue";
import { clone } from "remeda";
export default {
    name: "MainPageEditor",
    components: { BlockSettingsGroup },
    props: {
        manager: {
            type: Object,
        },
    },
    provide() {
        return {
            manager: this.manager,
        };
    },
    data() {
        return {
            settings: PaletteManager.settings,
            errorMessage: {},
            spec: {
                title: {
                    type: "const",
                    param: {
                        value: "",
                    },
                },
            },
        };
    },
    computed: {
        rootBlock() {
            const manager = this.manager;
            return manager.root;
        },
    },
    watch: {
        manager: {
            immediate: true,
            handler(val, old) {
                if (old !== val) {
                    if (old) {
                        old.page.onParam(this.onParam);
                    }
                    val?.page?.onParam(this.onParam);
                }
            },
        },
    },
    methods: {
        onParam(spec) {
            Object.assign(this.spec, clone(spec));
        },
        update(update) {
            for (let [key, val] of Object.entries(update)) {
                this.manager.page.updateParam(key, val);
            }
        },
        remove() { },
    },
    beforeDestroy() { },
};
