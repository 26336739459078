import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import { StackStyleSet } from "@blockwell/palette";
import { stackComponents, stackTypes, } from "@/components/palette/settings/style/types/stack/StackType";
import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "StackStyleSetting",
    mixins: [StyleSettingMixin],
    components: { StyleLabel, SettingsPopper, ...stackComponents },
    data() {
        return {
            preventClose: false
        };
    },
    computed: {
        prop() {
            return this.item?.prop;
        },
        preview() {
            let property = this.prop?.property;
            return stackTypes[property]?.preview;
        },
        settings() {
            let property = this.prop?.property;
            return stackTypes[property]?.settings;
        },
        stacked() {
            return StackStyleSet.from(this.entry);
        }
    },
    methods: {
        addLayer() {
            let entry = this.entry;
            entry.update({ push: true, updates: [{ property: null }] });
            this.updateFromEntry();
        },
        deleteLayer(index) {
            this.entry.value.values.splice(index, 1);
            this.updateFromEntry();
        },
        update(index, update) {
            let entry = this.entry;
            entry.update({ index, updates: [update] });
            this.updateFromEntry();
        }
    },
};
