export default {
    name: "NameFind",
    props: {
        label: String,
        source: {
            type: Function,
        },
        value: String,
        readonly: Boolean,
        float: Boolean,
        hideNotFound: {
            type: Boolean,
        },
        identifier: Boolean,
    },
    data() {
        return {
            confirmed: true,
            currentValue: this.value,
            open: false,
            results: null,
            focus: -1,
        };
    },
    watch: {
        value(val) {
            this.currentValue = val;
        },
    },
    methods: {
        inputFocus(ev) {
            this.open = true;
            this.search(this.currentValue);
            let el = ev.target;
            el.select();
        },
        inputBlur(ev) {
            this.open = false;
        },
        inputInput(ev) {
            let el = ev.target;
            this.confirmed = false;
            this.currentValue = el.value;
            this.search(this.currentValue);
            this.$emit("input", el.value);
        },
        inputClick(ev) {
            this.open = true;
        },
        optionClick(val) {
            if (val) {
                let currentValue = typeof val === "string" ? val : val.name;
                this.$emit("input", currentValue);
                this.$emit("confirm", currentValue);
                this.currentValue = currentValue;
            }
            this.confirmed = true;
            this.open = false;
        },
        optionMouseDown(ev) {
            ev.preventDefault();
        },
        optionMouseEnter(index, ev) {
            this.focus = index;
        },
        search(query) {
            this.query(query, (vals) => {
                this.results = vals || [];
            });
        },
        confirm(val) {
            if (val) {
                this.$emit("input", typeof val === "string" ? val : val.name);
                this.$emit("confirm", typeof val === "string" ? val : val.name);
            }
            this.confirmed = true;
        },
        query(query, populate) {
            this.source(this.confirmed ? undefined : query?.toLowerCase(), populate);
        },
        keydown(event) {
            let target = event.target;
            switch (event.key) {
                case "Enter":
                    if (target.value) {
                        this.$emit("enter", target.value);
                    }
                    if (this.focus > -1 && this.results?.[this.focus]) {
                        this.optionClick(this.results[this.focus]);
                    }
                    break;
                case "ArrowUp":
                    if (this.focus > 0) {
                        this.focus -= 1;
                        this.$refs.opts[this.focus]?.scrollIntoView();
                    }
                    else if (this.results?.length > 1) {
                        this.focus = this.results.length - 1;
                        this.$refs.opts[this.focus]?.scrollIntoView();
                    }
                    break;
                case "ArrowDown":
                    if (this.focus === -1) {
                        this.focus = 0;
                    }
                    else if (this.focus < (this.results?.length || 0) - 2) {
                        this.focus += 1;
                        this.$refs.opts[this.focus]?.scrollIntoView();
                    }
                    else if (this.results?.length > 1) {
                        this.focus = 0;
                        this.$refs.opts[this.focus]?.scrollIntoView();
                    }
                    break;
                case "Escape":
                    this.open = false;
                    break;
                case "Home":
                case "End":
                case "Backspace":
                case "Delete":
                case "ArrowLeft":
                case "ArrowRight":
                    return;
            }
            if (this.identifier) {
                if (target.value.length === 0) {
                    if ("0123456789-".includes(event.key)) {
                        event.preventDefault();
                        return;
                    }
                }
                if (!"0123456789abcdefghijklmnopqrstuvwxyz-".includes(event.key)) {
                    event.preventDefault();
                }
            }
        },
    },
};
