function viewOptions(view) {
    return [
        {
            name: "Extra Spacing",
            help: "Show additional spacing to make it easier to select blocks.",
            option: `${view}.extraSpacing`,
        },
        {
            name: "Outlines",
            help: "Display dashed outlines around all blocks.",
            option: `${view}.outlines`,
        },
        {
            name: "Condition Blocks",
            help: "Always show condition blocks, even when not normally displayed.",
            option: `${view}.conditionBlocks`,
        },
        {
            name: "Condition Stripes",
            help: "When showing condition blocks, add stripes to blocks that wouldn't normally be showing.",
            option: `${view}.conditionStripes`,
        },
        {
            name: "Show Selection",
            help: "Show the blue selection box for the currently selected block.",
            option: `${view}.selection`,
        },
    ];
}
export const paletteMainMenu = [
    {
        name: "File",
        children: [
            {
                name: "Save",
                action: "save",
                icon: "save",
                help: "Save the recent changes as a draft.",
                enabled(saveState) {
                    return saveState.localDraft && saveState.canEdit;
                },
            },
            {
                name: "Save as...",
                action: "save-as",
                icon: "save_as",
                help: "Save the Palette with a different name.",
            },
            {
                name: "Sharing",
                action: "sharing",
                icon: "groups",
                help: "Share the Palette with other users.",
                enabled(saveState) {
                    return saveState.canEdit;
                }
            },
            {
                type: "divider",
            },
            {
                name: "Subscription",
                action: "subscription",
                icon: "local_fire_department",
                help: "Manage this Palette's subscription."
            },
            {
                name: "Publish Draft",
                action: "publish",
                icon: "public",
                help: "Publish the saved draft as the live Palette.",
                enabled(saveState) {
                    return saveState.isDraft;
                },
            },
            {
                name: "Version History",
                action: "history",
                icon: "history",
                help: "View the history of published versions.",
            },
            {
                type: "divider",
            },
            {
                name: "Export Palette Spec",
                action: "export",
                help: "Export the specification of the Palette as JSON.",
            },
            {
                name: "Import Palette",
                action: "import",
                help: "Import a JSON Palette specification and replace the current Palette.",
            },
            {
                type: "divider",
            },
            {
                name: "Discard Changes",
                action: "discard",
                icon: "delete_forever",
                help: "Discard all unsaved changes.",
                enabled(saveState) {
                    return saveState.isDraft || !!saveState.localDraft;
                },
            },
        ],
    },
    {
        name: "View",
        children: [
            {
                name: "Default Mode",
                children: viewOptions("view.default"),
                help: "Viewing options for the default editor view.",
            },
            {
                name: "Preview Mode",
                children: viewOptions("view.preview"),
                icon: "remove_red_eye",
                help: "Viewing options for when the Preview Mode is selected.",
            },
        ],
    },
];
