import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
export default {
    name: "PaletteSharingEntry",
    components: { LoadingIcon, RadioTabs },
    props: {
        palette: String,
        email: String,
        role: String,
        disabled: Boolean,
    },
    data() {
        return {
            removing: false,
        };
    },
    methods: {
        updateRole(role) {
            this.$emit("role", role);
        },
        remove() {
            const api = this.$store.getters["user/api"].palette;
            this.removing = true;
            api.removeShare(this.palette, this.email, this.role)
                .then(() => {
                this.$emit("reload");
            })
                .finally(() => (this.removing = false));
        },
    },
};
