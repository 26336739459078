import { defineComponent } from "vue";
import Markdown from "@/components/Markdown.vue";
import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import DumbappPopover from "@/components/DumbappPopover.vue";
import ProgressCircle from "@/components/ProgressCircle.vue";
import Slide from "@/components/Slide.vue";
import { Chain } from "@blockwell/chain-client";
import { parameterToString } from "@blockwell/eth-types";
export default defineComponent({
    name: "DumbappBlockView",
    components: { ProgressCircle, DumbappPopover, Markdown, Slide },
    mixins: [BlockViewMixin],
    data() {
        return {
            dumbapp: null,
            layout: "below",
            values: {},
            prefilled: {},
            label: "Submit",
            disabled: false,
            result: null,
            errorMessage: "",
            forceWalletType: {
                type: null,
            },
        };
    },
    provide() {
        return {
            forceWalletType: this.forceWalletType,
        };
    },
    computed: {
        extras() {
            return {
                palette: {
                    name: this.block.block.registry.tracker.paletteName,
                    block: this.block.block.key,
                    shortcode: this.dumbapp?.shortcode,
                },
            };
        },
        pending() {
            let result = this.result;
            switch (result?.status) {
                case "network":
                case "confirm":
                    return "Waiting for MetaMask";
                case "new":
                case "nonce":
                case "submitted":
                    return "Submitted";
                default:
                    return null;
            }
        },
        completed() {
            return this.result?.status === "completed";
        },
        errored() {
            return this.result?.status === "error";
        },
    },
    methods: {
        onValue(render) {
            this.dumbapp = render.dumbapp;
            this.layout = render.layout;
            this.label = render.label;
            this.result = render.result;
            this.disabled = render.disabled;
            this.forceWalletType.type = render.walletType;
            let values = {};
            let prefilled = {};
            if (render.values) {
                let i = 0;
                for (let it of render.values.names) {
                    let value = render.values.value[i];
                    if (value !== null && value !== undefined) {
                        if (typeof value === "number") {
                            value = value.toString();
                        }
                        if (render.values.prefill[i]) {
                            prefilled[it] = value;
                        }
                        else {
                            values[it] = value;
                        }
                    }
                    ++i;
                }
            }
            this.values = values;
            this.prefilled = prefilled;
            if (render.result?.step?.error) {
                let error = render.result.step.error;
                if (error.data.code === 3 && error.data.data) {
                    let api = this.$store.getters["user/api"];
                    api.contracts
                        .getContractAbi({
                        chainId: this.result.step.network,
                        address: this.result.step.address,
                    })
                        .then(async (abi) => {
                        let decoded = await Chain.decodeError(error.data.data, abi);
                        if ("code" in decoded) {
                            this.errorMessage = decoded.defaultMessage || decoded.identifier;
                        }
                        else {
                            this.errorMessage =
                                decoded.name +
                                    "(" +
                                    decoded.inputs.map((it) => parameterToString(it)).join(", ") +
                                    ")";
                        }
                    });
                }
                else {
                    this.errorMessage = error.message;
                }
            }
            else {
                this.errorMessage = "";
            }
        },
        reset() {
            if (this.block.block?.type?.resultVariable) {
                this.block.block.type.resultVariable.type.update(null);
            }
        },
    },
});
