import PaletteSharingEntry from "@/components/palette/settings/PaletteSharingEntry.vue";
import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import PaletteEditorDialogMixin from "@/components/palette/settings/PaletteEditorDialogMixin";
export default {
    name: "PaletteSharing",
    components: { RadioTabs, PaletteSharingEntry },
    mixins: [PaletteEditorDialogMixin],
    data() {
        return {
            shared: [],
            owner: null,
            addEmail: "",
            addRole: "viewer",
            addNotify: true,
            sharingErrors: [],
            shareProcessing: false
        };
    },
    methods: {
        load() {
            const api = this.$store.getters["user/api"].palette;
            api.getSharing(this.name).then((res) => {
                this.shared = res.access;
                this.owner = res.owner;
            });
        },
        share() {
            if (this.shareProcessing) {
                return;
            }
            const api = this.$store.getters["user/api"].palette;
            this.shareProcessing = true;
            const email = this.addEmail;
            const role = this.addRole;
            const notify = !!this.addNotify;
            api.shareWith(this.name, email, role, notify)
                .then(() => {
                this.$formulate.reset("share-with");
                this.addNotify = notify;
                this.shared.push({ email, role });
            })
                .finally(() => (this.shareProcessing = false));
        },
        updateRole(email, role, newRole) {
            const api = this.$store.getters["user/api"].palette;
            api.updateShare(this.name, email, role, newRole).then(this.load);
        }
    },
    created() {
        this.load();
    }
};
