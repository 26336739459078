import AceEditorMixin from "@/components/palette/settings/types/components/AceEditorMixin";
export default {
    name: "EditorSettingInline",
    mixins: [AceEditorMixin],
    props: {},
    data() {
        return {};
    },
    methods: {},
    mounted() {
        this.prepareAce(false, true);
    }
};
