import { paletteMainMenu } from "@/components/palette/settings/menu/menu";
import PaletteMenuItem from "@/components/palette/settings/menu/PaletteMenuItem.vue";
export default {
    name: "PaletteMainMenu",
    components: { PaletteMenuItem },
    data() {
        return {
            menu: paletteMainMenu,
        };
    },
    methods: {
        action(action) {
            this.$emit("action", action);
        },
        option(name, value) {
            this.$store.commit("palette/update_option", { name, value });
        }
    }
};
