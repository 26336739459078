import PaletteBlockView from "@/components/palette/PaletteBlockView.vue";
import StyleableLayout from "@/layouts/StyleableLayout.vue";
import PaletteSettings from "@/components/palette/settings/PaletteSettings.vue";
import PaletteEditorHeader from "@/components/palette/settings/PaletteEditorHeader.vue";
import PaletteEditorMixin from "@/views/palette/PaletteEditorMixin";
import ProgressCircle from "@/components/ProgressCircle.vue";
import PaletteLayout from "@/views/palette/PaletteLayout.vue";
import ImageManager from "@/components/palette/settings/media/ImageManager.vue";
import PaletteViewMixin from "@/views/palette/PaletteViewMixin";
import PalettePageView from "@/components/palette/PalettePageView.vue";
import PaletteTree from "@/components/palette/settings/PaletteTree.vue";
import PaletteSaveMixin from "@/views/palette/PaletteSaveMixin";
import SettingsDropdown from "@/components/palette/settings/form/SettingsDropdown.vue";
export default {
    components: {
        SettingsDropdown,
        PaletteTree,
        PalettePageView,
        ImageManager,
        PaletteLayout,
        ProgressCircle,
        PaletteEditorHeader,
        PaletteSettings,
        StyleableLayout,
        PaletteBlockView,
    },
    mixins: [PaletteEditorMixin, PaletteSaveMixin, PaletteViewMixin],
    data() {
        return {
            rename: null,
            basePath: "/palette2",
        };
    },
    computed: {
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
    },
    watch: {
        name: {
            immediate: true,
            handler(name, old) {
                this.loadError = null;
                this.editorState.name = name;
                this.$store.restored.then(() => {
                    if (old && old !== name) {
                        this.saveState.draftTimestamp = 0;
                        this.saveState.updatedAt = 0;
                        this.saveState.version = -1;
                    }
                    this.draftLoader();
                });
            },
        },
        $route: {
            deep: true,
            immediate: true,
            handler(val) {
                let parts = val.hash.slice(1).split("/");
                this.editorState.editorPath = parts.slice(1);
                if (parts[0] === "editor") {
                    if (!this.editorState.editing) {
                        this.openEditor();
                    }
                }
                else if (this.editorState.editing) {
                    this.closeEditor();
                }
            },
        },
    },
    methods: {
        setupPalette(name, spec) {
            let useSpec;
            if (name === "new" && !spec) {
                useSpec = {
                    "name": "",
                    "schemaVersion": 2,
                    "root": {
                        "type": "layout",
                        "classes": [
                            "main-layout"
                        ],
                        "children": [
                            {
                                "block": {
                                    "type": "layout",
                                    "classes": [
                                        "header"
                                    ],
                                    "children": [
                                        {
                                            "block": {
                                                "type": "template",
                                                "param": {
                                                    "template": {
                                                        "type": "const",
                                                        "param": {
                                                            "value": "# New Palette"
                                                        }
                                                    }
                                                },
                                                "classes": [
                                                    "heading"
                                                ]
                                            }
                                        },
                                        {
                                            "block": {
                                                "type": "template",
                                                "param": {
                                                    "template": {
                                                        "type": "const",
                                                        "param": {
                                                            "value": "This is a new Palette with just some text."
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    },
                    "style": [
                        {
                            "name": "heading",
                            "css": {
                                "text-align": "center"
                            }
                        }
                    ]
                };
            }
            else {
                useSpec = spec;
            }
            this.setupPaletteSpec(useSpec)
                .then((manager) => {
                manager.registry.editor = true;
                manager.registry.events.on("change", this.change);
                manager.style.events.on("update", this.styleUpdated);
            });
        },
    },
};
