import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import BigNumber from "bignumber.js";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "NumberStyleSetting",
    components: { StyleLabel },
    mixins: [StyleSettingMixin],
    data() {
        return {
            showUnits: false,
        };
    },
    methods: {
        openUnits() {
            this.showUnits = !this.showUnits;
            if (this.showUnits) {
                window.addEventListener("click", this.globalClick, true);
            }
        },
        globalClick(event) {
            requestAnimationFrame(() => {
                this.showUnits = false;
            });
            window.removeEventListener("click", this.globalClick, true);
        },
        keydown(ev) {
            let amount;
            let unit = this.entry?.value?.unit;
            if (this.item.prop.step) {
                amount = this.item.prop.step;
            }
            else {
                switch (unit) {
                    case "px":
                        amount = 1;
                        break;
                    case "em":
                    case "rem":
                        amount = 0.1;
                        break;
                    default:
                        amount = 1;
                        break;
                }
            }
            if (ev.getModifierState("Control")) {
                amount *= 10;
            }
            switch (ev.key) {
                case "ArrowUp":
                    ev.preventDefault();
                    this.change(amount);
                    break;
                case "ArrowDown":
                    ev.preventDefault();
                    this.change(amount * -1);
                    break;
            }
        },
        change(amount) {
            let value = new BigNumber(this.entry?.value?.value);
            if (!value.isNaN()) {
                value = value.plus(amount);
                this.entry.update({ value: value.toString(10) });
                this.updateFromEntry();
            }
        },
    },
};
