import FuelMixin from "@/components/fuel/FuelMixin";
import LoadingButton from "@/components/LoadingButton.vue";
import FuelPopover from "@/components/fuel/FuelPopover.vue";
import Info from "@/components/Info.vue";
import FuelIcon from "@/components/fuel/FuelIcon.vue";
import { defineComponent } from "vue";
import FuelUseMixin from "@/components/fuel/FuelUseMixin";
import SimpleFuelBalance from "@/components/fuel/SimpleFuelBalance.vue";
export default defineComponent({
    name: "FuelUse",
    components: { SimpleFuelBalance, FuelIcon, Info, FuelPopover, LoadingButton },
    mixins: [FuelMixin, FuelUseMixin],
    props: {
        label: String,
    },
    provide() {
        return {
            provider: null,
        };
    },
    methods: {
        click() {
            if (this.ticket) {
                this.$emit("submit", this.ticket.id);
            }
            else if (!this.sufficientBalance) {
                this.showPopover = true;
            }
            else {
                const api = this.$store.getters["user/api"].fuel;
                this.fuelLoading = true;
                api.buyTicket(this.productData.id)
                    .then((res) => {
                    this.$emit("submit", res.id);
                })
                    .finally(() => {
                    this.fuelLoading = false;
                });
            }
        },
    },
});
