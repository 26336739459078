import { PageManager } from "@blockwell/palette";
import BlockSettingsGroup from "@/components/palette/settings/BlockSettingsGroup.vue";
import { clone } from "remeda";
export default {
    name: "PageEditor",
    components: { BlockSettingsGroup },
    props: {
        page: {
            type: Object,
        },
        manager: {
            type: Object,
        },
        create: Boolean,
    },
    provide() {
        return {
            manager: this.manager,
        };
    },
    data() {
        return {
            settings: PageManager.settings,
            errorMessage: {},
            spec: {
                name: "",
                parentContext: undefined,
                layout: undefined,
                pathParam: [],
            },
        };
    },
    computed: {
        rootBlock() {
            const manager = this.manager;
            if (this.page?.root) {
                return this.page.root;
            }
            if (this.spec.parentContext) {
                let ctx = manager.root.block.findNamedContext(this.spec.parentContext);
                let block = manager.registry.blocks.get(ctx.ownerKey);
                return Object.freeze({ block });
            }
            return manager.root;
        },
    },
    watch: {
        page: {
            immediate: true,
            handler(val, old) {
                if (old !== val) {
                    if (old) {
                        old.offSpec(this.onSpec);
                    }
                    val.onSpec(this.onSpec);
                }
            },
        },
    },
    methods: {
        onSpec(spec) {
            Object.assign(this.spec, clone(spec));
        },
        submit() {
            this.manager.page.addPage(this.page);
            this.$emit("created", this.page.name);
        },
        update(update) {
            for (let [key, value] of Object.entries(update)) {
                const page = this.page;
                if (page) {
                    if (key === "name") {
                        if (value.toLowerCase() === "main") {
                            this.errorMessage.name =
                                "'main' is a reserved name, it cannot be used for pages.";
                        }
                        else if (this.manager.page.isNameAvailable(value, page)) {
                            page.updateSpec(key, value);
                            delete this.errorMessage.name;
                        }
                        else {
                            this.errorMessage.name = "Page name already in use.";
                        }
                    }
                    else if (key === "parentContext" || key === "layout" || key === "title") {
                        page.updateSpec(key, value);
                    }
                    else if (key === "pathParam") {
                        page.updateParameters(clone(value), this.manager.page.lastPath);
                    }
                }
            }
        },
        remove() {
            this.$emit("remove");
            this.manager.page.removePage(this.page);
        },
    },
    beforeDestroy() {
        this.page?.offSpec(this.onSpec);
    },
};
