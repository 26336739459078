import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import Markdown from "@/components/Markdown.vue";
export default {
    name: "ToggleSetting",
    components: { Markdown },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: Boolean,
    },
    data() {
        return {
            checked: this.value,
        };
    },
    computed: {},
    watch: {
        checked(val) {
            this.emitSetting(val);
        },
    },
};
