export default {
    inject: ["editorState"],
    computed: {
        editing() {
            return this.editorState?.editing;
        },
        showSlot() {
            return this.editing && !this.filtered?.length;
        },
    },
    watch: {
        editing: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.addDragEvents();
                }
                else {
                    this.removeDragEvents();
                }
            },
        },
    },
    methods: {
        addDragEvents() {
            if (this.$el) {
                this.$el.addEventListener("dragover", this.dragOver);
                this.$el.addEventListener("dragenter", this.dragEnter);
                this.$el.addEventListener("drop", this.dragDrop);
            }
        },
        removeDragEvents() {
            if (this.$el) {
                this.$el.removeEventListener("dragover", this.dragOver);
                this.$el.removeEventListener("dragenter", this.dragEnter);
                this.$el.removeEventListener("drop", this.dragDrop);
            }
        },
        dragOver(ev, slot) {
            if (!slot && this.type === "repeat-child") {
                return;
            }
            ev.preventDefault();
            ev.stopPropagation();
            let target = this.key;
            let wrapper = this.$el;
            let bound = wrapper.getBoundingClientRect();
            let offsetX = ev.clientX - bound.x;
            let offsetY = ev.clientY - bound.y;
            if (slot) {
                let empty = wrapper.querySelector(`.empty-slot`);
                this.editorState.events.drag.emit("drag", {
                    target,
                    slot,
                    rect: new DOMRect(0, 2, empty.offsetWidth, empty.offsetHeight - 4),
                    element: empty
                });
            }
            else if (this.block.block.parent) {
                let rect = new DOMRect();
                rect.x = 0;
                rect.y = 0;
                if (offsetX < 20) {
                    rect.width = 3;
                    rect.height = wrapper.offsetHeight;
                }
                else if (offsetX > wrapper.offsetWidth - 20) {
                    rect.x = wrapper.offsetWidth;
                    rect.width = 3;
                    rect.height = wrapper.offsetHeight;
                }
                else if (offsetY < wrapper.offsetHeight / 2) {
                    rect.width = wrapper.offsetWidth;
                    rect.height = 3;
                }
                else {
                    rect.y = wrapper.offsetHeight;
                    rect.width = wrapper.offsetWidth;
                    rect.height = 3;
                }
                this.editorState.events.drag.emit("drag", { target, slot, rect, element: wrapper });
            }
            else {
                this.editorState.events.drag.emit("drag", { target: null, slot: null, rect: null });
            }
        },
        dragEnter(ev, slot) {
            if (!slot && this.type === "repeat-child") {
                ev.preventDefault();
            }
        },
        dragDrop(ev, slot) {
            if (!slot && this.type === "repeat-child") {
                return;
            }
            ev.preventDefault();
            ev.stopPropagation();
            let target = this.key;
            let wrapper = this.$el;
            let bound = wrapper.getBoundingClientRect();
            let offsetX = ev.clientX - bound.x;
            let offsetY = ev.clientY - bound.y;
            let key = ev.dataTransfer.getData("text/key");
            let spec = ev.dataTransfer.getData("text/spec");
            let data = {
                target,
            };
            if (key) {
                data.block = parseInt(key);
            }
            else {
                data.type = JSON.parse(spec);
            }
            if (slot) {
                data.slot = slot;
            }
            else if (this.block.block.parent) {
                if (offsetX < 20) {
                    data.position = "left";
                }
                else if (offsetX > wrapper.offsetWidth - 20) {
                    data.position = "right";
                }
                else if (offsetY < wrapper.offsetHeight / 2) {
                    data.position = "top";
                }
                else {
                    data.position = "bottom";
                }
            }
            else {
                data = { block: null, target: null };
            }
            this.editorState.events.drag.emit("drop", data);
        },
    },
    mounted() {
        if (this.editing) {
            this.addDragEvents();
        }
    },
    beforeDestroy() {
        if (this.editing) {
            this.removeDragEvents();
        }
    },
};
