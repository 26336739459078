import Markdown from "@/components/Markdown.vue";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import { mapGetters, mapMutations } from "vuex";
import { walletProvider } from "@/components/dumbapp/init";
import WalletBlockBlockwell from "@/components/palette/blocks/components/WalletBlockBlockwell.vue";
import WalletBlockMetamask from "@/components/palette/blocks/components/WalletBlockMetamask.vue";
import Modal from "@/components/Modal.vue";
import PaletteModal from "@/components/palette/blocks/components/PaletteModal.vue";
const walletTypes = [
    {
        type: "wallet",
        name: "Blockwell Wallet",
        component: WalletBlockBlockwell,
        description: "Flexible blockchain wallet by Blockwell.",
        logo: require("@/assets/images/lion.png"),
    },
    {
        type: "metamask",
        name: "MetaMask",
        component: WalletBlockMetamask,
        description: "Browser extension crypto wallet.",
        logo: require("@/assets/images/logos/metamask-fox.svg"),
    },
];
const walletTypesMap = Object.fromEntries(walletTypes.map((it) => [it.type, it]));
export default {
    name: "WalletBlockView",
    components: { PaletteModal, Modal, DumbappWallet, Markdown },
    mixins: [BlockViewMixin],
    data() {
        return {
            value: "",
            walletHolder: {
                wallet: null,
                account: null,
                chainId: null,
                state: null,
            },
            showWalletSelect: false,
            types: walletTypes,
            defaultWallet: null
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn", "executorParameters"]),
        type() {
            return this.$store.state.dumbapp.walletType;
        },
        account() {
            return this.walletHolder.account;
        },
        walletType() {
            return walletTypesMap[this.type || this.defaultWallet || "wallet"];
        },
        walletLogo() {
            return this.walletType.logo;
        },
        walletName() {
            return this.walletType.name;
        },
        walletComponent() {
            return this.walletType.component;
        },
        walletDeps() {
            return {
                type: this.type || this.defaultWallet || "wallet",
                parameters: this.executorParameters,
            };
        },
    },
    watch: {
        "walletHolder": {
            immediate: true,
            deep: true,
            handler(val) {
                let wallet = this.block.block.type;
                wallet.wallet.type.set(val.account, val.wallet?.type);
            },
        },
        walletDeps: {
            immediate: true,
            deep: true,
            async handler(val) {
                if (val.type) {
                    let wallet = await walletProvider.get(val.type, val.parameters);
                    // We don't want Vue to pollute this
                    Object.freeze(wallet);
                    this.clearWalletListener();
                    this.walletHolder.wallet = wallet;
                    wallet.events.on("connection", this.walletEvent);
                    await wallet.checkConnection();
                }
                else {
                    this.clearWalletListener();
                    this.walletHolder.wallet = null;
                }
            },
        },
    },
    methods: {
        ...mapMutations("dumbapp", ["set_wallet_type"]),
        walletEvent(event) {
            this.walletHolder.account = event.account;
            this.walletHolder.state = event.state;
            this.walletHolder.chainId = event.chainId;
        },
        selectWallet(type) {
            if (this.disableWallet === type.type) {
                return;
            }
            this.set_wallet_type(type.type);
            this.showWalletSelect = false;
        },
        onValue(value) {
            this.defaultWallet = value.defaultWallet;
        },
        onLayout() {
            if (this.walletHolder.account && this.block.block?.type?.variable) {
                this.block.block.type.variable.set(this.walletHolder.account);
            }
        },
        clearWalletListener() {
            let wallet = this.walletHolder.wallet;
            if (wallet) {
                wallet.events.off("connection", this.walletEvent);
            }
        },
    },
    beforeDestroy() {
        this.clearWalletListener();
    },
};
