export default {
    name: "SettingData",
    props: {
        label: String,
        value: [String, Number, Boolean]
    },
    data() {
        return {};
    },
};
