import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default {
    name: "RepeatChildView",
    mixins: [BlockViewMixin],
    inject: ["editorState"],
    data() {
        return {
            layout: "list",
            show: null,
            showEmpty: false,
        };
    },
    computed: {
        visible() {
            return this.show !== false || this.editorState.editing;
        },
    },
    methods: {
        onValue(value) {
            if (typeof value === "string") {
                this.layout = value || "list";
            }
            else {
                this.show = value;
            }
        },
        onLayout(layout) {
            this.showEmpty = layout.length === 0;
        },
    },
};
