import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import Clipboard from "@/components/Clipboard.vue";
export default {
    name: "ContractBlockView",
    components: { Clipboard },
    mixins: [BlockViewMixin],
    data() {
        return {
            value: null,
        };
    },
    methods: {
        onValue(value) {
            this.value = value;
        },
    },
};
