import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default {
    name: "ImageBlockView",
    mixins: [BlockViewMixin],
    data() {
        return {
            value: null,
            layout: "normal"
        };
    },
    computed: {
        src() {
            if (this.value) {
                return this.value;
            }
            // Transparent image
            return "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
        }
    },
    methods: {
        onValue(value) {
            this.value = value.src;
            if (this.layout !== value.layout) {
                this.layout = value.layout;
            }
        },
    },
};
