import { defineComponent } from "vue";
import Markdown from "@/components/Markdown.vue";
import { markdownTemplate } from "@/components/palette/template/markdown";
import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default defineComponent({
    name: "TemplateBlockView",
    components: { Markdown },
    mixins: [BlockViewMixin],
    data() {
        return {
            value: null,
            invalid: false
        };
    },
    methods: {
        onValue(value) {
            if (value?.value !== null) {
                if (value.value !== this.value) {
                    this.invalid = false;
                    this.value = value.value;
                    this.$refs.content.innerHTML = markdownTemplate(value.value);
                }
            }
            else {
                this.invalid = true;
            }
        }
    },
});
