import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import ProgressCircle from "@/components/ProgressCircle.vue";
export default {
    name: "SpinnerBlockView",
    components: { ProgressCircle },
    mixins: [BlockViewMixin],
    inject: ["editorState"],
    data() {
        return {
            show: null,
        };
    },
    computed: {
        showing() {
            return this.editorState?.editing || this.show;
        },
        still() {
            return this.editorState?.editing;
        }
    },
    methods: {
        onValue(value) {
            this.show = value.value;
        },
    },
};
