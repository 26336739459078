import { defineComponent } from "vue";
export default defineComponent({
    name: "PageTab",
    props: {
        page: {
            type: Object,
        },
        selected: Boolean
    },
    data() {
        return {
            name: "",
            layout: "modal",
        };
    },
    watch: {
        page: {
            immediate: true,
            handler(val, old) {
                if (old !== val) {
                    old?.offSpec?.(this.onSpec);
                    this.name = val?.name || "";
                    val?.onSpec?.(this.onSpec);
                }
            },
        },
    },
    methods: {
        onSpec(spec) {
            this.name = spec.name;
            this.layout = spec.layout || "modal";
        },
    },
    beforeDestroy() {
        this.page?.offSpec?.(this.onSpec);
    },
});
