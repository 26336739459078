import PaletteEditorDialogMixin from "@/components/palette/settings/PaletteEditorDialogMixin";
export default {
    name: "PaletteSaveAs",
    mixins: [PaletteEditorDialogMixin],
    data() {
        return {
            saveAsName: "",
            saveAsValid: false,
            saveAsErrors: [],
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
    },
    watch: {
        show(val, old) {
            if (val && !old) {
                this.saveAsName = this.name !== "new" ? this.name : "";
            }
        },
        saveAsName(val) {
            if (/^[a-z0-9-]{4,}$/.test(val)) {
                let api = this.api;
                api.palette.availability(val).then((it) => {
                    if (this.saveAsName === val) {
                        switch (it) {
                            case "available":
                                this.saveAsValid = true;
                                this.saveAsErrors = [];
                                break;
                            case "unavailable":
                                this.saveAsValid = false;
                                this.saveAsErrors = ["Name has already been taken."];
                                break;
                            case "owned":
                                this.saveAsValid = true;
                                this.saveAsErrors = [
                                    "You already have a palette with that name, saving will overwrite it.",
                                ];
                                break;
                        }
                    }
                });
            }
            else {
                this.saveAsValid = false;
                this.saveAsErrors = [];
            }
        },
    },
    methods: {
        saveAs() {
            if (this.saveAsValid) {
                const saveState = this.saveState;
                let name = this.saveAsName;
                Promise.all(saveState.events.emit("saveAs", name))
                    .then(() => {
                    this.$toasted.show(`<div>Saved palette as <strong>${name}</strong></div>`, {
                        duration: 5000,
                        icon: "check_circle",
                        className: "confirmed",
                    });
                    this.showDialog = false;
                })
                    .catch((err) => {
                    console.error(err);
                    this.saveAsErrors = [err.message];
                });
            }
        },
    },
};
