import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import { debounce } from "@blockwell/util";
import equals from "fast-deep-equal";
export default {
    name: "MappingSetting",
    components: { LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: Array,
        },
    },
    data() {
        return {
            values: [],
        };
    },
    computed: {
        keys() {
            if (this.setting.keys) {
                return this.setting.keys;
            }
            return ["from", "to"];
        },
    },
    watch: {
        value: {
            deep: true,
            immediate: true,
            handler(val) {
                let mapped = val.map((it) => {
                    return {
                        from: it[this.keys[0]],
                        to: it[this.keys[1]],
                    };
                });
                if (!equals(this.values, mapped)) {
                    this.values = mapped;
                }
            },
        },
        values: {
            deep: true,
            handler: debounce(function (val) {
                let emit = val.map((it) => {
                    let mapped = {};
                    mapped[this.keys[0]] = it.from;
                    mapped[this.keys[1]] = it.to;
                    return mapped;
                });
                this.emitSetting(emit);
            }, 200),
        },
    },
    methods: {
        addRow() {
            this.values.push({
                from: this.setting.defaultName
                    ? this.setting.defaultName(this.values.length)
                    : this.values.length.toString(),
                to: "",
            });
        },
        removeRow(index) {
            this.values.splice(index, 1);
        },
        enterPress(side, index, event) {
            let form = this.$refs.mappings;
            let inputs = form.querySelectorAll(`.mapping-${side} input`);
            let last = index === inputs.length - 1;
            event.preventDefault();
            if (last) {
                this.addRow();
            }
            this.$nextTick(() => {
                let form = this.$refs.mappings;
                let inputs = form.querySelectorAll(`.mapping-${side} input`);
                let input = inputs[index + 1];
                input.focus();
            });
        },
        onPaste(side, index, event) {
            // @ts-ignore
            let clip = event.clipboardData || window.clipboardData;
            let paste = clip.getData("text");
            if (/\n/.test(paste)) {
                event.preventDefault();
                this.$nextTick(() => {
                    let values = paste.split(/\n/);
                    // fix indexes
                    let i = index;
                    for (let it of values) {
                        if (i === this.values.length) {
                            let row = {
                                from: this.setting.defaultName
                                    ? this.setting.defaultName(this.values.length)
                                    : this.values.length.toString(),
                                to: "",
                            };
                            row[side] = it;
                            this.values.push(row);
                        }
                        else {
                            this.values[i][side] = it;
                        }
                        i++;
                    }
                });
            }
        },
    },
};
