export default {
    name: "SelectField",
    props: {
        label: String,
        value: String,
        options: {
            type: Array,
        },
    },
    data() {
        return {
            val: this.value,
        };
    },
    watch: {
        val(val) {
            this.$emit("input", val);
        },
        value(val) {
            this.val = val;
        },
    },
};
