import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import { blockNamedContexts } from "@blockwell/palette";
export default {
    name: "DefinitionSetting",
    components: { NameFind, LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: Object,
        },
    },
    data() {
        return {
            name: this.value?.name || this.setting.default?.name || "",
            ctx: this.value?.ctx || this.setting.default?.ctx || "",
        };
    },
    computed: {},
    watch: {
        name(val) {
            let config = {
                name: val,
            };
            if (this.ctx) {
                config.ctx = this.ctx;
            }
            this.emitSetting(config);
        },
        ctx(val) {
            let config = {
                name: this.name,
            };
            if (val) {
                config.ctx = val;
            }
            this.emitSetting(config);
        },
    },
    methods: {
        source() {
            return (q, populate) => {
                let query = q?.toLowerCase();
                let vars = blockNamedContexts(this.wrap.block);
                if (query) {
                    vars = vars.filter((it) => it.context.name.startsWith(query));
                }
                populate(vars.map(({ context, block, name }) => {
                    return {
                        name,
                        ownerKey: context.ownerKey,
                        blockType: block.type.type,
                    };
                }));
            };
        },
    },
};
