import css from '!!raw-loader!./mykai.css';

export default function mykai(ace) {
    ace.define("ace/theme/mykai", ["require", "exports", "module", "ace/lib/dom"], function (req, exp, mod) {

        exp.isDark = true;
        exp.cssClass = "ace-mykai";
        exp.cssText = css;

        const dom = req("../lib/dom");
        dom.importCssString(exp.cssText, exp.cssClass);
    });
}
