import dayjs from "dayjs";
import PaletteVersionEntry from "@/components/palette/settings/PaletteVersionEntry.vue";
import PaletteEditorDialogMixin from "@/components/palette/settings/PaletteEditorDialogMixin";
export default {
    name: "PaletteVersionsManager",
    components: { PaletteVersionEntry },
    mixins: [PaletteEditorDialogMixin],
    data() {
        return {
            versions: [],
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        localDraft() {
            let saveState = this.saveState;
            if (saveState.localDraft) {
                return dayjs(saveState.localDraft).format("lll");
            }
            return null;
        },
        draft() {
            let saveState = this.saveState;
            if (saveState.draftTimestamp) {
                return dayjs(saveState.draftTimestamp).format("lll");
            }
            return null;
        },
        current() {
            let saveState = this.saveState;
            if (saveState.version > 0) {
                return dayjs(saveState.version).format("lll");
            }
            return null;
        },
    },
    mounted() {
        let api = this.api.palette;
        api.getPaletteVersions(this.name).then((res) => {
            this.versions = res;
        });
    },
};
