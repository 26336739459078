import BigNumber from "bignumber.js";
export default {
    name: "SimpleFuelBalance",
    props: {
        balance: BigNumber,
        symbol: String
    },
    data() {
        return {};
    },
    computed: {
        balanceWhole() {
            return this.balance.dp(0, BigNumber.ROUND_FLOOR).toFormat();
        },
        balanceDecimals() {
            let decimals = this.balance.mod(1);
            if (decimals.gt(0)) {
                return decimals.toString(10).slice(2, 4);
            }
            return null;
        },
    }
};
