import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import ContractSettingMixin from "@/components/palette/settings/types/DynamicContextMixin";
export default {
    name: "ContractSetting",
    components: { VariableSetting, SelectField, Info },
    mixins: [SettingMixin, ContractSettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    computed: {
        variableSetting() {
            return {
                ...this.setting,
                type: "var",
                variableType: "contract",
            };
        },
    },
    methods: {
        resolved(val) {
            if (val?.type === "contract" && this.dynamicContext) {
                this.dynamicContext.contract = val.value;
            }
        },
    },
};
