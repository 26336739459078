import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import Cleave from "cleave.js";
export default {
    name: "InputBlockView",
    mixins: [BlockViewMixin],
    data() {
        return {
            label: null,
            help: null,
            type: "text",
            cleave: null,
            rawValue: "",
            onCleave: null,
            observer: null,
        };
    },
    computed: {},
    watch: {
        type: {
            immediate: true,
            handler(val, old) {
                if (val === "currency" && !this.cleave) {
                    this.cleaveSetup();
                }
                else if (val !== "currency" && this.cleave) {
                    this.destroyCleave();
                }
            },
        },
    },
    methods: {
        onValue(value) {
            this.help = value.help || null;
            this.label = value.label || null;
            this.type = value.type || "text";
        },
        input(ev) {
            let target = ev.target;
            let rawValue = target.value;
            this.rawValue = rawValue;
            let block = this.block.block.type;
            this.$nextTick(() => {
                block.setValue(rawValue);
            });
        },
        cleaveInput(event) {
            let rawValue = this.cleave.getRawValue();
            this.rawValue = rawValue;
            let block = this.block.block.type;
            this.$nextTick(() => {
                block.setValue(rawValue);
            });
        },
        cleaveSetup() {
            this.$nextTick(() => {
                this.cleave = new Cleave(this.$refs.input, {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    delimiter: "\u00A0",
                    numeralDecimalScale: 18,
                });
                if (this.onCleave) {
                    this.cleave.setRawValue(this.onCleave);
                    this.onCleave = null;
                }
                // this.observer = new ResizeObserver((entries) => {
                //     const { width } = entries[0].contentRect;
                //
                //     if (this.$refs.input) {
                //         this.$refs.input.style = `padding-right: ${width + 10}px`;
                //     }
                // });
            });
        },
        destroyCleave() {
            this.cleave?.destroy();
            this.cleave = null;
            this.observer?.disconnect();
            this.observer = null;
        },
    },
    destroyed() {
        this.destroyCleave();
    },
};
