import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import EditorSettingPopper from "@/components/palette/settings/types/EditorSettingPopper.vue";
import EditorSettingInline from "@/components/palette/settings/types/components/EditorSettingInline.vue";
export default {
    name: "EditorSetting",
    components: { EditorSettingInline, EditorSettingPopper, LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin],
    inject: ["editorState"],
    props: {
        setting: {
            type: Object
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            show: false,
            template: this.value?.param?.value || "",
            reference: null
        };
    },
    watch: {
        template(value) {
            this.emitSetting({
                type: "const",
                param: {
                    value
                }
            });
        }
    },
    methods: {
        open() {
            this.reference = this.wrap.block.element;
            this.show = true;
        }
    }
};
