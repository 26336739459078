import PaletteBlockMixin from "@/components/palette/PaletteBlockMixin";
export default {
    name: "PaletteViewerBlockView",
    mixins: [PaletteBlockMixin],
    data() {
        return {
            key: null,
            canHaveChildren: false,
            filtered: null,
        };
    },
    watch: {
        block: { immediate: true, handler(block) {
                if (block && block.block.key !== this.key) {
                    this.key = block.block.key;
                    if (block.block.canHaveChildren()) {
                        this.canHaveChildren = true;
                        block.block.onLayout(this.layout);
                    }
                    else {
                        this.canHaveChildren = false;
                    }
                    block.block.element = this.$el;
                }
            } }
    },
    methods: {
        layout(children) {
            let filtered = [];
            for (let child of children) {
                if (child.visible === false) {
                    continue;
                }
                if (child.block.type.type === "repeat-child" && child.block.layout.length > 0) {
                    filtered = filtered.concat(child.block.layout);
                }
                else {
                    filtered.push(child);
                }
            }
            if (filtered.length > 0 || this.filtered?.length > 0) {
                this.filtered = filtered;
            }
        },
    },
};
