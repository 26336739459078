import { blockEmitPerf, blockRenderPerf, } from "@blockwell/palette";
import { ChainMetrics } from "@blockwell/chain-client";
import BlockSettings from "@/components/palette/settings/BlockSettings.vue";
import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import BlockVariables from "@/components/palette/settings/BlockVariables.vue";
import PaletteBlocks from "@/components/palette/settings/PaletteBlocks.vue";
import BlockPrimarySetting from "@/components/palette/settings/block/BlockPrimarySetting.vue";
import { clone } from "remeda";
import BlockStyle from "@/components/palette/settings/style/BlockStyle.vue";
import ColorTheme from "@/components/palette/settings/color/ColorTheme.vue";
import { tinycolor } from "@ctrl/tinycolor";
import { sum } from "rambdax";
import { variableDepPerf, variableValPerf } from "@blockwell/variables";
export default {
    name: "PaletteSettings",
    components: {
        ColorTheme,
        BlockStyle,
        BlockPrimarySetting,
        PaletteBlocks,
        BlockVariables,
        RadioTabs,
        BlockSettings,
    },
    inject: ["editorState"],
    provide() {
        return {
            manager: this.manager,
        };
    },
    props: {
        manager: {
            type: Object,
        },
        block: Number,
    },
    data() {
        return {
            tab: this.editorState.tab,
            spec: null,
            wrap: null,
            settings: [],
            primarySetting: null,
            styles: (this.manager?.style?.getStyles() || []),
            styleElements: [],
            colorElement: null,
            dashColor: "rgba(140, 139, 139, 0.3)",
        };
    },
    computed: {},
    watch: {
        manager: {
            immediate: true,
            handler(manager, old) {
                if (old) {
                    old.style.events.off("add", this.styleAdded);
                    old.style.events.off("update", this.styleUpdated);
                    old.color.events.off("update", this.colorUpdated);
                }
                if (manager) {
                    manager.style.events.on("add", this.styleAdded);
                    manager.style.events.on("update", this.styleUpdated);
                    manager.color.events.on("update", this.colorUpdated);
                }
            },
        },
        block: {
            immediate: true,
            handler(val) {
                if (val) {
                    let wrap = this.manager.registry.blocks.getWrap(val);
                    this.wrap = wrap;
                    if (wrap?.block) {
                        let spec = clone(wrap.block.spec);
                        this.spec = spec;
                    }
                    else {
                        this.spec = null;
                    }
                }
                else {
                    this.wrap = null;
                    this.spec = null;
                }
            },
        },
        "spec.type": {
            immediate: true,
            handler(val) {
                if (val) {
                    this.settings = this.manager.registry.blocks.types.settings(val);
                    this.primarySetting = this.manager.registry.blocks.types.primarySetting(val);
                }
            },
        },
        tab(val) {
            this.editorState.tab = val;
        },
        "editorState.tab"(val) {
            if (val !== this.tab) {
                this.tab = val;
            }
        },
        styles: {
            immediate: true,
            handler(val) {
                let els = this.styleElements;
                let i = 0;
                for (let it of val) {
                    let el;
                    if (els.length > i) {
                        el = els[i];
                    }
                    else {
                        el = document.createElement("style");
                        document.head.appendChild(el);
                        this.styleElements.push(el);
                    }
                    el.innerHTML = it.toCss(".palette-container div.palette-wrapper ");
                    ++i;
                }
                if (els.length > i + 1) {
                    let rem = els.splice(i + 1);
                    for (let it of rem) {
                        it.remove();
                    }
                }
            },
        },
    },
    methods: {
        styleAdded({ set, index }) {
            let el = document.createElement("style");
            document.head.insertBefore(el, this.styleElements[index]);
            this.styles.splice(index, 0, set);
        },
        styleUpdated(style) {
            let index = this.styles.indexOf(style.set);
            let el = this.styleElements[index];
            if (el) {
                el.innerHTML = style.set.toCss(".palette-container div.palette-wrapper ");
            }
        },
        colorUpdated(colors) {
            if (!this.colorElement) {
                this.colorElement = document.createElement("style");
                document.head.appendChild(this.colorElement);
            }
            this.colorElement.innerHTML = colors;
            const manager = this.manager;
            let brightness = tinycolor(manager.color.colors.background.base).getBrightness();
            let outline;
            if (brightness > 128) {
                outline = brightness - 30;
            }
            else if (brightness > 32) {
                outline = brightness + 20;
            }
            else {
                outline = 42;
            }
            outline = Math.round(outline);
            this.colorElement.innerHTML += ` .palette-editor .palette-block-view { outline-color: rgb(${outline},${outline},${outline}); }`;
        },
    },
    mounted() {
        this.editorState.style?.remove();
        function renderMetrics(perf) {
            return Object.fromEntries(Object.entries(perf).map(([key, val]) => {
                // Exclude first item because that can have initialization/loading costs that aren't
                // representative of performance.
                return [key, [sum(val.slice(1)) / (val.length - 1), sum(val.slice(1)), val.length - 1]];
            }));
        }
        console.log("performance metrics", {
            dependencies: renderMetrics(variableDepPerf),
            values: renderMetrics(variableValPerf),
            blockRender: renderMetrics(blockRenderPerf),
            blockEmit: renderMetrics(blockEmitPerf),
            chain: ChainMetrics.values
        });
    },
    beforeDestroy() {
        const manager = this.manager;
        if (manager) {
            if (this.editorState.style) {
                this.editorState.style.innerHTML =
                    manager.style.renderStylesheet(".palette-wrapper") + "\n\n" + manager.color.toCss();
                document.head.appendChild(this.editorState.style);
            }
            for (let el of this.styleElements) {
                el.remove();
            }
            manager.style.events.off("add", this.styleAdded);
            manager.style.events.off("update", this.styleUpdated);
        }
        if (this.colorElement) {
            this.colorElement.remove();
        }
    },
};
