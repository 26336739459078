import PaletteEditorDialogMixin from "@/components/palette/settings/PaletteEditorDialogMixin";
import CodeBlock from "@/components/CodeBlock.vue";
export default {
    name: "PaletteExport",
    components: { CodeBlock },
    mixins: [PaletteEditorDialogMixin],
    data() {
        return {
            exportCode: "",
        };
    },
    mounted() {
        this.exportCode = JSON.stringify(this.manager.toJSON(), null, 2);
    },
};
