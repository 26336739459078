import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import AbiSetting from "@/components/palette/settings/types/AbiSetting.vue";
export default {
    name: "EventsSetting",
    components: { AbiSetting, NameFind },
    mixins: [SettingMixin],
    props: {
        setting: {
            type: Object,
        },
        value: {
            type: [Array, String]
        },
    },
    data() {
        return {};
    },
    computed: {
        array() {
            if (Array.isArray(this.value)) {
                return this.value;
            }
            else if (this.value) {
                return [this.value];
            }
            else {
                return [];
            }
        },
        showAdd() {
            return !this.array.includes("*");
        }
    },
    methods: {
        removeEvent(index) {
            let array = [...this.array];
            array.splice(index, 1);
            this.emitSetting(array);
        },
        addEvent() {
            this.emitSetting([...this.array, ""]);
        },
        input(index, input) {
            let array = [...this.array];
            array[index] = Object.values(input)[0];
            this.emitSetting(array);
        }
    }
};
