import { path } from "rambdax/immutable";
export default {
    name: "PaletteMenuItem",
    inject: ["editorState", "saveState"],
    props: {
        item: {
            type: Object,
        },
    },
    data() {
        return {
            checked: "option" in this.item ? path(this.item.option, this.$store.state.palette.options) : false
        };
    },
    computed: {
        isEnabled() {
            const item = this.item;
            const saveState = this.saveState;
            if ("action" in item) {
                if (item.enabled) {
                    return item.enabled(saveState);
                }
            }
            return true;
        }
    },
    methods: {
        action(item) {
            this.$emit("action", item.action);
        },
        option(ev, item) {
            let input = ev.target;
            this.$emit("option", item.option, input.checked);
        }
    }
};
