import Vue from "vue";
import Mailchimp from "../../components/Mailchimp.vue";
export default Vue.extend({
    name: "PaletteFooter",
    components: { Mailchimp },
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
});
