import PopperMixin from "@/components/PopperMixin";
export default {
    name: "SettingsDropdown",
    mixins: [PopperMixin],
    props: {
        show: Boolean,
        menu: {
            type: Array,
        },
    },
    data() {
        return {
            useFlip: false,
            placementOverride: "bottom",
        };
    },
    computed: {},
    methods: {
        click(name) {
            if (name && name !== "divider") {
                this.$emit("action", name);
                this.close();
            }
        },
    },
};
