import { isArrayResponse, isStructResponse } from "@blockwell/chain-client";
export default {
    name: "DumbappValueView",
    props: {
        value: {
            type: [Object, Array, String, Boolean, Number]
        },
    },
    methods: {
        isStructResponse: isStructResponse,
        isArrayResponse: isArrayResponse,
        structResponse(value) {
            return Object.entries(value).map(([key, val]) => {
                return { key, val };
            });
        },
        arrayResponse(value) {
            return value.map((it, index) => {
                return {
                    key: (index + 1).toString(),
                    val: it
                };
            });
        }
    },
};
