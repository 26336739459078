import { parseGradientSteps } from "@blockwell/palette";
import Checkboard from "@/components/color/Checkboard.vue";
export default {
    name: "BackgroundStackPreview",
    components: { Checkboard },
    inject: ["manager"],
    props: {
        layer: {
            type: Object
        },
        index: Number
    },
    data() {
        return {};
    },
    computed: {
        func() {
            let image = this.layer.get("background-image")?.value || "url()";
            let index = image.indexOf("(");
            let name = image.slice(0, index);
            let content = image.slice(index + 1, -1).trim();
            let deg = "180";
            if (name.includes("gradient")) {
                let match = /^\s*(\d+)deg\s*,(.*)/.exec(content);
                if (match) {
                    deg = match[1];
                    content = match[2];
                }
            }
            return {
                name,
                content,
                deg,
            };
        },
        preview() {
            let style = {};
            if (this.func.deg) {
                let steps = parseGradientSteps(this.func.content);
                let gradient = steps.map((it) => `${this.resolveValue(it.color)} ${it.pos}%`).join(",");
                style["background-image"] = `${this.func.name}(${this.func.deg}deg, ${gradient})`;
            }
            else {
                style["background-image"] = this.layer.get("background-image")?.value;
            }
            return style;
        },
        type() {
            let value = this.layer.get("background-image")?.value;
            if (!value || value.startsWith("url")) {
                return "Image";
            }
            else {
                return "Gradient";
            }
        },
        repeat() {
            let value = this.layer.get("background-repeat")?.value;
            if (!value || value === "repeat") {
                return "repeats";
            }
            else if (value === "repeat-x") {
                return "repeats horizontal";
            }
            else if (value === "repeat-y") {
                return "repeats vertical";
            }
        },
        size() {
            let val = this.layer.get("background-size");
            if (val) {
                if (val.fixedValue) {
                    return val.fixedValue;
                }
                else if (val.unit) {
                    return val.value + val.unit;
                }
                else {
                    return val.value;
                }
            }
            return "";
        }
    },
    methods: {
        resolveValue(value) {
            if (this.manager) {
                const colors = this.manager.color;
                return colors.resolveColor(value);
            }
            return value;
        },
    }
};
