import { defineComponent } from "vue";
import Markdown from "@/components/Markdown.vue";
import DumbappWallet from "@/components/dumbapp/submit/DumbappWallet.vue";
import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
import BigNumber from "bignumber.js";
export default defineComponent({
    name: "BalanceBlockView",
    components: { DumbappWallet, Markdown },
    provide() {
        return {
            provider: null
        };
    },
    mixins: [BlockViewMixin],
    data() {
        return {
            value: null,
            name: "",
            symbol: ""
        };
    },
    computed: {
        balance() {
            return new BigNumber(this.value);
        },
        valid() {
            return !this.balance.isNaN();
        },
        balanceWhole() {
            return this.balance.dp(0, BigNumber.ROUND_FLOOR).toFormat();
        },
        balanceDecimals() {
            let decimals = this.balance.mod(1);
            if (decimals.gt(0)) {
                return decimals.toString(10).slice(2);
            }
            return null;
        }
    },
    methods: {
        onValue(value) {
            this.value = value?.value || "-";
            this.name = value?.token?.name;
            this.symbol = value?.token?.parameters?.symbol;
        }
    }
});
