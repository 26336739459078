import { clone } from "remeda";
export default {
    props: {
        block: {
            type: Object,
        },
    },
    data() {
        return {
            addedClasses: (this.block.block.spec.classes || []),
            interactions: null,
            interactionListeners: [],
        };
    },
    computed: {
        baseClasses() {
            switch (this.block.block.type.type) {
                case "repeat":
                    return ["palette-repeat-block-view", "palette-block-view"];
                default:
                    return ["palette-block-view"];
            }
        },
    },
    watch: {
        block(val, old) {
            if (val?.block !== old?.block) {
                if (old) {
                    old.block.offValue(this.onValueInteractions);
                }
                val.block.onValue(this.onValueInteractions);
                if (this.onLayout) {
                    if (old) {
                        old.block.offLayout(this.onLayout);
                    }
                    val.block.onLayout(this.onLayout);
                }
            }
        },
    },
    methods: {
        onValueInteractions(val) {
            if (this.onValue) {
                this.onValue(val);
            }
            if (val?.interactions?.length > 0) {
                this.interactions = clone(val.interactions);
                let types = new Set();
                for (let it of val.interactions) {
                    if (it?.type) {
                        types.add(it.type);
                    }
                }
                this.reconcileInteractions(Array.from(types));
            }
            else {
                this.interactions = null;
                this.reconcileInteractions([]);
            }
        },
        typeClass(layout) {
            let inter = this.interactionListeners.map((it) => `block-interaction-${it}`);
            return [
                ...this.baseClasses,
                ...this.block.block.style.classes(layout),
                ...this.addedClasses,
                ...inter,
            ];
        },
        updateClasses() {
            this.addedClasses = this.block.block.spec.classes;
        },
        reconcileInteractions(types) {
            let existing = new Set(this.interactionListeners);
            for (let type of types) {
                if (!existing.has(type)) {
                    switch (type) {
                        case "click":
                            this.$el.addEventListener("click", this.interactionClick);
                            break;
                    }
                }
                else {
                    existing.delete(type);
                }
            }
            for (let type of existing) {
                switch (type) {
                    case "click":
                        this.$el.removeEventListener("click", this.interactionClick);
                        break;
                }
            }
            this.interactionListeners = types;
        },
        interactionClick(ev) {
            ev.stopPropagation();
            for (let it of this.interactions) {
                if (it.type === "click") {
                    for (let action of it.actions) {
                        this.block.block.interaction.interact(action);
                    }
                }
            }
        },
    },
    mounted() {
        this.block.block.onValue(this.onValueInteractions);
        if (this.onLayout) {
            this.block.block.onLayout(this.onLayout);
        }
        this.block.block.style.onClasses(this.updateClasses);
    },
    beforeDestroy() {
        // There are cases where the whole block hierarchy is already fully destroyed, hence
        // the check for this.block?.block
        if (this.block?.block) {
            this.block.block.offValue(this.onValueInteractions);
            if (this.onLayout) {
                this.block.block.offLayout(this.onLayout);
            }
            this.block.block.style.offClasses(this.updateClasses);
        }
    },
};
