import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import NumberStyleSetting from "@/components/palette/settings/style/types/NumberStyleSetting.vue";
import { debounce } from "@blockwell/util";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "SliderStyleSetting",
    components: { StyleLabel, NumberStyleSetting },
    mixins: [StyleSettingMixin],
    data() {
        return {
            moveX: 0,
            moveY: 0,
        };
    },
    computed: {
        circle() {
            return this.unit === "deg";
        },
        circleStyle() {
            if (this.unit === "deg") {
                let angle = parseInt(this.value);
                if (isNaN(angle)) {
                    if (this.item.prop.default) {
                        angle = parseInt(this.item.prop.default);
                    }
                    else {
                        angle = 0;
                    }
                }
                return {
                    transform: `rotate(${angle}deg)`,
                };
            }
        },
    },
    methods: {
        slider: debounce(function (ev) {
            let el = ev.target;
            this.entry.update({ value: el.value });
            this.updateFromEntry();
        }, 16, { maxWait: 32 }),
        updateNumber(entry) {
            this.entry.update(entry.value);
            this.updateFromEntry();
        },
        updateDirection(x, y) {
            let rad = Math.atan2(x - this.moveX, this.moveY - y);
            let deg = Math.round((180 * rad) / Math.PI);
            deg = (deg + 360) % 360;
            this.entry.update({ value: deg.toString() });
            this.updateFromEntry();
        },
        mousedown(ev) {
            let el = ev.target;
            let bound = el.getBoundingClientRect();
            this.moveX = bound.x + bound.width / 2;
            this.moveY = bound.y + bound.height / 2;
            this.updateDirection(ev.clientX, ev.clientY);
            ev.preventDefault();
            window.addEventListener("mouseup", this.mouseup, { once: true });
            window.addEventListener("mousemove", this.mousemove);
        },
        mousemove(ev) {
            if (this.moveX) {
                this.updateDirection(ev.clientX, ev.clientY);
            }
        },
        mouseup(ev) {
            this.moveX = 0;
            window.removeEventListener("mousemove", this.mousemove);
        },
    },
    beforeDestroy() {
        window.removeEventListener("mousemove", this.mousemove);
    },
};
