import Checkboard from "@/components/color/Checkboard.vue";
import BigNumber from "bignumber.js";
import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
const units = ["Bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
const getBytes = (bytes) => {
    if (!bytes) {
        return "0 Bytes";
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return new BigNumber(bytes / Math.pow(1024, i)).sd(2).toString(10) + " " + units[i];
};
export default {
    name: "MediaFileView",
    components: { SettingsPopper, Checkboard },
    props: {
        file: {
            type: Object,
        },
        layout: {
            type: String,
            default: "card",
        },
        noSettings: Boolean,
    },
    data() {
        return {};
    },
    computed: {
        name() {
            return this.file?.name;
        },
        format() {
            return this.file?.metadata?.format;
        },
        dimensions() {
            let width = this.file?.metadata?.width;
            let height = this.file?.metadata?.height;
            if (width && height) {
                return `${width} x ${height}`;
            }
            return "";
        },
        src() {
            let storage = this.file?.storage?.[0];
            if (storage?.location) {
                return storage.location;
            }
            else {
                return null;
            }
        },
        size() {
            return getBytes(this.file?.metadata.size);
        },
    },
    methods: {
        dragStart(ev) {
            let media = JSON.stringify(this.file);
            ev.dataTransfer.setData("text/media", media);
            ev.dataTransfer.setData("text/spec", JSON.stringify({
                type: "image",
                param: {
                    uri: {
                        type: "const",
                        param: {
                            value: this.src,
                        },
                    },
                },
            }));
            this.$emit("drag");
        },
        dragEnd() {
        },
    },
};
