import { defineComponent } from "vue";
import BlockViewMixin from "@/components/palette/blocks/BlockViewMixin";
export default defineComponent({
    name: "LayoutBlockView",
    mixins: [BlockViewMixin],
    data() {
        return {
            layout: "list",
        };
    },
    computed: {
        classes() {
            return [...this.typeClass(this.layout)];
        },
    },
    methods: {
        onValue(value) {
            if (this.layout !== value.layout) {
                this.layout = value.layout;
            }
        },
    },
});
