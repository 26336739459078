import Info from "@/components/Info.vue";
import { capitalCase } from "change-case";
export default {
    name: "UnknownSetting",
    components: { Info },
    props: {
        paramKey: String,
        wrap: {
            type: Object,
        },
        setting: {
            type: Object,
        },
        value: {
            type: [Object, Number, String, Boolean, Array],
        },
    },
    data() {
        return {};
    },
    computed: {
        title() {
            return this.setting.title || capitalCase(this.paramKey);
        },
    }
};
