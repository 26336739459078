import PaletteTreeVariable from "@/components/palette/settings/PaletteTreeVariable.vue";
import { reverse } from "remeda";
export default {
    name: "PaletteTreeContext",
    components: { PaletteTreeVariable },
    inject: ["editorState"],
    props: {
        context: {
            type: Object,
        },
        manager: {
            type: Object,
        },
        depth: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            children: [],
            variables: [],
            value: null,
        };
    },
    computed: {
        key() {
            return this.context.context.ownerKey;
        },
        block() {
            return this.manager.registry.blocks.get(this.context.context.ownerKey);
        },
        repeatChild() {
            return this.block.type.type === "repeat-child";
        },
        repeatCount() {
            if (this.block.type.type === "repeat") {
                return this.block.children.length;
            }
            return 0;
        },
        selectable() {
            return !this.repeatChild;
        },
        filtered() {
            if (!this.editorState.treeOptions.showRepeats) {
                return this.children.filter((it) => !it.context.repeated);
            }
            return this.children;
        },
    },
    watch: {
        context: {
            immediate: true,
            handler(context, old) {
                if (context && context !== old) {
                    context.context.onLayout(this.onLayout);
                }
                if (old && old.context !== context?.context) {
                    old.context.offLayout(this.onLayout);
                }
            },
        },
    },
    methods: {
        onLayout({ children, variables, }) {
            this.children = children;
            this.variables = reverse(variables.filter((it) => !!it.name && it.varType !== "null"));
        },
        onClick() {
            this.editorState.selectKey = this.key;
        },
    },
    beforeDestroy() {
        this.context.context.offLayout(this.onLayout);
    },
};
