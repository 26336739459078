import UnknownSetting from "@/components/palette/settings/types/UnknownSetting.vue";
import VariableSetting from "@/components/palette/settings/types/VariableSetting.vue";
import SelectSetting from "@/components/palette/settings/types/SelectSetting.vue";
import ContractSetting from "@/components/palette/settings/types/ContractSetting.vue";
import AbiSetting from "@/components/palette/settings/types/AbiSetting.vue";
import ArgumentsSetting from "@/components/palette/settings/types/ArgumentsSetting.vue";
import LiteralSetting from "@/components/palette/settings/types/LiteralSetting.vue";
import DefinitionSetting from "@/components/palette/settings/types/DefinitionSetting.vue";
import EditorSetting from "@/components/palette/settings/types/EditorSetting.vue";
import FindContract from "@/components/palette/settings/FindContract.vue";
import DumbappSetting from "@/components/palette/settings/types/DumbappSetting.vue";
import EventsSetting from "@/components/palette/settings/types/EventsSetting.vue";
import ToggleSetting from "@/components/palette/settings/types/ToggleSetting.vue";
import MappingSetting from "@/components/palette/settings/types/MappingSetting.vue";
import CollectionSetting from "@/components/palette/settings/types/CollectionSetting.vue";
import ContextSetting from "@/components/palette/settings/types/ContextSetting.vue";
import PageSetting from "@/components/palette/settings/types/PageSetting.vue";
export default {
    components: {
        UnknownSetting,
        VariableSetting,
        ContractSetting,
        SelectSetting,
        AbiSetting,
        ArgumentsSetting,
        LiteralSetting,
        DefinitionSetting,
        EditorSetting,
        FindContract,
        DumbappSetting,
        EventsSetting,
        ToggleSetting,
        MappingSetting,
        CollectionSetting,
        ContextSetting,
    },
    methods: {
        settingComponentBase(setting) {
            switch (setting.type) {
                case "contract":
                    return ContractSetting;
                case "select":
                    return SelectSetting;
                case "var":
                    return VariableSetting;
                case "def":
                    return DefinitionSetting;
                case "editor":
                    return EditorSetting;
                case "array":
                    break;
                case "abi":
                    return AbiSetting;
                case "args":
                    return ArgumentsSetting;
                case "literal":
                    return LiteralSetting;
                case "toggle":
                    return ToggleSetting;
                case "dumbapp":
                    return DumbappSetting;
                case "events":
                    return EventsSetting;
                case "mapping":
                    return MappingSetting;
                case "collection":
                    return CollectionSetting;
                case "context":
                    return ContextSetting;
                case "page":
                    return PageSetting;
            }
            return UnknownSetting;
        },
        extraComponent(dynamicType) {
            switch (dynamicType) {
                case "contract":
                    return FindContract;
            }
            return null;
        },
        showCurrentValue(variableType) {
            switch (variableType) {
                case "literal":
                case "editor":
                case "abi":
                case "decimals":
                case "args":
                    return false;
            }
            return true;
        },
    },
};
