import BaseSettingTypesMixin from "@/components/palette/settings/types/BaseSettingTypesMixin";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import { clone } from "remeda";
import { actionRegistry } from "@blockwell/palette/lib/block/interaction";
export default {
    name: "InteractionConfig",
    components: { NameFind },
    mixins: [BaseSettingTypesMixin],
    inject: ["manager"],
    props: {
        value: {
            type: Object,
        },
        wrap: {
            type: Object,
        },
    },
    data() {
        let manager = this.manager;
        return {
            interactionSetting: {
                type: "select",
                title: "Interaction",
                enum: ["click"],
                default: "click"
            },
            types: actionRegistry.getAll().map((it) => {
                return {
                    type: it.type,
                    label: it.label,
                    description: it.description,
                    name: it.type,
                };
            })
        };
    },
    computed: {
        settings() {
            if (this.value?.type && this.action?.type) {
                let settings = actionRegistry.get(this.action.type).settings;
                let value = this.action;
                return Object.entries(settings).map(([key, val]) => {
                    return {
                        key,
                        setting: Object.freeze(val),
                        value: value.param?.[key],
                    };
                });
            }
            return [];
        },
        action() {
            return this.value?.actions?.[0];
        }
    },
    methods: {
        source(q, populate) {
            populate(this.types);
        },
        typeInput(type) {
            if (this.value.actions?.[0]?.type !== type) {
                let action = { type: type, param: {} };
                let value = clone(this.value);
                value.actions = [action];
                this.$emit("update", value);
            }
        },
        input(update) {
            let value = clone(this.value);
            Object.assign(value.actions[0].param, update);
            this.$emit("update", value);
        }
    },
};
