import Vue from "vue";
import WhitelabelMobileNav from "@/layouts/components/WhitelabelMobileNav.vue";
import PaletteMainHeader from "@/views/palette/PaletteMainHeader.vue";
import PaletteFooter from "@/views/palette/PaletteFooter.vue";
import PaletteViewerFooter from "@/views/palette/PaletteViewerFooter.vue";
import PaletteEditorHeader from "@/components/palette/settings/PaletteEditorHeader.vue";
export default Vue.extend({
    name: "PaletteLayout",
    components: {
        PaletteEditorHeader,
        PaletteViewerFooter,
        PaletteFooter,
        PaletteMainHeader,
        WhitelabelMobileNav,
    },
    props: {
        icon: {
            type: String,
        },
        external: {
            type: String,
        },
        whitelabelLayout: {
            type: Boolean,
        },
        editorState: {
            type: Object,
        },
        manager: {
            type: Object,
        },
    },
    data() {
        return {};
    },
    computed: {
        iconUrl() {
            if (this.config?.icon) {
                return this.createImageUrl(this.config.icon);
            }
            return null;
        },
    },
    watch: {
        iconUrl(val) {
            let element = document.querySelector("link[rel~='icon']");
            if (val) {
                element.href = val;
            }
            else {
                element.href = "/favicon.ico";
            }
        },
    },
    methods: {
        createImageUrl(url) {
            if (url && /^https:\/\//i.test(url)) {
                return process.env.VUE_APP_API_URL + "img?url=" + encodeURIComponent(url);
            }
        },
    },
    created() { },
    destroyed() { },
});
