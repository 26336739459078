import { isConstantReference, isVariableReference, vals, VariableReference } from "@blockwell/variables";
import { capitalCase } from "change-case";
import Info from "@/components/Info.vue";
import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import { debounce } from "@/lib/vutil";
import CurrentValue from "@/components/palette/settings/CurrentValue.vue";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import { clone } from "remeda";
import DynamicContextMixin from "@/components/palette/settings/types/DynamicContextMixin";
import { blockVariableInfo } from "@blockwell/palette";
export default {
    name: "VariableSetting",
    components: { LiteralSettingInput, CurrentValue, NameFind, RadioTabs, Info },
    mixins: [DynamicContextMixin],
    props: {
        paramKey: String,
        wrap: {
            type: Object
        },
        setting: {
            type: Object
        },
        value: {
            type: Object
        },
        hideTitle: Boolean,
        forceType: {
            type: String
        },
        emptyConstAsNull: Boolean,
        parentIndex: {
            type: [Number, Array]
        },
        autoValue: String,
        autoText: String
    },
    data() {
        let constValue = this.value?.param?.value;
        return {
            type: "var",
            name: this.value?.param?.name || "",
            constValue,
            ownIndex: this.parentIndex,
            currentValue: null,
            errorValue: null,
            removeOnValue: null
        };
    },
    computed: {
        title() {
            return this.setting.title || capitalCase(this.paramKey);
        },
        allowAuto() {
            if (this.autoValue) {
                return "custom";
            }
            let type = this.setting.variableType;
            if (type === "contract" || type === "wallet") {
                return type;
            }
            else if (Array.isArray(type)) {
                if (type[0] === "contract" || type[0] === "wallet") {
                    return type[0];
                }
            }
            return false;
        },
        allowConstant() {
            if (!this.setting.variableType) {
                return true;
            }
            if (Array.isArray(this.setting.variableType)) {
                return this.setting.variableType.includes("value");
            }
            return this.setting.variableType === "value" || this.setting.variableType === "wallet";
        },
        constantType() {
            if (this.forceType) {
                return this.forceType;
            }
            if (this.setting.singleOnly) {
                return "value";
            }
            if (this.setting.arrayOnly) {
                return "array";
            }
            return null;
        },
        typeOptions() {
            let options = [
                { label: "Variable", value: "var", description: "Use existing variable." }
            ];
            if (this.allowAuto) {
                options.unshift({
                    label: "Auto",
                    value: "auto",
                    description: "Automatically use the closest matching value."
                });
            }
            if (this.allowConstant) {
                options.push({
                    label: "Constant",
                    value: "const",
                    description: "Use a preset value."
                });
            }
            return options;
        }
    },
    watch: {
        constValue: {
            deep: true,
            handler(val) {
                if (this.type === "const") {
                    this.emitConst(val);
                }
            }
        },
        type(val, old) {
            if (val !== old) {
                if (val === "var") {
                    if (this.name) {
                        this.emit({
                            type: "var",
                            param: {
                                name: this.name
                            }
                        });
                    }
                }
                else if (val === "auto") {
                    if (this.allowAuto) {
                        if (this.setting.undefinedAuto) {
                            this.emit(null);
                        }
                        else {
                            this.emit({
                                type: "var",
                                param: {
                                    type: this.allowAuto
                                }
                            });
                        }
                    }
                }
                else {
                    this.emitConst(this.constValue);
                }
            }
        },
        value: {
            immediate: true,
            deep: true,
            handler(ref) {
                const block = this.wrap.block;
                const resolver = block.context.resolver;
                if (this.removeOnValue) {
                    this.removeOnValue();
                }
                if (ref) {
                    if (isVariableReference(ref)) {
                        if (this.allowAuto && "type" in ref.param) {
                            this.type = "auto";
                        }
                        else if ("name" in ref.param) {
                            this.type = "var";
                            this.name = ref.param.name;
                        }
                        let resolved = resolver.resolveReference(ref);
                        if (resolved.value instanceof VariableReference) {
                            const variable = resolved.value;
                            const path = resolved.path ? clone(resolved.path) : null;
                            const onValue = (val) => {
                                this.resolved(vals.path(val, path));
                            };
                            variable.value();
                            variable.onValue(onValue);
                            this.removeOnValue = () => variable.offValue(onValue);
                        }
                        else if (resolved?.value) {
                            let value = vals.path(resolved.value.value(), resolved.path);
                            this.resolved(value);
                        }
                        else {
                            this.resolved({ type: "null", value: null });
                        }
                    }
                    else if (isConstantReference(ref)) {
                        this.type = "const";
                        this.constValue = ref.param.value;
                        this.resolved({ type: "value", value: ref.param.value });
                    }
                }
                else {
                    if (this.allowAuto) {
                        this.type = "auto";
                        let resolved = resolver.resolve({
                            type: "var",
                            param: {
                                type: this.allowAuto
                            }
                        });
                        this.resolved(resolved);
                    }
                    else if (this.setting.allowNull) {
                        this.resolved(null);
                    }
                    else if (this.setting.default) {
                        this.type = "const";
                        this.constValue = this.setting.default;
                        this.resolved({ type: "value", value: this.setting.default });
                    }
                }
            }
        },
        name: {
            handler: debounce(function (val) {
                if (val === "") {
                    this.emit(undefined);
                }
                else if (val) {
                    let newRef = {
                        type: "var",
                        param: {
                            name: val
                        }
                    };
                    const block = this.wrap.block;
                    const resolver = block.context.resolver;
                    let resolved = resolver.resolveReference(newRef);
                    if (resolved.value.valueType === "null") {
                        this.errorValue = `No variable found with '${val}'`;
                    }
                    else {
                        this.errorValue = null;
                    }
                    this.emit(newRef);
                }
                else if (this.type === "var") {
                    this.errorValue = `Variable name is required`;
                }
            }, 200)
        },
        parentIndex(val) {
            this.ownIndex = val;
        }
    },
    methods: {
        emitConst(val) {
            if (this.emptyConstAsNull) {
                let empty = val === undefined ||
                    val === null ||
                    val === "" ||
                    (Array.isArray(val) && val.length === 0);
                if (empty) {
                    this.emit(null);
                    return;
                }
            }
            this.emit({
                type: "const",
                param: {
                    value: val
                }
            });
        },
        emit(newRef) {
            let input = {};
            input[this.paramKey] = newRef;
            this.$emit("input", input);
        },
        resolved(val) {
            this.$emit("resolved", val);
            if (!val) {
                this.currentValue = null;
            }
            else {
                switch (val.type) {
                    case "value":
                        this.currentValue = val.value;
                        break;
                    case "nft":
                        this.currentValue = val.value?.collection + " #" + val.value?.tokenId;
                        break;
                    case "wallet":
                        this.currentValue = val.value?.address;
                        break;
                    case "contract":
                        this.currentValue = val.value?.name;
                        break;
                    case "null":
                        this.currentValue = null;
                        break;
                }
            }
            if (this.setting?.addToContext && this.dynamicContext) {
                this.$set(this.dynamicContext, "rawValue", val);
            }
        },
        source() {
            let cached;
            let timeout;
            let varType = this.setting.variableType;
            let types;
            if (varType) {
                if (Array.isArray(varType)) {
                    types = varType;
                }
                else {
                    types = [varType];
                }
            }
            return (q, populate) => {
                let query = q?.toLowerCase();
                if (!cached) {
                    cached = blockVariableInfo(this.wrap.block, types, this.ownIndex).filter((it) => it.valueType !== "Null");
                }
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => (cached = null), 3000);
                let vars = cached;
                if (query) {
                    vars = vars.filter((it) => it.name.startsWith(query) ||
                        it.path.find((part) => part.startsWith(query)));
                }
                populate(vars);
            };
        },
        inputValue(info) {
            return info?.name;
        },
        suggestion(info) {
            if (!info.name) {
                return "";
            }
            if (info) {
                return `<div class="var-name">${info.name}</div>
                    <div class="var-info">
                        <div class="var-type">${info.valueType}</div>
                        <div class="var-value">${info.value || ""}</div>
                    </div>`;
            }
            return "";
        }
    },
    beforeDestroy() {
        if (this.removeOnValue) {
            this.removeOnValue();
            this.removeOnValue = null;
        }
    }
};
