import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "SelectStyleSetting",
    components: { StyleLabel },
    mixins: [StyleSettingMixin],
    data() {
        return {};
    },
    computed: {
        groups() {
            let opts = this.item.prop.options;
            if (opts?.[0]?.group) {
                let grouped = {};
                for (let it of opts) {
                    if (!grouped[it.group]) {
                        grouped[it.group] = [];
                    }
                    grouped[it.group].push(it);
                }
                return Object.entries(grouped).map(([key, val]) => {
                    return {
                        label: key,
                        options: val
                    };
                });
            }
            return null;
        },
        iconClasses() {
            if (this.item?.iconColumns) {
                return "icon-columns icon-columns-" + this.item.iconColumns;
            }
            return "";
        }
    }
};
