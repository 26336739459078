export default {
    name: "PaletteTreeVariable",
    inject: ["editorState"],
    props: {
        variable: {
            type: Object
        },
        manager: {
            type: Object
        },
        owner: Number,
        depth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            value: null
        };
    },
    computed: {
        key() {
            let ref = this.variable.ref;
            return ref.index;
        },
        builtIn() {
            return this.variable.ref.builtIn;
        },
        varType() {
            return this.variable.varType;
        },
        selectable() {
            return true;
        }
    },
    watch: {},
    methods: {
        onClick() {
            if (this.builtIn) {
                this.editorState.selectKey = this.variable.owner;
                this.editorState.tab = "block";
            }
            else if (this.selectable) {
                this.editorState.selectKey = this.owner;
                this.editorState.tab = "vars";
                this.editorState.selectVariable = this.variable.name;
            }
        }
    },
    beforeDestroy() {
    }
};
