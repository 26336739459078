import ColorStyleSetting from "@/components/palette/settings/style/types/ColorStyleSetting.vue";
import SelectStyleSetting from "@/components/palette/settings/style/types/SelectStyleSetting.vue";
import NumberStyleSetting from "@/components/palette/settings/style/types/NumberStyleSetting.vue";
import UnknownStyleSetting from "@/components/palette/settings/style/types/UnknownStyleSetting.vue";
import FileStyleSetting from "@/components/palette/settings/style/types/FileStyleSetting.vue";
import GradientStyleSetting from "@/components/palette/settings/style/types/GradientStyleSetting.vue";
import SliderStyleSetting from "@/components/palette/settings/style/types/SliderStyleSetting.vue";
export default {
    components: {
        ColorStyleSetting,
        SelectStyleSetting,
        NumberStyleSetting,
        UnknownStyleSetting,
        FileStyleSetting,
        GradientStyleSetting,
        SliderStyleSetting,
    },
    methods: {
        singleSettingComponent(item) {
            switch (item.prop.type) {
                case "color":
                    return ColorStyleSetting;
                case "radio":
                    break;
                case "select":
                    return SelectStyleSetting;
                case "file":
                    return FileStyleSetting;
                case "slider":
                    return SliderStyleSetting;
                case "gradient":
                    return GradientStyleSetting;
                case "number":
                    return NumberStyleSetting;
            }
            return UnknownStyleSetting;
        },
    },
};
