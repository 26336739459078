import { createPopper } from "@popperjs/core";
import ResizeObserver from "resize-observer-polyfill";
import AceEditorMixin from "@/components/palette/settings/types/components/AceEditorMixin";
export default {
    name: "EditorSettingPopper",
    mixins: [AceEditorMixin],
    props: {
        show: Boolean,
        reference: { type: [Element, null], required: true },
    },
    data() {
        return {
            showing: false,
        };
    },
    methods: {
        open() {
            let ref = this.reference;
            let modifiers = [
                // {
                //     name: "offset",
                //     options: {
                //         offset: [0, 2],
                //     },
                // },
                {
                    name: "preventOverflow",
                    options: {
                        padding: 10
                    },
                },
            ];
            this.popper = createPopper(ref, this.$el, {
                // strategy: "fixed",
                modifiers,
                placement: "bottom",
            });
            this.$nextTick(() => {
                this.showing = true;
                this.$el.setAttribute("data-show", "");
                this.popper?.update();
            });
        },
        close() {
            this.showing = false;
        },
    },
    watch: {
        showing(val) {
            this.$emit("update:show", val);
        },
        show(val) {
            if (!val) {
                this.close();
            }
            else {
                this.open();
            }
        },
    },
    mounted() {
        if (this.show) {
            this.open();
        }
        let el = this.$el;
        let ref = this.reference;
        this.$nextTick(() => {
            let obs = () => {
                let width = Math.max(360, ref.offsetWidth);
                el.style.width = width + "px";
                this.popper?.update();
            };
            el.style.width = ref.offsetWidth + "px";
            obs();
            this.observer = new ResizeObserver(obs);
            this.observer.observe(this.reference);
            this.observer.observe(this.$el);
        });
        this.prepareAce(true);
    },
    beforeDestroy() {
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
    },
};
