import { BackgroundStyleGroup, BordersGroup, EffectsStyleGroup, FlexChildGroup, LayoutStyleGroup, PositionStyleGroup, SizingStyleGroup, TypeStyleGroup } from "@/components/palette/settings/style/style-groups";
import { clone } from "remeda";
import StackStyleSetting from "@/components/palette/settings/style/types/StackStyleSetting.vue";
import StyleComponentMixin from "@/components/palette/settings/style/StyleComponentMixin";
import SpacingStyleSetting from "@/components/palette/settings/style/types/SpacingStyleSetting.vue";
import CompositeStyleSetting from "@/components/palette/settings/style/types/CompositeStyleSetting.vue";
import BorderStyleSetting from "@/components/palette/settings/style/types/BorderStyleSetting.vue";
export default {
    name: "BlockStyleSettings",
    components: {
        BorderStyleSetting,
        SpacingStyleSetting,
        CompositeStyleSetting,
        StackStyleSetting
    },
    mixins: [StyleComponentMixin],
    inject: ["manager"],
    props: {
        set: {
            type: Object,
        },
        layers: {
            type: Array,
        },
        styleReq: {
            type: Object,
        },
        parentReq: {
            type: Object,
        },
    },
    data() {
        return {
            baseGroups: [
                FlexChildGroup,
                LayoutStyleGroup,
                SizingStyleGroup,
                PositionStyleGroup,
                TypeStyleGroup,
                BackgroundStyleGroup,
                BordersGroup,
                EffectsStyleGroup
            ],
        };
    },
    computed: {
        groups() {
            let baseGroups = this.baseGroups;
            let set = this.set;
            return baseGroups
                .map((group) => {
                if (set?.setType === "root" && group.hideRoot) {
                    return null;
                }
                let items = group.items.filter((item) => {
                    if (item.prop.requires) {
                        for (let [key, val] of Object.entries(item.prop.requires)) {
                            if (!val.includes(this.styleReq?.[key])) {
                                return false;
                            }
                        }
                    }
                    else if (item.prop.requiresParent) {
                        for (let [key, val] of Object.entries(item.prop.requiresParent)) {
                            if (!val.includes(this.parentReq?.[key])) {
                                return false;
                            }
                        }
                    }
                    return true;
                });
                if (items.length === 0) {
                    return null;
                }
                return {
                    ...group,
                    items,
                };
            })
                .filter((it) => !!it);
        },
    },
    methods: {
        settingComponent(item) {
            if (item.prop.type === "stack") {
                return StackStyleSetting;
            }
            if (item.prop.type === "composite") {
                if (item.prop.property === "margin" ||
                    item.prop.property === "padding" ||
                    item.prop.property === "positioning" ||
                    item.prop.property === "border-radius") {
                    return SpacingStyleSetting;
                }
                else if (item.prop.property === "border") {
                    return BorderStyleSetting;
                }
                return CompositeStyleSetting;
            }
            return this.singleSettingComponent(item);
        },
        update(entry) {
            let manager = this.manager;
            let set = this.set;
            manager.style.updateStyle(set, [clone(entry)]);
        },
        reset(entry) {
            let manager = this.manager;
            let set = this.set;
            manager.style.removeStyle(set, entry.value.property);
        }
    },
};
