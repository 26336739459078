import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
import { BlockTypeStyle, ClassStyle, RootStyle } from "@blockwell/palette";
export default {
    name: "StyleLabel",
    components: { SettingsPopper },
    props: {
        label: String,
        show: Boolean,
        layerPrecedes: Boolean,
        fromLayer: Object,
        edited: Boolean,
        description: String,
        composite: Boolean,
        classes: {
            type: Array
        }
    },
    methods: {
        classLabel(type) {
            if (type instanceof RootStyle) {
                return "Palette";
            }
            if (type instanceof BlockTypeStyle) {
                if (type.layout) {
                    return `${type.type} block (${type.layout})`;
                }
                else {
                    return type.type + " block";
                }
            }
            if (type instanceof ClassStyle) {
                return type.name;
            }
            return type.id;
        },
        labelClasses(fromLayer, edited) {
            let classes = [];
            if (fromLayer) {
                classes.push("layer-" + fromLayer.setType);
            }
            if (edited) {
                classes.push("edited");
            }
            return classes;
        }
    }
};
