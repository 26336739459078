import Vue from "vue";
import WhitelabelMobileNav from "@/layouts/components/WhitelabelMobileNav.vue";
import WhitelabelMainHeader from "@/layouts/components/WhitelabelMainHeader.vue";
import WhitelabelFooter from "@/layouts/components/WhitelabelFooter.vue";
import PaletteViewerFooter from "@/views/palette/PaletteViewerFooter.vue";
export default Vue.extend({
    name: "PaletteViewerLayout",
    components: {
        PaletteViewerFooter,
        WhitelabelFooter,
        WhitelabelMainHeader,
        WhitelabelMobileNav,
    },
    props: {
        icon: {
            type: String,
        },
        external: {
            type: String,
        },
        whitelabelLayout: {
            type: Boolean,
        },
        hideNav: Boolean,
    },
    data() {
        return {};
    },
});
