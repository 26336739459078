import { capitalCase } from "change-case";
import { styleEntryForProp } from "@blockwell/palette";
import { debounce } from "@blockwell/util";
import SettingsPopper from "@/components/palette/settings/form/SettingsPopper.vue";
export default {
    components: { SettingsPopper },
    props: {
        item: {
            type: Object
        },
        set: {
            type: Object
        },
        layers: {
            type: Array
        }
    },
    data() {
        return {
            values: [],
            edited: false,
            fromLayer: null,
            layerPrecedes: true,
            entry: null,
            labelShow: false
        };
    },
    computed: {
        value() {
            return this.entry?.value?.value;
        },
        fixedValue() {
            return this.entry?.value?.fixedValue;
        },
        unit() {
            return this.entry?.value?.unit;
        },
        isDefault() {
            return this.entry?.isDefault;
        },
        label() {
            return this.item.label || capitalCase(this.item.prop.property);
        },
        labelClasses() {
            let classes = [];
            if (!this.layerPrecedes) {
                classes.push("overridden");
            }
            if (this.fromLayer) {
                classes.push("layer-" + this.fromLayer.setType);
            }
            if (this.edited) {
                classes.push("edited");
            }
            return classes;
        },
        settingCss() {
            let css = {};
            if (this.item.width) {
                css.width = `calc(${this.item.width}% - 5px)`;
            }
            else {
                css.width = "100%";
            }
            return css;
        },
        valueDeps() {
            let prop = this.item.prop;
            let set = this.set;
            let layers = this.layers;
            return {
                set,
                layers,
                prop
            };
        },
        units() {
            let prop = this.item.prop;
            if (prop.type !== "composite" && prop.type !== "stack") {
                if (prop.units || prop.fixedValues) {
                    return [...(prop.units || []), ...(prop.fixedValues || [])];
                }
            }
            return null;
        },
        labelDeps() {
            return {
                show: this.labelShow,
                label: this.label,
                classes: this.labelClasses,
                description: this.item?.description,
                edited: this.edited,
                fromLayer: this.fromLayer,
                layerPrecedes: this.layerPrecedes,
                composite: !this.layers
            };
        },
        labelEvents() {
            return {
                selector: this.selectLayer,
                reset: this.reset,
                "update:show": this.updateLabelShow
            };
        }
    },
    watch: {
        valueDeps: {
            immediate: true,
            deep: true,
            handler(deps) {
                this.loadEntry(deps);
            }
        }
    },
    methods: {
        loadEntry({ set, layers, prop }) {
            let entry = set.get(prop.property);
            let edited = false;
            if (entry) {
                this.entry = styleEntryForProp(prop, entry);
                this.fromLayer = null;
                edited = true;
                this.edited = true;
                this.layerPrecedes = true;
            }
            else if (layers?.length > 0 && set.setType !== "root") {
                let precedes = true;
                for (let layer of layers) {
                    if (layer) {
                        if (layer.setType === "root") {
                            continue;
                        }
                        if (layer === set) {
                            precedes = false;
                            continue;
                        }
                        let entry = layer.get(prop.property);
                        if (entry) {
                            this.entry = styleEntryForProp(prop, entry);
                            this.fromLayer = layer;
                            this.layerPrecedes = precedes;
                            return;
                        }
                    }
                }
            }
            if (!edited) {
                this.entry = styleEntryForProp(prop);
            }
        },
        selectUnit(unit) {
            this.entry.update({ unit });
            this.updateFromEntry();
        },
        input: debounce(function (ev) {
            let val;
            if (typeof ev === "string") {
                val = ev;
            }
            else {
                let el = ev.target;
                val = el.value;
            }
            this.entry.update({ value: val });
            this.updateFromEntry();
        }, 100),
        updateFromEntry() {
            this.edited = true;
            this.fromLayer = null;
            this.$emit("update", this.entry.value);
            this.$emit("entry", this.entry);
        },
        reset() {
            this.$emit("reset", this.entry);
            this.edited = false;
            this.loadEntry({
                set: this.set,
                layers: this.layers,
                prop: this.item.prop
            });
            this.labelShow = false;
        },
        selectLayer(set) {
            this.$emit("selector", set);
        },
        updateLabelShow(val) {
            this.labelShow = val;
        }
    }
};
