import StyleSettingMixin from "@/components/palette/settings/style/types/StyleSettingMixin";
import CompositeStyleSetting from "@/components/palette/settings/style/types/CompositeStyleSetting.vue";
import { CompositeStyleSet, cssProperties } from "@blockwell/palette";
import StyleLabel from "@/components/palette/settings/style/StyleLabel.vue";
export default {
    name: "BorderStyleSetting",
    components: { StyleLabel, CompositeStyleSetting },
    mixins: [StyleSettingMixin],
    data() {
        return {
            borders: [
                { name: "top", icon: "border_top" },
                { name: "right", icon: "border_right" },
                { name: "bottom", icon: "border_bottom" },
                { name: "left", icon: "border_left" },
                { name: "all", icon: "border_outer" },
            ],
            selected: "all"
        };
    },
    computed: {
        layer() {
            return CompositeStyleSet.from(this.entry);
        },
        borderItem() {
            let property = "border";
            if (this.selected !== "all") {
                property += "-" + this.selected;
            }
            return {
                prop: cssProperties.get(property)
            };
        }
    },
    methods: {
        borderInput(border) {
            this.selected = border;
        },
        styleUpdate(entry) {
            this.edited = true;
            this.fromLayer = null;
            this.$emit("update", entry.value);
        }
    }
};
