import { blockVariableInfo, } from "@blockwell/palette";
import Info from "@/components/Info.vue";
import RadioTabs from "@/components/palette/settings/form/RadioTabs.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import { debounce } from "@/lib/vutil";
import CurrentValue from "@/components/palette/settings/CurrentValue.vue";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import { clone } from "remeda";
import { isConstantReference, isVariableReference, vals, VariableReference } from "@blockwell/variables";
export default {
    name: "ArgumentVariableSetting",
    components: { LiteralSettingInput, CurrentValue, NameFind, RadioTabs, Info },
    props: {
        wrap: {
            type: Object,
        },
        setting: {
            type: Object,
        },
        value: {
            type: Object,
        },
        compact: Boolean,
        path: Boolean
    },
    data() {
        let constValue = this.value?.param?.value;
        let type = "var";
        if (this.value.prefill) {
            type = "prefill";
        }
        else if (this.value.form) {
            type = "form";
        }
        else if (constValue) {
            type = "const";
        }
        return {
            type: this.value?.prefill ? "prefill" : "var",
            name: this.value?.param?.name || "",
            constValue,
            currentValue: null,
            errorValue: null,
            removeOnValue: null,
        };
    },
    computed: {
        typeOptions() {
            return [
                ...(this.setting.allowNull
                    ? [
                        {
                            label: "Form",
                            value: "form",
                            description: "Leave the value for the user to enter.",
                        },
                        {
                            label: "Fill",
                            value: "prefill",
                            description: "Pre-fill a value in the form, but let the user edit it.",
                        },
                    ]
                    : []),
                { label: "Variable", value: "var", description: "Use existing variable." },
                {
                    label: "Constant",
                    value: "const",
                    description: "Use a preset value.",
                },
            ];
        },
    },
    watch: {
        constValue: {
            deep: true,
            handler(val) {
                if (this.type === "const") {
                    this.emitConst(val);
                }
            },
        },
        type(val, old) {
            if (val !== old) {
                if (val === "var") {
                    if (this.name) {
                        this.emit({
                            value: {
                                type: "var",
                                param: {
                                    name: this.name,
                                },
                            },
                        });
                    }
                }
                else if (val === "prefill") {
                    if (this.name) {
                        this.emit({
                            prefill: true,
                            value: {
                                type: "var",
                                param: {
                                    name: this.name,
                                },
                            },
                        });
                    }
                }
                else if (val === "form") {
                    this.emit({ value: null, form: true });
                }
                else {
                    this.emitConst(this.constValue);
                }
            }
        },
        value: {
            immediate: true,
            deep: true,
            handler(config) {
                const block = this.wrap.block;
                const resolver = block.context.resolver;
                if (this.removeOnValue) {
                    this.removeOnValue();
                }
                let ref = config?.value;
                if (ref) {
                    if (isVariableReference(ref)) {
                        if (this.allowAuto && "type" in ref.param) {
                            this.type = "auto";
                        }
                        else if ("name" in ref.param) {
                            if (config.prefill) {
                                this.type = "prefill";
                            }
                            else {
                                this.type = "var";
                            }
                            this.name = ref.param.name;
                        }
                        let resolved = resolver.resolveReference(ref);
                        if (resolved.value instanceof VariableReference) {
                            const variable = resolved.value;
                            const path = resolved.path ? clone(resolved.path) : null;
                            const onValue = (val) => {
                                this.resolved(vals.path(val, path));
                            };
                            variable.value();
                            variable.onValue(onValue);
                            this.removeOnValue = () => variable.offValue(onValue);
                        }
                        else if (resolved?.value) {
                            let value = vals.path(resolved.value.value(), resolved.path);
                            this.resolved(value);
                        }
                        else {
                            this.resolved({ type: "null", value: null });
                        }
                    }
                    else if (isConstantReference(ref)) {
                        this.type = "const";
                        this.constValue = ref.param.value;
                        this.resolved({ type: "value", value: ref.param.value });
                    }
                }
                else if (config.form) {
                    this.type = "form";
                    this.resolved(null);
                }
                else {
                    if (this.setting.allowNull) {
                        this.type = "form";
                        this.resolved(null);
                    }
                    else if (this.setting.default) {
                        this.type = "const";
                        this.constValue = this.setting.default;
                        this.resolved({ type: "value", value: this.setting.default });
                    }
                }
            },
        },
        name: {
            handler: debounce(function (val) {
                if (val === "") {
                    this.emit(undefined);
                }
                else if (val) {
                    let newRef = {
                        type: "var",
                        param: {
                            name: val,
                        },
                    };
                    const block = this.wrap.block;
                    const resolver = block.context.resolver;
                    let resolved = resolver.resolveReference(newRef);
                    if (resolved.value.valueType === "null") {
                        this.errorValue = `No variable found with '${val}'`;
                    }
                    else {
                        this.errorValue = null;
                    }
                    this.emit({
                        value: newRef,
                        prefill: this.type === "prefill",
                    });
                }
                else if (this.type === "var" || this.type === "prefill") {
                    this.errorValue = `Variable name is required`;
                }
            }, 200),
        },
    },
    methods: {
        emitConst(val) {
            this.emit({
                value: {
                    type: "const",
                    param: {
                        value: val,
                    },
                },
            });
        },
        emit(newRef) {
            this.$emit("input", newRef);
        },
        resolved(val) {
            this.$emit("resolved", val);
            if (!val) {
                this.currentValue = null;
                return;
            }
            switch (val.type) {
                case "value":
                    this.currentValue = val.value;
                    break;
                case "nft":
                    this.currentValue = val.value?.collection + " #" + val.value?.tokenId;
                    break;
                case "wallet":
                    this.currentValue = val.value;
                    break;
                case "contract":
                    this.currentValue = val.value?.name;
                    break;
                case "null":
                    this.currentValue = null;
                    break;
            }
        },
        source() {
            let cached;
            let timeout;
            let varType = this.setting.variableType;
            let types;
            if (varType) {
                if (Array.isArray(varType)) {
                    types = varType;
                }
                else {
                    types = [varType];
                }
            }
            return (q, populate) => {
                let query = q?.toLowerCase();
                if (!cached) {
                    cached = blockVariableInfo(this.wrap.block, types).filter((it) => it.valueType !== "Null");
                }
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => (cached = null), 3000);
                let vars = cached;
                if (query) {
                    vars = vars.filter((it) => it.name.startsWith(query) ||
                        it.path.find((part) => part.startsWith(query)));
                }
                populate(vars);
            };
        },
        inputValue(info) {
            return info?.name;
        },
        suggestion(info) {
            if (!info.name) {
                return "";
            }
            if (info) {
                return `<div class="var-name">${info.name}</div>
                    <div class="var-info">
                        <div class="var-type">${info.valueType}</div>
                        <div class="var-value">${info.value || ""}</div>
                    </div>`;
            }
            return "";
        },
    },
    beforeDestroy() {
        if (this.removeOnValue) {
            this.removeOnValue();
            this.removeOnValue = null;
        }
    },
};
