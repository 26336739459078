import PaletteTreeBlock from "@/components/palette/settings/PaletteTreeBlock.vue";
import PaletteTreeContext from "@/components/palette/settings/PaletteTreeContext.vue";
import Info from "@/components/Info.vue";
export default {
    name: "PaletteTree",
    components: { Info, PaletteTreeContext, PaletteTreeBlock },
    inject: ["editorState"],
    provide() {
        return {
            manager: this.manager,
        };
    },
    props: {
        manager: {
            type: Object,
        },
    },
    data() {
        return {
            open: false,
            page: null
        };
    },
    computed: {
        context() {
            return Object.freeze({ context: this.manager.root.block.context });
        },
        root() {
            if (this.page) {
                return this.page.root;
            }
            return this.manager.root;
        }
    },
    watch: {
        "editorState.selectKey": {
            handler(val) {
                let el = this.$el.querySelector(".palette-tree-block.selected");
                if (el) {
                    el.classList.remove("selected");
                }
                if (val) {
                    let el = this.$el.querySelector("#palette-tree-" + val);
                    if (el) {
                        el.classList.add("selected");
                        el.children[0].scrollIntoView({
                            block: "nearest",
                            behavior: "smooth",
                        });
                    }
                }
            },
        },
        "editorState.hoverKey": {
            handler(val) {
                let el = this.$el.querySelector(".palette-tree-block.hovering");
                if (el) {
                    el.classList.remove("hovering");
                }
                if (val) {
                    let el = this.$el.querySelector("#palette-tree-" + val);
                    el?.classList?.add("hovering");
                }
            },
        },
        manager: {
            immediate: true,
            handler(val, old) {
                if (old !== val && old) {
                    old.page.events.off("activate", this.onActivate);
                }
                if (val) {
                    val.page.events.on("activate", this.onActivate);
                }
            }
        }
    },
    methods: {
        mouseover(ev) {
            let el = ev.target.closest(".palette-tree-block");
            if (el && el.classList.contains("selectable")) {
                this.editorState.hoverKey = parseInt(el.dataset?.key);
            }
        },
        mouseout(ev) {
            if (this.editorState.hoverKey) {
                this.editorState.hoverKey = 0;
            }
        },
        onActivate({ page, path }) {
            this.page = page;
        }
    },
    beforeDestroy() {
        this.manager?.page?.events?.off("activate", this.onActivate);
    },
};
