import StyleableLayout from "@/layouts/StyleableLayout.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
    components: { LoadingButton, StyleableLayout },
    data() {
        return {
            owned: null,
            shared: null,
            publicPalettes: [
                {
                    name: "simple",
                    description: "Simple Palette with some basic controls."
                },
                {
                    name: "test",
                    description: "An advanced sample Palette for a staking game."
                }
            ]
        };
    },
    computed: {
        ...mapGetters("user", ["loggedIn"]),
        api() {
            return this.$store.getters["user/api"];
        },
    },
    watch: {
        loggedIn: {
            immediate: true,
            handler(val) {
                let api = this.api;
                if (val) {
                    api.palette.getPalettes().then((res) => {
                        let owned = res.data.map((it) => {
                            return {
                                name: it.name,
                                updated: dayjs(it.updated),
                            };
                        });
                        owned.sort((a, b) => b.updated.diff(a.updated));
                        this.owned = owned;
                        let shared = res.shared.map((it) => {
                            return {
                                name: it.name,
                                updated: dayjs(it.updated),
                                roles: it.roles
                            };
                        });
                        shared.sort((a, b) => b.updated.diff(a.updated));
                        this.shared = shared;
                    });
                }
                else {
                    this.shared = null;
                    this.owned = null;
                }
            },
        },
    },
    methods: {
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
    },
    mounted() { },
};
