import { paletteDefaultStyles } from "@blockwell/palette";
import { DumbappSubmissionData } from "@blockwell/dumbapp";
import { clone } from "remeda";
import { loadPalette } from "@/components/palette/palette";
export default {
    props: {
        name: String,
    },
    data() {
        return {
            subscription: null,
            manager: null,
            loadError: null,
            loading: false,
        };
    },
    computed: {
        batches() {
            return this.$store.getters["user/batches"];
        },
        api() {
            return this.$store.getters["user/api"];
        },
        paletteId() {
            return this.manager?.registry?.id;
        },
    },
    watch: {
        "$route.query"(val) {
            this.manager?.updateRootParameters(val);
        },
        manager: {
            immediate: true,
            handler(val, old) {
                if (old !== val) {
                    if (old) {
                        old.page.offTitle(this.onTitle);
                        old.registry.events.off("interaction", this.onInteract);
                    }
                    val?.page?.onTitle(this.onTitle);
                    val?.registry?.events?.on("interaction", this.onInteract);
                }
            },
        },
    },
    methods: {
        onTitle(val) {
            if (val?.trim()) {
                document.title = val + " | Blockwell Wallet";
            }
        },
        onInteract(interaction) {
            switch (interaction.type) {
                case "link":
                    break;
                case "page":
                    let path = encodeURIComponent(interaction.page);
                    if (interaction.path?.length > 0) {
                        path += `/` + interaction.path.map(encodeURIComponent).join("/");
                    }
                    this.$router.push({
                        query: this.$route.query,
                        hash: this.$route.hash,
                        path: `${this.basePath}/${this.manager.name}/${path}`,
                    });
                    break;
            }
        },
        loadPaletteName(name) {
            let api = this.api;
            this.loading = true;
            api.palette
                .get(name)
                .then((res) => {
                const palette = res.data;
                const sub = res.subscription;
                if (this.name === name) {
                    this.subscription = sub;
                    this.loadError = null;
                    this.$store.restored.then(() => {
                        this.setupPalette(name, palette);
                    });
                }
            })
                .catch((err) => {
                if (this.name === name) {
                    if (err.code === "not_found") {
                        this.loadError = `Palette with the name '${name}' could not be found.`;
                    }
                    else {
                        this.loadError = "Unknown error loading the palette, please try again.";
                    }
                }
            })
                .finally(() => (this.loading = false));
        },
        async setupPaletteSpec(spec) {
            let manager = loadPalette(spec, this.batches, this.$store.getters["user/nftLoader"], this.api.contracts, this.api);
            if (this.manager) {
                this.manager.destroy();
                this.manager = null;
                await this.$nextTick();
            }
            manager.updateRootParameters(this.$route.query);
            let style = document.getElementById("palette2-style-definition");
            if (!style) {
                style = document.createElement("style");
                style.id = "palette2-style-definition";
                if (!this.editorState?.editing) {
                    document.head.appendChild(style);
                }
            }
            if (this.editorState) {
                this.editorState.style = style;
            }
            style.innerHTML =
                manager.style.renderStylesheet(".palette-wrapper") + "\n\n" + manager.color.toCss();
            this.manager = manager;
            manager.root.block.renderTree();
            manager.root.block.layoutTree();
            setTimeout(() => {
                manager.root.block.renderTree();
            }, 250);
            return manager;
        },
        dumbappEvent(submission) {
            const manager = this.manager;
            if (manager) {
                manager.registry.tracker.dumbappUpdated(new DumbappSubmissionData(clone(submission.data)));
            }
        },
    },
    mounted() {
        let style = document.getElementById("palette2-default-style-definition");
        if (!style) {
            style = document.createElement("style");
            style.id = "palette2-default-style-definition";
            style.innerHTML = paletteDefaultStyles;
            document.head.appendChild(style);
        }
        this.$bus.on("dumbapp-updated", this.dumbappEvent);
    },
    beforeDestroy() {
        if (this.manager) {
            this.manager.destroy();
        }
        this.$bus.off("dumbapp-updated", this.dumbappEvent);
    },
};
