export default {
    inject: {
        dynamicContext: {
            name: "_dynamicContext",
            default: null,
        },
        manager: {
            name: "_manager",
        },
    },
    computed: {
        dynamicContext() {
            return this._dynamicContext;
        },
        manager() {
            return this._manager;
        },
    },
};
