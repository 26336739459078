import Checkboard from "@/components/color/Checkboard.vue";
export default {
    name: "ShadowStackPreview",
    components: { Checkboard },
    props: {
        layer: {
            type: Object
        },
        index: Number
    },
    data() {
        return {};
    },
    computed: {
        preview() {
            let style = {};
            style["background-color"] = this.layer.get("box-shadow-color")?.value;
            return style;
        },
        type() {
            let value = this.layer.get("box-shadow-type")?.value;
            if (!value) {
                return "Outside";
            }
            else {
                return "Inside";
            }
        },
        blur() {
            let val = this.layer.get("box-shadow-blur");
            if (val) {
                if (val.fixedValue) {
                    return val.fixedValue;
                }
                else if (val.unit) {
                    return val.value + val.unit;
                }
                else {
                    return val.value;
                }
            }
            return "";
        },
        size() {
            let val = this.layer.get("box-shadow-spread");
            if (val) {
                if (val.fixedValue) {
                    return val.fixedValue;
                }
                else if (val.unit) {
                    return val.value + val.unit;
                }
                else {
                    return val.value;
                }
            }
            return "";
        }
    }
};
