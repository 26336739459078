export default {
    name: "PaletteTreeBlock",
    inject: ["editorState"],
    props: {
        block: {
            type: Object,
        },
        hiding: Boolean,
        depth: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            key: null,
            canHaveChildren: false,
            children: null,
            value: null,
            variableCount: 0,
        };
    },
    computed: {
        hidden() {
            if (this.hiding) {
                return true;
            }
            if (this.block.block.type.type === "condition" && !this.value) {
                return true;
            }
            return false;
        },
        repeatChild() {
            return this.block.block.type.type === "repeat-child";
        },
        selectable() {
            return !this.repeatChild && (!this.hidden || this.editorState.options.conditionBlocks);
        },
        repeatCount() {
            if (this.block.block.type.type === "repeat") {
                return this.block.block.children.length;
            }
            return 0;
        },
        filtered() {
            if (this.block.block.type.type === "repeat" &&
                !this.editorState.treeOptions.showRepeats) {
                return this.children.slice(0, 1);
            }
            return this.children;
        },
        icon() {
            switch (this.block.block.type.type) {
                case "balance":
                    return "money";
                case "layout":
                    return "table_rows";
                case "template":
                    return "text_fields";
                case "repeat":
                    return "format_list_numbered";
                case "condition":
                    return "flip";
                case "nft":
                    return "smart_toy";
                case "wallet":
                    return "account_balance_wallet";
                case "dumbapp":
                    return "smart_button";
                case "spinner":
                    return "refresh";
                case "checkbox":
                    return "check_box";
                case "image":
                    return "image";
                case "contract":
                    return "keyboard_alt";
                case "input":
                    return "input";
                case "contrib-token":
                    return "trip_origin";
            }
        },
    },
    watch: {
        block: {
            immediate: true,
            handler(block, old) {
                if (block?.block && block.block.key !== this.key) {
                    this.key = block.block.key;
                    block.block.onValue(this.onValue);
                    block.block.context.onLayout(this.onContextLayout);
                    if (block.block.canHaveChildren()) {
                        this.canHaveChildren = true;
                        block.block.onLayout(this.onLayout);
                    }
                    else {
                        this.canHaveChildren = false;
                    }
                }
                if (old?.block && old.block !== block?.block) {
                    old.block.offValue(this.onValue);
                    old.block.offLayout(this.onLayout);
                    old.block.context?.offLayout(this.onContextLayout);
                }
            },
        },
    },
    methods: {
        onLayout(children) {
            this.children = children;
        },
        onContextLayout() {
            let block = this.block.block;
            this.variableCount = block.variable.variableCount();
        },
        onValue(val) {
            this.value = val;
        },
        onClick() {
            if (this.selectable) {
                this.editorState.selectKey = this.key;
            }
        },
    },
    beforeDestroy() {
        let block = this.block.block;
        block.offValue(this.onValue);
        block.offLayout(this.onLayout);
        block.context?.offLayout(this.onContextLayout);
    },
};
