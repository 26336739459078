import Info from "@/components/Info.vue";
import SelectField from "@/components/palette/settings/form/SelectField.vue";
import SettingMixin from "@/components/palette/settings/types/SettingMixin";
import LiteralSettingInput from "@/components/palette/settings/types/LiteralSettingInput.vue";
import NameFind from "@/components/palette/settings/form/NameFind.vue";
import Markdown from "@/components/Markdown.vue";
import DynamicContextMixin from "@/components/palette/settings/types/DynamicContextMixin";
import { Typing } from "@blockwell/eth-types";
export default {
    name: "PageSetting",
    components: { Markdown, NameFind, LiteralSettingInput, SelectField, Info },
    mixins: [SettingMixin, DynamicContextMixin],
    inject: {
        manager: {
            name: "manager"
        }
    },
    props: {
        setting: {
            type: Object
        },
        value: String
    },
    data() {
        return {};
    },
    computed: {
        name() {
            return this.value || "";
        }
    },
    watch: {
        name: {
            immediate: true,
            handler(val) {
                if (val) {
                    const manager = this.manager;
                    let page = manager.page.getPages().find(it => it.name === val);
                    this.dynamicContext.args = page.spec.pathParam.map(it => {
                        return {
                            name: it.name,
                            typing: Typing.string
                        };
                    });
                }
                else {
                    this.dynamicContext.args = [];
                }
            }
        }
    },
    methods: {
        source() {
            const manager = this.manager;
            return (q, populate) => {
                let query = q?.toLowerCase();
                let pages = manager.page.getPages();
                if (query) {
                    pages = pages.filter((it) => it.name.startsWith(query));
                }
                populate(pages.map((page) => {
                    return {
                        name: page.name,
                        layout: page.layout
                    };
                }));
            };
        }
    }
};
