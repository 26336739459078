import dayjs from "dayjs";
import LoadingButton from "@/components/LoadingButton.vue";
import bootbox from "bootbox";
export default {
    name: "PaletteVersionEntry",
    components: { LoadingButton },
    inject: ["saveState"],
    props: {
        name: String,
        version: {
            type: Object
        }
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        time() {
            return dayjs(this.version.version).format("lll");
        }
    },
    methods: {
        load() {
            let saveState = this.saveState;
            if (saveState.isDraft) {
                bootbox.confirm({
                    title: "Discard Changes?",
                    message: `Loading a different version will discard all unpublished changes.`,
                    backdrop: true,
                    callback: (result) => {
                        if (result) {
                            this.doLoad();
                        }
                    },
                });
            }
            else {
                this.doLoad();
            }
        },
        doLoad() {
            let api = this.$store.getters["user/api"].palette;
            let saveState = this.saveState;
            this.loading = true;
            api.getVersion(this.name, this.version.version)
                .then(res => {
                return saveState.events.emit("importSpec", res)[0];
            })
                .finally(() => {
                this.loading = false;
            });
        }
    }
};
