import BlockSettingsGroup from "@/components/palette/settings/BlockSettingsGroup.vue";
import SettingData from "@/components/palette/settings/SettingData.vue";
import { clone } from "remeda";
import equals from "fast-deep-equal";
import { layoutChange } from "@/components/palette/palette";
import InteractionsSetting from "@/components/palette/settings/types/InteractionsSetting.vue";
export default {
    name: "BlockSettings",
    components: { InteractionsSetting, SettingData, BlockSettingsGroup },
    inject: ["manager"],
    provide() {
        return {
            dynamicContext: this.dynamicContext,
        };
    },
    props: {
        spec: {
            type: Object,
        },
        settings: {
            type: Array,
        },
        wrap: {
            type: Object,
        },
    },
    data() {
        return {
            dynamicContext: {
                contract: null,
                abi: null,
                args: [],
            },
        };
    },
    methods: {
        update(update) {
            for (let [key, value] of Object.entries(update)) {
                if (!equals(this.spec.param[key], value)) {
                    let prev = this.spec.param[key];
                    this.$set(this.spec.param, key, value);
                    this.wrap.block.updateParameter(key, clone(value));
                    if (key === "layout") {
                        layoutChange(this.manager, this.wrap.block, prev, value);
                    }
                }
            }
        },
        updateInteractions(update) {
            let block = this.wrap.block;
            this.$set(this.spec, "act", clone(update.act));
            block.interaction.updateInteractions(update.act);
        }
    },
};
